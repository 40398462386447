<!-- Common stuff like toasts and loading indicator -->
<common-list-view-elements [loading]="loading" [accountService]="accountService" [parent]="this">
</common-list-view-elements>

<!-- ui Canvas -->
<canvas hidden #emptyCanvas></canvas>

<!-- Context Menu -->
<p-contextMenu #cm [model]="contextMenu" [style]="{'width': 'auto'}"></p-contextMenu>

<!-- Content -->
<div class="card" style="min-height: 100%">
	<!--<div class="card" style="max-height: 200px">-->
		<p-table #table (onColResize)="onColResize($event)" (onColReorder)="onColReorder($event)"
			(onFilter)="onFilter($event)"
			[metaKeySelection]="false"
			(onRowUnselect)="handleRowUnselect($event); $event.originalEvent.stopPropagation();"
			(onRowSelect)="handleRowSelection($event); isMobile && cm.show($event.originalEvent); $event.originalEvent.stopPropagation();"
			[(contextMenuSelection)]="selectedEntry" [(selection)]="selectedEntry" [contextMenu]="cm"
			[globalFilterFields]="filters" [reorderableColumns]="true" [resizableColumns]="true"
			[scrollHeight]="contentHeight + 'px'" [virtualRowHeight]="45" [virtualScroll]="true" columnResizeMode="expand"
			dataKey="Id" scrollDirection="both" selectionMode="single" [stateKey]="stateName" stateStorage="local"
			styleClass="p-datatable-gridlines" [columns]="cols" [rows]="100" [scrollable]="true" [value]="entries">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-ai-center p-jc-between">
					<div>
						<button *ngIf="hasFilterDialog" type="button" pButton icon="fa fa-search" class="p-my-2 p-mr-3"
							pTooltip="{{'BUTTONS.LOAD' | translate }}" tooltipPosition="bottom"
							(click)="openFilterDialog()"></button>
						<button *ngIf="createPermission" type="button" pButton icon="fa fa-plus"
							class="p-button-success p-my-2 p-mr-3" pTooltip="{{'BUTTONS.NEW' | translate }}"
							tooltipPosition="bottom" (click)="create()"></button>
						<button type="button" pButton icon="pi pi-file-o" (click)="exportCSV()" class="p-my-2 p-mx-1"
							pTooltip="CSV" tooltipPosition="bottom"></button>
						<button type="button" pButton icon="pi pi-file-excel" (click)="exportXLSX()"
							class="p-button-success p-my-2 p-mx-1" pTooltip="XLS" tooltipPosition="bottom"></button>
						<button type="button" pButton icon="pi pi-file-pdf" (click)="exportPDF()"
							class="p-button-warning p-my-2 p-mx-1" pTooltip="PDF" tooltipPosition="bottom"></button>
					</div>
					<h5 class="p-m-0">{{ name | translate }}</h5>
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText id="global" type="text" [value]="globalFilter"
							(input)="table.filterGlobal($event.target.value, 'contains');globalFilter = $event.target.value;"
							placeholder="{{'BUTTONS.SEARCH'|translate}}" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
						[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
						<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
							<div>
								<p-sortIcon [field]="col.key"></p-sortIcon>
								{{ 'HEADERS.' + col.key | translate }}
							</div>
							<p-columnFilter [type]="col.type" [field]="col.key" display="menu" appendTo="body"
								[style.color]="(isColFiltered(col) ? 'var(--primary-color)' : 'var(--text-color)')">
							</p-columnFilter>
						</div>
					</th>
					<th id="buttoncolhead" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right" pFrozenColumn>
						<p-multiSelect #colselection [options]="possibleCols" [(ngModel)]="cols" dataKey="key"
							(onChange)="toggleColumn($event)" optionLabel="label" appendTo="body"
							[style.visibility]="'hidden'" [style.position]="'absolute'" [panelStyle]="{'min-width':'auto'}"
							class="colselect">
						</p-multiSelect>
						<button pButton pRipple icon="fas fa-table-columns"
							class="p-button-rounded p-button-secondary p-mr-2"
							(click)="colselection.show($event.originalEvent);" pTooltip="{{'BUTTONS.COL_SELECT'|translate}}"
							tooltipPosition="bottom" showDelay="500"></button>
						<button pButton pRipple icon="pi pi-filter-slash" class="p-button-rounded p-button-secondary p-mr-2"
							(click)="table.clear()" pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}"
							tooltipPosition="bottom" showDelay="500"></button>
						<button pButton pRipple icon="fas fa-table-cells" class="p-button-rounded p-button-secondary p-mr-2"
							(click)="resetTable()" pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
							showDelay="500"></button>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
				<tr style="height:45px;" [pContextMenuRow]="entry" [pSelectableRow]="entry">
					<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
						<div *ngIf="entry[col.key] === null">
							<i class="pi pi-minus"></i>
						</div>
						<div *ngIf="entry[col.key] !== null">
							<div *ngIf="col.type == 'text'">
								{{ entry[col.key] }}
							</div>
							<div *ngIf="col.type == 'numeric'">
								{{ entry[col.key] }}
							</div>
							<div *ngIf="col.type == 'number'">
								{{ entry[col.key] }}
							</div>
							<div *ngIf="col.type == 'boolean'">
								<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
								   [style.color]="entry[col.key] ? 'green' : 'red'"></i>
							</div>
							<div *ngIf="col.type == 'date'">
								{{ entry[col.key].toLocaleString() }}
							</div>
						</div>
					</td>
					<td class="buttoncolbody" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
						pFrozenColumn>
						<a id="detailbutton" [routerLink]="url + '/' + url_detail + '/' + entry.ds_this_id"
							class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
							<i class="pi pi-search"></i>
						</a>
						<a *ngIf="updatePermission" id="editbutton"
							[routerLink]="url + '/' + url_edit + '/' + entry.ds_this_id"
							class="p-button-rounded p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
							<i class="pi pi-pencil"></i>
						</a>
						<a *ngIf="deletePermission" id="deletebutton" (click)="delete(entry.ds_this_id)"
							class="p-button-rounded p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
							<i class="pi pi-trash"></i>
						</a>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="summary">
				<div class="p-d-flex p-ai-center p-jc-between">
					{{ 'TABLE.COUNT' | translate : { count : count } }}
					{{
					(table && table.filteredValue ? 'TABLE.TOTAL' : '') | translate : { count : (entries ? entries.length
					: 0) }
					}}
					<div>
						{{ 'TABLE.LAST_REFRESH' | translate }}: {{ loadTimestamp ? loadTimestamp.toLocaleString() : '' }}
					</div>
				</div>
			</ng-template>
		</p-table>
	<!--</div>-->
	<div *ngIf="commitButtonVisible" class="p-col-12">
		<button pButton pRipple [disabled]="!isValidAreaSelected" [label]="commitButtonLabel" class="p-button p-mt-2" style="width:100%"
			(click)="commitGebietszuweisung()"></button>
	</div>
</div>
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppMainComponent } from '@app/components/app.main.component';
import { Base } from '@app/models/base';
import { tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import { AccountService } from '@app/services/account.service';
import { AdminService } from '@app/services/admin.service';
import { DateService } from '@app/services/date.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Arrangement, Occasion, Risk, State, Track } from '@models/reversingcadastral';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { LogService } from "@services/log.service";
import { asArray } from 'ol/color';
import GPX from 'ol/format/GPX';
import { Point } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './edit.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAufzeichnungEditComponent implements OnInit {
	arrangements: Arrangement[];
	basefields: any[];
	rk_rating_boundaries: any;
	fieldgroups: any[];
	id: number;
	loading = 0;
	mapRasEdit: Map;
	occasions: Occasion[];
	options: any;
	placeNames: any = {}; // Auswahloptionen für Orte
	positionLayer: VectorLayer;
	rating: number = 0;
	ratings: any;
	risks: Risk[];
	rkFiles: Base[];
	routeLayer: VectorLayer;
	startTimeStamp: number;
	states: State[];
	track: Track;
	trackPoints: any[];
	url: string = '';
	vehicles: tbl_fahrzeug[];
	gebiete: Base[];
	touren: Base[];
	videoplayer: any;
	video_api_url: string = '';
	videoSafeUrl: any;
	gpx_api_url: string = '';
	gpxUnsafeUrl: string = '';
	gpxSafeUrl: any;

	constructor(
		private adminService: AdminService,
		public app: AppMainComponent,
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
		private logService: LogService,
		private accountService: AccountService,
		private http: HttpClient,
		private sanitizer: DomSanitizer
	) {
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];
		this.url = '/' + PATH.RK_AUFZEICHNUNG;

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER', routerLink: [this.url] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: [this.url + '/' + PATH.EDIT + '/' + this.id] }
		]);

		this.fieldgroups = [
			[
				{ type: 'string', key: 'name', label: 'Name' },
				{ type: 'date', key: 'aufgezeichnet', label: 'Aufzeichnung' },
				{ type: 'dropdown', key: 'fahrzeug_id', label: 'Fahrzeug' },
				{ type: 'string', key: 'freitext', label: 'Anmerkungen' },
				//{ type: 'multiselect', key: 'status', label: 'Status' },
				{ type: 'dropdown', key: 'akte_id', label: 'RK Akte' },
				//{ type: 'object', key: 'gebiet_id', required: false, width: 400, apiUrl: 'TblGebiet', createComponent: TblGebietCreateComponent },
				//{ type: 'object', key: 'tour_id', required: false, width: 400, apiUrl: 'TblTour', createComponent: TblTourCreateComponent },
				{ type: 'dropdown', key: 'gebiet_id', label: 'Gebiet' },
				{ type: 'dropdown', key: 'tour_id', label: 'Tour' },
			],
			[
				{ type: 'plz', key: 'plz_von', label: 'PLZ von' },
				{ type: 'village', key: 'ort_von', label: 'Ort von', plz_field: 'plz_von' },
				{ type: 'string', key: 'strasse_von', label: 'Straße von' },
				{ type: 'string', key: 'hausnummer_von', label: 'Hausnummer von' },
				{ type: 'plz', key: 'plz_bis', label: 'PLZ bis' },
				{ type: 'village', key: 'ort_bis', label: 'Ort bis', plz_field: 'plz_bis' },
				{ type: 'string', key: 'strasse_bis', label: 'Straße bis' },
				{ type: 'string', key: 'hausnummer_bis', label: 'Hausnummer bis' },
				{ type: 'number', key: 'laenge', label: 'Länge' },
				{ type: 'boolean', key: 'breiter_350', label: 'Breiter als 3,50m' },
				{ type: 'boolean', key: 'kuerzer_150', label: 'Kürzer als 150m'},
				{ type: 'string', key: 'center_latitude', label: 'Latitude' },
				{ type: 'string', key: 'center_longitude', label: 'Longitude' },
			]
		];

		//this.ratings =
		//{
		//	0: '',
		//	1: 'green',
		//	2: 'lightgreen',
		//	3: 'yellow',
		//	4: 'lightcoral',
		//	5: 'red'
		//};

		this.options = {
			//bewertung: [
			//	{ label: '0 von 5', value: 0, icon: '' },
			//	{ label: '1 von 5', value: 1, icon: 'green' },
			//	{ label: '2 von 5', value: 2, icon: 'lightgreen' },
			//	{ label: '3 von 5', value: 3, icon: 'yellow' },
			//	{ label: '4 von 5', value: 4, icon: 'lightcoral' },
			//	{ label: '5 von 5', value: 5, icon: 'red' },
			//],
			//gefahren: [],
			//gruende: [],
			//massnahmen: [],
			//status: [],
			fahrzeug_id: [],
			akte_id: [],
			gebiet_id: [],
			tour_id: []
		};
	}

	ngAfterViewInit() {
		this.accountService.getOptions().then( options => {
			this.http.get(this.gpx_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
				const unsafeUrl = URL.createObjectURL(blob);
				const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
				this.gpxUnsafeUrl = unsafeUrl;
				this.gpxSafeUrl = safeUrl;
				this.initMap();
			});
			
			this.http.get(this.video_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
				const unsafeUrl = URL.createObjectURL(blob);
				const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
				this.videoSafeUrl = safeUrl;
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		});
	}

	ngOnInit() {
		this.loading += 1;
		this.video_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/video/` + this.id;
		this.gpx_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/gpx/` + this.id;
		this.crudService.getTrack(this.id).then(res => {
			this.track = res;
			if (!this.track.breiter_350) {
				this.rating = 5;
			}
			this.stringsToDates();
			this.loadOptions();
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'plz' && !this.placeNames[field.key] && this.track[field.key]) {
						this.getPlacesFromPostalCode(field.key);
					}
				});
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		setTimeout(() => this.logService.log("Information", 1, "ReversingCadastral", "ReversingCadastral.Edit::Open"), 5000);
	}

	datesToStrings() {
		this.fieldgroups.forEach(group => {
			group.forEach(field => {
				if (field.type === 'date' && this.track[field.key]) {
					this.track[field.key] = new Date(this.track[field.key]).toISOString();
				}
			});
		});
	}

	initMap() {
		useGeographic();
		const coord = [this.track.center_longitude, this.track.center_latitude];

		this.positionLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: new Style({
				image: new Circle({
					radius: 5,
					fill: new Fill({
						color: 'blue'
					})
				})
			})
		});

		this.routeLayer = new VectorLayer({
			source: new VectorSource({
				//url: '/assets' + this.track.gpx,
				url: this.gpxUnsafeUrl,
				format: new GPX(),
				crossOrigin: 'anonymous'
			}),
			style: (feature) => {
				var colorName = feature.get('desc') == 'v' ? this.app.forwardingColor : this.app.reversingColor;
				var colorRGB = asArray(colorName); // Farbe von Name in RGB Array umwandeln
				colorRGB = colorRGB.slice();
				colorRGB[3] = 0.7; // Alphakanal hinzufügen
				return new Style({
					stroke: new Stroke({
						color: colorRGB,
						width: 4
					})
				})
			}
		});

		this.routeLayer.getSource().on('error', this.mapError());

		this.mapRasEdit = new Map({
			target: 'mapRasEdit',
			view: new View({
				center: coord,
				zoom: 17,
				maxZoom: 18,
			}),
			controls: [],
			renderer: 'webgl',
			layers: [
				new TileLayer({
					source: new OSM({
						url: environment.mapUrl,
						format: 'image/png',
						crossOrigin: 'anonymous'
					})
				}),
				this.routeLayer,
				this.positionLayer
			]
		});

		this.mapRasEdit.on('singleclick', event => {
			const coord = this.mapRasEdit.getCoordinateFromPixel(event.pixel);
			let closestPoint = [0, 0, 0];
			this.trackPoints.forEach(tp => {
				const distCurrentPoint = Math.abs(tp[0] - coord[0]) + Math.abs(tp[1] - coord[1]);
				const distClosestPoint = Math.abs(closestPoint[0] - coord[0]) + Math.abs(closestPoint[1] - coord[1]);
				if (distCurrentPoint < distClosestPoint) {
					closestPoint = tp;
				}
			});
			const timestamp = closestPoint[2] - this.startTimeStamp;
			this.videoplayer.currentTime = timestamp;
			this.setVehiclePosition(timestamp);
		});
	}

	initGPX(event) {
		this.videoplayer = event.target;
		if (!this.trackPoints) {
			this.trackPoints = [];
			this.routeLayer.getSource().getFeatures().forEach(feature => {
				const chunkSize = 3;
				let points = [];
				for (let i = 0; i < feature.getGeometry().flatCoordinates.length; i += chunkSize) {
					const chunk = feature.getGeometry().flatCoordinates.slice(i, i + chunkSize);
					points.push(chunk);
					if (!this.startTimeStamp || +chunk[2] < this.startTimeStamp) {
						this.startTimeStamp = +chunk[2];
					}
				}
				this.trackPoints = [...this.trackPoints, ...points];
			});
		}

		this.setVehiclePosition(0);
	}

	inputDate(dateField, input: string) {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (this.track[dateField]) {
				date.setHours(this.track[dateField].getHours(), this.track[dateField].getMinutes(), this.track[dateField].getSeconds(), this.track[dateField].getMilliseconds());
			} else {
				date.setHours(0, 0, 0, 0);
			}
			this.track[dateField] = date;
		}
	}

	/**
	 * Holt alle Orte für eine PLZ
	 */
	getPlacesFromPostalCode(key) {
		if (this.track[key]) {
			this.loading += 1;
			this.crudService.getPlacesFromPostalCode(+this.track[key]).then(res => {
				this.placeNames[key] = res;
			}).catch(err => {
				//err.error.forEach(e => {
				//	if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
				//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				//	} else {
				//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				//	}
				//})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	loadOptions() {
		//this.loading += 1;
		//this.adminService.getCustomerSettings().then(res => {
		//	if (res) {
		//		this.rk_rating_boundaries = res.rk_rating_boundaries;
		//	}
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
//
		//this.loading += 1;
		//this.crudService.getAllRisks().then(res => {
		//	this.risks = res;
		//	this.track.fkey = 0
		//	this.risks.forEach(l => {
		//		this.options.gefahren.push({ label: l.bezeichnung, value: l.bezeichnung });
		//		this.track["gefahren"].forEach(x => {
		//			if (x == l.bezeichnung) {
		//				if (this.track.fkey) {
		//					this.track.fkey = this.track.fkey + l.value;
		//				} else {
		//					this.track.fkey = l.value;
		//				}
//
		//				if (this.track.fkey >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[5] : 50))
		//					this.rating = 5;
		//				else if (this.track.fkey >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[4] : 40))
		//					this.rating = 4;
		//				else if (this.track.fkey >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[3] : 30))
		//					this.rating = 3;
		//				else if (this.track.fkey >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[2] : 20))
		//					this.rating = 2;
		//				else if (this.track.fkey >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[1] : 10))
		//					this.rating = 1;
		//				else
		//					this.rating = 0;
		//				/*
		//				if (this.rating) {
//
		//					this.rating = this.rating + l.value <= 5 ? this.rating + l.value : 5;
		//				} else {
		//					this.rating = l.value;
		//				}
		//				*/
		//			}
		//		});
		//	});
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
//
		//this.loading += 1;
		//this.crudService.getAllOccasions().then(res => {
		//	this.occasions = res;
		//	this.occasions.forEach(l => {
		//		this.options.gruende.push({ label: l.bezeichnung, value: l.bezeichnung });
		//	});
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
//
		//this.loading += 1;
		//this.crudService.getAllArrangements().then(res => {
		//	this.arrangements = res;
		//	this.arrangements.forEach(l => {
		//		this.options.massnahmen.push({ label: l.bezeichnung, value: l.bezeichnung });
		//	});
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
//
		//this.loading += 1;
		//this.crudService.getAllStates().then(res => {
		//	this.states = res;
		//	this.states.forEach(l => {
		//		this.options.status.push({ label: l.bezeichnung, value: l.bezeichnung });
		//	});
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});

		this.loading += 1;
		this.crudService.getAllVehicles().then(res => {
			this.vehicles = res;
			this.vehicles.forEach(l => {
				if (l.kennzeichen) {
					this.options.fahrzeug_id.push({ label: l.kennzeichen, value: l.ds_this_id });
				} else {
					this.options.fahrzeug_id.push({ label: l.logbox_serial, value: l.ds_this_id });
				}
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllEntries('TblGebiet').then(res => {
			this.gebiete = res;
			this.gebiete.forEach(l => {
				if (l.bezeichnung) {
					this.options.gebiet_id.push({ label: l.bezeichnung, value: l.ds_this_id });
				} else {
					this.options.gebiet_id.push({ label: l.ds_this_id, value: l.ds_this_id });
				}
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllEntries('TblTour').then(res => {
			this.touren = res;
			this.touren.forEach(l => {
				if (l.bezeichnung) {
					this.options.tour_id.push({ label: l.bezeichnung, value: l.ds_this_id });
				} else {
					this.options.tour_id.push({ label: l.ds_this_id, value: l.ds_this_id });
				}
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllEntries('TblRueckfahrkatasterAkte').then(res => {
			this.rkFiles = res;
			this.rkFiles.forEach(l => {
				if (l.bezeichnung) {
					this.options.akte_id.push({ label: l.bezeichnung, value: l.ds_this_id });
				} else {
					this.options.akte_id.push({ label: l.ds_this_id, value: l.ds_this_id });
				}
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	save() {
		this.loading += 1;
		if (!this.stringIsNullOrWhitespace(this.track.plz_von)) {
			this.datesToStrings();
			this.updateVehicle();
			this.crudService.editTrack(this.track).then(res => {
				this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.id]);
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
		} else {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: 'PLZ' }), life: 3000 });
			this.loading -= 1;
		}
	}

	setVehiclePosition(videoTimestamp) {
		let position = this.trackPoints[0];
		this.trackPoints.forEach(tp => {
			if (Math.abs(this.startTimeStamp + videoTimestamp - tp[2]) < Math.abs(this.startTimeStamp + videoTimestamp - position[2])) { // evtl +5 Sekunden wegen GPS Buffer?!
				position = tp;
			}
		})

		const feature = new Feature({
			geometry: new Point([position[0], position[1]]),
		});
		this.positionLayer.getSource().clear();
		this.positionLayer.getSource().addFeature(feature);
	}

	stringsToDates() {
		this.fieldgroups.forEach(group => {
			group.forEach(field => {
				if (field.type === 'date') {
					if (this.track[field.key]) {
						this.track[field.key] = new Date(this.track[field.key]);
					}
				}
			});
		});
	}

	updateVehicle() {
		this.vehicles.forEach(v => {
			if (v.ds_this_id === this.track.fahrzeug_id) {
				this.track.Vehicle = v;
			}
		});
	}

	mapError() {
		if( this.gpxUnsafeUrl == null || this.gpxUnsafeUrl == undefined || this.gpxUnsafeUrl.length == 0 ) {
			this.messageService.add({ severity: 'error', summary: 'Map Error', detail: 'Can\'t load gpx file.', life: 10000 });
		}
	}

	videoError() {
		this.messageService.add({ severity: 'error', summary: 'Video Error', detail: 'Can\'t load video file.', life: 10000 });
		this.track.video_url = null;
	}
}

import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { EditComponent } from '@app/common/templates/edit/edit.component';
import { ListComponent } from '@app/common/templates/list/list.component';
import { ListExComponent } from '@app/common/templates/list/listEx.component';
import { DropdownDirective } from '@app/helpers/dropdown.directive';
import { ExceptionHandler } from '@app/helpers/error.handler';
import { MultiSelectDirective } from '@app/helpers/multiselect.directive';
import { EswComponent } from '@app/views/berichte/esw/container_overview/eswinput.component';
import { EswAnmeldungBaumschnittComponent } from '@app/views/berichte/esw/container_overview/esw_anmeldung_baumschnitt.component';
import { TblFahrtberichtDetailComponent } from '@app/views/berichte/tbl_fahrtbericht/detail/detail.component';
import { TblFahrtberichtEditComponent } from '@app/views/berichte/tbl_fahrtbericht/edit/edit.component';
import { ReportsTripsFilterDialogComponent } from '@app/views/berichte/tbl_fahrtbericht/filter_dialog/filter_dialog.component';
import { TblFahrtberichtListComponent } from '@app/views/berichte/tbl_fahrtbericht/list/list.component';
import { TblLeerungListComponent } from '@app/views/berichte/tbl_leerung/list/list.component';
import { TblAbfuhrrhythmusCreateComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/create/create.component';
import { TblAbfuhrrhythmusDetailComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/detail/detail.component';
import { TblAbfuhrrhythmusEditComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/edit/edit.component';
import { TblAbfuhrrhythmusListComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/list/list.component';
import { TblFirmaCreateComponent } from '@app/views/bhv/tbl_firma/create/create.component';
import { TblFirmaDetailComponent } from '@app/views/bhv/tbl_firma/detail/detail.component';
import { TblFirmaEditComponent } from '@app/views/bhv/tbl_firma/edit/edit.component';
import { TblFirmaListComponent } from '@app/views/bhv/tbl_firma/list/list.component';
import { TblGefaessCreateComponent } from '@app/views/bhv/tbl_gefaess/create/create.component';
import { TblGefaessDetailComponent } from '@app/views/bhv/tbl_gefaess/detail/detail.component';
import { TblGefaessEditComponent } from '@app/views/bhv/tbl_gefaess/edit/edit.component';
import { TblGefaessListComponent } from '@app/views/bhv/tbl_gefaess/list/list.component';
import { MoveGarbageCanDialogComponent } from '@app/views/bhv/tbl_gefaess/move_dialog/dialog.component';
import { TblGefaessStatusCreateComponent } from '@app/views/bhv/tbl_gefaess_status/create/create.component';
import { TblGefaessStatusDetailComponent } from '@app/views/bhv/tbl_gefaess_status/detail/detail.component';
import { TblGefaessStatusEditComponent } from '@app/views/bhv/tbl_gefaess_status/edit/edit.component';
import { TblGefaessStatusListComponent } from '@app/views/bhv/tbl_gefaess_status/list/list.component';
import { TblGefaessTypCreateComponent } from '@app/views/bhv/tbl_gefaess_typ/create/create.component';
import { TblGefaessTypDetailComponent } from '@app/views/bhv/tbl_gefaess_typ/detail/detail.component';
import { TblGefaessTypEditComponent } from '@app/views/bhv/tbl_gefaess_typ/edit/edit.component';
import { TblGefaessTypListComponent } from '@app/views/bhv/tbl_gefaess_typ/list/list.component';
import { TblGefaessSperreCreateComponent } from '@app/views/bhv/tbl_gefaess_sperre/create/create.component';
import { TblGefaessSperreDetailComponent } from '@app/views/bhv/tbl_gefaess_sperre/detail/detail.component';
import { TblGefaessSperreEditComponent } from '@app/views/bhv/tbl_gefaess_sperre/edit/edit.component';
import { TblGefaessSperreListComponent } from '@app/views/bhv/tbl_gefaess_sperre/list/list.component';
import { TblGefaessZustandCreateComponent } from '@app/views/bhv/tbl_gefaess_zustand/create/create.component';
import { TblGefaessZustandDetailComponent } from '@app/views/bhv/tbl_gefaess_zustand/detail/detail.component';
import { TblGefaessZustandEditComponent } from '@app/views/bhv/tbl_gefaess_zustand/edit/edit.component';
import { TblGefaessZustandListComponent } from '@app/views/bhv/tbl_gefaess_zustand/list/list.component';
import { TblGefaessMoveToHaldeDialogComponent } from '@app/views/bhv/tbl_gefaess/detail/move_to_halde_dialog/move_to_halde_dialog.component';
import { TblKundeCreateComponent } from '@app/views/bhv/tbl_kunde/create/create.component';
import { TblKundeDetailComponent } from '@app/views/bhv/tbl_kunde/detail/detail.component';
import { TblKundeEditComponent } from '@app/views/bhv/tbl_kunde/edit/edit.component';
import { TblKundeListComponent } from '@app/views/bhv/tbl_kunde/list/list.component';
import { TblAuftragCreateComponent } from '@app/views/bhv/tbl_auftrag/create/create.component';
import { TblAuftragDetailComponent } from '@app/views/bhv/tbl_auftrag/detail/detail.component';
import { TblAuftragEditComponent } from '@app/views/bhv/tbl_auftrag/edit/edit.component';
import { TblAuftragListComponent } from '@app/views/bhv/tbl_auftrag/list/list.component';
import { TblObjektCreateComponent } from '@app/views/bhv/tbl_objekt/create/create.component';
import { TblObjektDetailComponent } from '@app/views/bhv/tbl_objekt/detail/detail.component';
import { TblObjektEditComponent } from '@app/views/bhv/tbl_objekt/edit/edit.component';
import { TblObjektListComponent } from '@app/views/bhv/tbl_objekt/list/list.component';
import { SetUpGarbageCanDialogComponent } from '@app/views/bhv/tbl_objekt/set_up_garbage_can_dialog/dialog.component';
import { TblObjektGruppeCreateComponent } from '@app/views/bhv/tbl_objekt_gruppe/create/create.component';
import { TblObjektGruppeDetailComponent } from '@app/views/bhv/tbl_objekt_gruppe/detail/detail.component';
import { TblObjektGruppeEditComponent } from '@app/views/bhv/tbl_objekt_gruppe/edit/edit.component';
import { TblObjektGruppeListComponent } from '@app/views/bhv/tbl_objekt_gruppe/list/list.component';
import { TblObjektStatusCreateComponent } from '@app/views/bhv/tbl_objekt_status/create/create.component';
import { TblObjektStatusDetailComponent } from '@app/views/bhv/tbl_objekt_status/detail/detail.component';
import { TblObjektStatusEditComponent } from '@app/views/bhv/tbl_objekt_status/edit/edit.component';
import { TblObjektStatusListComponent } from '@app/views/bhv/tbl_objekt_status/list/list.component';
import { TblObjektTypCreateComponent } from '@app/views/bhv/tbl_objekt_typ/create/create.component';
import { TblObjektTypDetailComponent } from '@app/views/bhv/tbl_objekt_typ/detail/detail.component';
import { TblObjektTypEditComponent } from '@app/views/bhv/tbl_objekt_typ/edit/edit.component';
import { TblObjektTypListComponent } from '@app/views/bhv/tbl_objekt_typ/list/list.component';
import { TblAuftragGfAbmeldungDialogComponent } from './views/bhv/tbl_auftrag/list/gefaess_abmeldung_dialog/gefaess_abmeldung_dialog.component';
import { TblAuftragStatusDialogComponent } from './views/bhv/tbl_auftrag/list/auftrag_status_dialog/auftrag_status_dialog.component';
import { SettingsComponent } from '@app/views/einstellungen/allgemein/settings.component';
import { ChangePasswordComponent } from '@app/views/einstellungen/passwort/changepassword.component';
import { ManualComponent } from '@app/views/handbuch/manual.component';
import { HomeComponent } from '@app/views/home/home.component';
import { TermsComponent } from '@app/views/impressum/terms.component';
import { OnlineKarteComponent } from '@app/views/online/karte/map.component';
import { VehicleSelection } from '@app/views/online/karte/vehicle_selection/vehicle_selection.component';
import { OnlineCollectionsFilterDialogComponent } from '@app/views/online/leerung/collection_filter_dialog/collection_filter_dialog.component';
import { OnlineLeerungComponent } from '@app/views/online/leerung/collections.component';
import { TblRueckfahrkatasterAufzeichnungCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/create/create.component';
import { TblRueckfahrkatasterAufzeichnungDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/detail/detail.component';
import { TblRueckfahrkatasterAufzeichnungEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/edit/edit.component';
import { TblRueckfahrkatasterAufzeichnungListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/list/list.component';
import { TblRueckfahrkatasterAufzeichnungFilterDialogComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/list/filter_dialog/filter_dialog.component';
import { TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/popup_list_aktenzuweisung/list_aktenzuweisung.component';
import { TblRueckfahrkatasterAkteFilterDialogComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_akte/list/filter_dialog/filter_dialog.component';
import { TblRueckfahrkatasterGefahrCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/create/create.component';
import { TblRueckfahrkatasterGefahrDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/detail/detail.component';
import { TblRueckfahrkatasterGefahrEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/edit/edit.component';
import { TblRueckfahrkatasterGefahrListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/list/list.component';
import { TblRueckfahrkatasterGrundCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/create/create.component';
import { TblRueckfahrkatasterGrundDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/detail/detail.component';
import { TblRueckfahrkatasterGrundEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/edit/edit.component';
import { TblRueckfahrkatasterGrundListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/list/list.component';
import { TblRueckfahrkatasterMassnahmeCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/create/create.component';
import { TblRueckfahrkatasterMassnahmeDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/detail/detail.component';
import { TblRueckfahrkatasterMassnahmeEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/edit/edit.component';
import { TblRueckfahrkatasterMassnahmeListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/list/list.component';
import { TblRueckfahrkatasterStatusCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/create/create.component';
import { TblRueckfahrkatasterStatusDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/detail/detail.component';
import { TblRueckfahrkatasterStatusEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/edit/edit.component';
import { TblRueckfahrkatasterStatusListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/list/list.component';
import { TblAbfallartCreateComponent } from '@app/views/stammdaten/tbl_abfallart/create/create.component';
import { TblAbfallartDetailComponent } from '@app/views/stammdaten/tbl_abfallart/detail/detail.component';
import { TblAbfallartEditComponent } from '@app/views/stammdaten/tbl_abfallart/edit/edit.component';
import { TblAbfallartListComponent } from '@app/views/stammdaten/tbl_abfallart/list/list.component';
import { TblAufbauCreateComponent } from '@app/views/stammdaten/tbl_aufbau/create/create.component';
import { TblAufbauDetailComponent } from '@app/views/stammdaten/tbl_aufbau/detail/detail.component';
import { TblAufbauEditComponent } from '@app/views/stammdaten/tbl_aufbau/edit/edit.component';
import { TblAufbauListComponent } from '@app/views/stammdaten/tbl_aufbau/list/list.component';
import { TblFahrgestellCreateComponent } from '@app/views/stammdaten/tbl_fahrgestell/create/create.component';
import { TblFahrgestellDetailComponent } from '@app/views/stammdaten/tbl_fahrgestell/detail/detail.component';
import { TblFahrgestellEditComponent } from '@app/views/stammdaten/tbl_fahrgestell/edit/edit.component';
import { TblFahrgestellListComponent } from '@app/views/stammdaten/tbl_fahrgestell/list/list.component';
import { TblFahrzeugCreateComponent } from '@app/views/stammdaten/tbl_fahrzeug/create/create.component';
import { MasterdataChassisCreateDialog } from '@app/views/stammdaten/tbl_fahrzeug/create/createChassis/create.component';
import { MasterdataConstructionsCreateDialog } from '@app/views/stammdaten/tbl_fahrzeug/create/createConstruction/create.component';
import { MasterdataLifterCreateDialog } from '@app/views/stammdaten/tbl_fahrzeug/create/createLifter/create.component';
import { TblFahrzeugDetailComponent } from '@app/views/stammdaten/tbl_fahrzeug/detail/detail.component';
import { TblFahrzeugEditComponent } from '@app/views/stammdaten/tbl_fahrzeug/edit/edit.component';
import { TblFahrzeugListComponent } from '@app/views/stammdaten/tbl_fahrzeug/list/list.component';
import { TblGebietCreateComponent } from '@app/views/stammdaten/tbl_gebiet/create/create.component';
import { TblGebietDetailComponent } from '@app/views/stammdaten/tbl_gebiet/detail/detail.component';
import { TblGebietEditComponent } from '@app/views/stammdaten/tbl_gebiet/edit/edit.component';
import { TblGebietListComponent } from '@app/views/stammdaten/tbl_gebiet/list/list.component';
import { TblGemeindeCreateComponent } from '@app/views/stammdaten/tbl_gemeinde/create/create.component';
import { TblGemeindeDetailComponent } from '@app/views/stammdaten/tbl_gemeinde/detail/detail.component';
import { TblGemeindeEditComponent } from '@app/views/stammdaten/tbl_gemeinde/edit/edit.component';
import { TblGemeindeListComponent } from '@app/views/stammdaten/tbl_gemeinde/list/list.component';
import { TblLandCreateComponent } from '@app/views/stammdaten/tbl_land/create/create.component';
import { TblLandDetailComponent } from '@app/views/stammdaten/tbl_land/detail/detail.component';
import { TblLandEditComponent } from '@app/views/stammdaten/tbl_land/edit/edit.component';
import { TblLandListComponent } from '@app/views/stammdaten/tbl_land/list/list.component';
import { TblLeerungBarcodeStatusCreateComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/create/create.component';
import { TblLeerungBarcodeStatusDetailComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/detail/detail.component';
import { TblLeerungBarcodeStatusEditComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/edit/edit.component';
import { TblLeerungBarcodeStatusListComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/list/list.component';
import { TblLifterCreateComponent } from '@app/views/stammdaten/tbl_lifter/create/create.component';
import { TblLifterDetailComponent } from '@app/views/stammdaten/tbl_lifter/detail/detail.component';
import { TblLifterEditComponent } from '@app/views/stammdaten/tbl_lifter/edit/edit.component';
import { TblLifterListComponent } from '@app/views/stammdaten/tbl_lifter/list/list.component';
import { TblMitarbeiterCreateComponent } from '@app/views/stammdaten/tbl_mitarbeiter/create/create.component';
import { TblMitarbeiterDetailComponent } from '@app/views/stammdaten/tbl_mitarbeiter/detail/detail.component';
import { TblMitarbeiterEditComponent } from '@app/views/stammdaten/tbl_mitarbeiter/edit/edit.component';
import { TblMitarbeiterListComponent } from '@app/views/stammdaten/tbl_mitarbeiter/list/list.component';
import { TblOrtCreateComponent } from '@app/views/stammdaten/tbl_ort/create/create.component';
import { TblOrtDetailComponent } from '@app/views/stammdaten/tbl_ort/detail/detail.component';
import { TblOrtEditComponent } from '@app/views/stammdaten/tbl_ort/edit/edit.component';
import { TblOrtListComponent } from '@app/views/stammdaten/tbl_ort/list/list.component';
import { TblStrasseCreateComponent } from '@app/views/stammdaten/tbl_strasse/create/create.component';
import { TblStrasseDetailComponent } from '@app/views/stammdaten/tbl_strasse/detail/detail.component';
import { TblStrasseEditComponent } from '@app/views/stammdaten/tbl_strasse/edit/edit.component';
import { TblStrasseListComponent } from '@app/views/stammdaten/tbl_strasse/list/list.component';
import { TblTourCreateComponent } from '@app/views/stammdaten/tbl_tour/create/create.component';
import { TblTourDetailComponent } from '@app/views/stammdaten/tbl_tour/detail/detail.component';
import { TblTourEditComponent } from '@app/views/stammdaten/tbl_tour/edit/edit.component';
import { TblTourListComponent } from '@app/views/stammdaten/tbl_tour/list/list.component';
import { TblTourGruppeCreateComponent } from '@app/views/stammdaten/tbl_tour_gruppe/create/create.component';
import { TblTourGruppeDetailComponent } from '@app/views/stammdaten/tbl_tour_gruppe/detail/detail.component';
import { TblTourGruppeEditComponent } from '@app/views/stammdaten/tbl_tour_gruppe/edit/edit.component';
import { TblTourGruppeListComponent } from '@app/views/stammdaten/tbl_tour_gruppe/list/list.component';
import { TblVolumenCreateComponent } from '@app/views/stammdaten/tbl_volumen/create/create.component';
import { TblVolumenDetailComponent } from '@app/views/stammdaten/tbl_volumen/detail/detail.component';
import { TblVolumenEditComponent } from '@app/views/stammdaten/tbl_volumen/edit/edit.component';
import { TblVolumenListComponent } from '@app/views/stammdaten/tbl_volumen/list/list.component';
import { JwtModule } from '@auth0/angular-jwt';
import { Globals } from '@common/global_variables';
import { FilterDialogComponent } from '@common/templates/filter_dialog/filter_dialog.component';
import { AppBreadcrumbComponent } from '@components/app.breadcrumb.component';
import { AppComponent } from '@components/app.component';
import { AppFooterComponent } from '@components/app.footer.component';
import { AppMainComponent } from '@components/app.main.component';
import { AppMenuComponent } from '@components/app.menu.component';
import { MenuService } from '@components/app.menu.service';
import { AppMenuitemComponent } from '@components/app.menuitem.component';
import { AppTopBarComponent } from '@components/app.topbar.component';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { CommonListViewElementsComponent } from '@components/common-list-view-elements';
import { environment } from '@environments/environment';
import { ConfigProvider } from '@helpers/config.provider';
import { RequestInterceptor } from '@helpers/request.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppAccessdeniedComponent } from '@pages/app.accessdenied.component';
import { AppErrorComponent } from '@pages/app.error.component';
import { GdpaComponent } from '@pages/app.gdpa.component';
import { AppLoginComponent } from '@pages/app.login.component';
import { AppNotfoundComponent } from '@pages/app.notfound.component';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { AccountService } from '@services/account.service';
import { AdminService } from '@services/admin.service';
import { CRUDService } from '@services/crud.service';
import { DateService } from '@services/date.service';
import { ExportService } from '@services/export.service';
import { LogService } from '@services/log.service';
import { MapService } from '@services/map.service';
import { NetService } from '@services/net.service';
import { SettingsService } from '@services/settings.service';
import { OrganizationComponent } from '@views/admin/clients/organization/organization.component';
import { AdminUsersCreateComponent } from '@views/admin/clients/users/create/create.component';
import { AdminUsersDetailComponent } from '@views/admin/clients/users/detail/detail.component';
import { AdminUsersEditComponent } from '@views/admin/clients/users/edit/edit.component';
import { AdminUsersListComponent } from '@views/admin/clients/users/list/list.component';
import { AdminVisyConnectionSettingsCreateComponent } from '@views/admin/visy/connection_settings/create/create.component';
import { AdminVisyConnectionSettingsDetailComponent } from '@views/admin/visy/connection_settings/detail/detail.component';
import { AdminVisyConnectionSettingsEditComponent } from '@views/admin/visy/connection_settings/edit/edit.component';
import { AdminVisyConnectionSettingsListComponent } from '@views/admin/visy/connection_settings/list/list.component';
import { AdminVisyUsersCreateComponent } from '@views/admin/visy/users/create/create.component';
import { AdminVisyUsersCreateTestuserComponent } from '@views/admin/visy/users/createtestuser/create.component';
import { AdminVisyUsersDetailComponent } from '@views/admin/visy/users/detail/detail.component';
import { AdminVisyUsersEditComponent } from '@views/admin/visy/users/edit/edit.component';
import { AdminVisyUsersListComponent } from '@views/admin/visy/users/list/list.component';
import { VToolsCreateComponent } from '@views/admin/visy/users/vtools/create.component';
import { TblLeerungFilterDialogComponent } from '@views/berichte/tbl_leerung/filter_dialog/filter_dialog.component';
import { ObjektGefaessStapelverarbeitungComponent } from '@views/bhv/stapelverarbeitung/stapelverarbeitung.component';
import { UploadIdentcodesperreFromTextfileComponent } from '@views/bhv/tbl_gefaess_sperre/upload_identcodesperren_dialog/upload_identcodesperren.component';
import { TblGefaessFilterDialogComponent } from '@views/bhv/tbl_gefaess/list/filter_dialog/filter_dialog.component';
import { TblKundeFilterDialogComponent } from '@views/bhv/tbl_kunde/list/filter_dialog/filter_dialog.component';
import { TblAuftragFilterDialogComponent } from '@views/bhv/tbl_auftrag/list/filter_dialog/filter_dialog.component';
import { TblObjektFilterDialogComponent } from '@views/bhv/tbl_objekt/list/filter_dialog/filter_dialog.component';
import { TblObjektSetEigentuemerDialogComponent } from './views/bhv/tbl_objekt/detail/set_eigentuemer_dialog/set_eigentuemer_dialog.component';
import { TblRueckfahrkatasterAkteListComponent } from '@views/rueckfahrkataster/tbl_rueckfahrkataster_akte/list/list.component';
import { TblAbfallartTypCreateComponent } from '@views/stammdaten/tbl_abfallart_typ/create/create.component';
import { TblAbfallartTypDetailComponent } from '@views/stammdaten/tbl_abfallart_typ/detail/detail.component';
import { TblAbfallartTypEditComponent } from '@views/stammdaten/tbl_abfallart_typ/edit/edit.component';
import { TblAbfallartTypListComponent } from '@views/stammdaten/tbl_abfallart_typ/list/list.component';
import { TblAnredeCreateComponent } from '@views/stammdaten/tbl_anrede/create/create.component';
import { TblAnredeDetailComponent } from '@views/stammdaten/tbl_anrede/detail/detail.component';
import { TblAnredeEditComponent } from '@views/stammdaten/tbl_anrede/edit/edit.component';
import { TblAnredeListComponent } from '@views/stammdaten/tbl_anrede/list/list.component';
import { TblAvvCreateComponent } from '@views/stammdaten/tbl_avv/create/create.component';
import { TblAvvDetailComponent } from '@views/stammdaten/tbl_avv/detail/detail.component';
import { TblAvvEditComponent } from '@views/stammdaten/tbl_avv/edit/edit.component';
import { TblAvvListComponent } from '@views/stammdaten/tbl_avv/list/list.component';
import { MaintenanceDialog } from '@views/stammdaten/tbl_fahrzeug/list/maintenanceDialog/maintenanceDialog.component';
import { TblTourTypCreateComponent } from '@views/stammdaten/tbl_tour_typ/create/create.component';
import { TblTourTypDetailComponent } from '@views/stammdaten/tbl_tour_typ/detail/detail.component';
import { TblTourTypEditComponent } from '@views/stammdaten/tbl_tour_typ/edit/edit.component';
import { TblTourTypListComponent } from '@views/stammdaten/tbl_tour_typ/list/list.component';
import { CookieService } from 'ngx-cookie-service';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ZPLPrintService } from './services/zplprint.service';
import { TblRueckfahrkatasterAkteCreateComponent } from './views/rueckfahrkataster/tbl_rueckfahrkataster_akte/create/create.component';
import { TblRueckfahrkatasterAkteDetailComponent } from './views/rueckfahrkataster/tbl_rueckfahrkataster_akte/detail/detail.component';
import { TblRueckfahrkatasterAkteEditComponent } from './views/rueckfahrkataster/tbl_rueckfahrkataster_akte/edit/edit.component';
import { PopupFromToReasonComponent } from '@app/common/popup/popupFromToReason.component';
import { PopupCloseOnReasonAdditionComponent } from './common/popup/popupCloseOnReasonAddition.component';
import { TblKomponenteTypListComponent } from './views/stammdaten/tbl_komponente_typ/list/list.component';
import { TblKomponenteTypCreateComponent } from './views/stammdaten/tbl_komponente_typ/create/create.component';
import { TblKomponenteTypEditComponent } from './views/stammdaten/tbl_komponente_typ/edit/edit.component';
import { TblKomponenteTypDetailComponent } from './views/stammdaten/tbl_komponente_typ/detail/detail.component';
import { TblKomponenteListComponent } from './views/stammdaten/tbl_komponente/list/list.component';
import { TblKomponenteCreateComponent } from './views/stammdaten/tbl_komponente/create/create.component';
import { TblKomponenteEditComponent } from './views/stammdaten/tbl_komponente/edit/edit.component';
import { TblKomponenteDetailComponent } from './views/stammdaten/tbl_komponente/detail/detail.component';
import { TblFahrzeugAkteKomponenteLinkDetailComponent } from './views/stammdaten/tbl_komponente_fahrzeugakte_link/detail/detail.component';
import { ComponentToVehicleDialogComponent } from './views/stammdaten/tbl_komponente/detail/component_to_vehicle_dialog/component_to_vehicle_dialog.component';
import { VehicleAddComponentDialogComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_add_component_dialog/vehicle_add_component_dialog.component';
import { TblFahrzeugAkteKomponenteLinkEditComponent } from './views/stammdaten/tbl_komponente_fahrzeugakte_link/edit/edit.component';
import { TblFahrzeugAbwicklungAbschnittTypListComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/list/list.component';
import { TblFahrzeugAbwicklungAbschnittTypCreateComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/create/create.component';
import { TblFahrzeugAbwicklungAbschnittTypEditComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/edit/edit.component';
import { TblFahrzeugAbwicklungAbschnittTypDetailComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/detail/detail.component';
import { TblFahrzeugAbwicklungAbschnittCreateComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/create/create.component';
import { TblFahrzeugAbwicklungAbschnittDetailComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/detail/detail.component';
import { TblFahrzeugAbwicklungAbschnittEditComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/edit/edit.component';
import { TblFahrzeugAbwicklungAbschnittListComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/list/list.component';
import { VehicleAddProcesstaskDialogComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_add_processtask_dialog/vehicle_add_processtask_dialog.component';
import { VehicleCheckProcessTaskDialogComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_check_processtask_dialog/vehicle_check_processtask_dialog.component';
import { VehicleAddProcessScheduleDialogComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_add_process_schedule/vehicle_add_process_schedule_dialog.component';
import { VehicleProcessScheduleHistoryListComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_process_schedule_history_list/vehicle_process_schedule_history_list.component';
import { VehicleProcessScheduleDetailComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_detail_process_schedule/vehicle_detail_process_schedule.component';
import { VehicleProcessScheduleEditComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_edit_process_schedule/vehicle_edit_process_schedule.component';
import { VehicleAddMultipleComponentsDialogComponent } from './views/stammdaten/tbl_fahrzeug/detail/vehicle_add_multiple_components_dialog/vehicle_add_multiple_components_dialog.component';
import { TblRueckfahrkatasterAufzeichnungGebietszuweisungListComponent } from './views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/popup_list_gebietszuweisung/list_gebietszuweisung.component';

registerLocaleData(localeDe);

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function configProviderFactory(provider: ConfigProvider) {
	return () => provider.load();
}

export function getToken() {
	return localStorage.getItem('jwt');
}

@NgModule({
	imports: [
		AccordionModule,
		AppRoutingModule,
		AutoCompleteModule,
		BreadcrumbModule,
		BrowserAnimationsModule,
		BrowserModule,
		ButtonModule,
		CalendarModule,
		CardModule,
		CarouselModule,
		ChartModule,
		CheckboxModule,
		ChipsModule,
		CodeHighlighterModule,
		ColorPickerModule,
		ConfirmDialogModule,
		ContextMenuModule,
		DataViewModule,
		DialogModule,
		DragDropModule,
		DropdownModule,
		DynamicDialogModule,
		FieldsetModule,
		FileUploadModule,
		FormsModule,
		FullCalendarModule,
		GalleriaModule,
		HttpClientModule,
		InplaceModule,
		InputMaskModule,
		InputNumberModule,
		InputSwitchModule,
		InputTextareaModule,
		InputTextModule,
		LightboxModule,
		ListboxModule,
		MegaMenuModule,
		MenubarModule,
		MenuModule,
		MessageModule,
		MessagesModule,
		MultiSelectModule,
		OrderListModule,
		OrganizationChartModule,
		OverlayPanelModule,
		PaginatorModule,
		PanelMenuModule,
		PanelModule,
		PasswordModule,
		PickListModule,
		ProgressBarModule,
		RadioButtonModule,
		RatingModule,
		RippleModule,
		ScrollPanelModule,
		SelectButtonModule,
		SidebarModule,
		SlideMenuModule,
		SliderModule,
		SpinnerModule,
		SplitButtonModule,
		SplitterModule,
		StepsModule,
		TableModule,
		TabMenuModule,
		TabViewModule,
		TerminalModule,
		TieredMenuModule,
		ToastModule,
		ToggleButtonModule,
		ToolbarModule,
		TooltipModule,
		TreeModule,
		TreeTableModule,
		TriStateCheckboxModule,
		VirtualScrollerModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			defaultLanguage: 'de'
		}),
		JwtModule.forRoot({
			config: {
				tokenGetter: getToken,
				allowedDomains: [environment.apiUrl.replace('http://', '').replace('https://', '')]
			}
		}),
	],
	declarations: [
		AdminUsersCreateComponent,
		AdminUsersDetailComponent,
		AdminUsersEditComponent,
		AdminUsersListComponent,
		AdminVisyConnectionSettingsCreateComponent,
		AdminVisyConnectionSettingsDetailComponent,
		AdminVisyConnectionSettingsEditComponent,
		AdminVisyConnectionSettingsListComponent,
		AdminVisyUsersCreateComponent,
		AdminVisyUsersCreateTestuserComponent,
		AdminVisyUsersDetailComponent,
		AdminVisyUsersEditComponent,
		AdminVisyUsersListComponent,
		AppAccessdeniedComponent,
		AppBreadcrumbComponent,
		AppComponent,
		AppErrorComponent,
		AppFooterComponent,
		AppLoginComponent,
		AppMainComponent,
		AppMenuComponent,
		AppMenuitemComponent,
		AppNotfoundComponent,
		AppTopBarComponent,
		ChangePasswordComponent,
		CreateComponent,
		DetailComponent,
		DropdownDirective,
		MultiSelectDirective,
		EditComponent,
		EswComponent,
		EswAnmeldungBaumschnittComponent,
		FilterDialogComponent,
		GdpaComponent,
		HomeComponent,
		ListComponent,
		ListExComponent,
		MaintenanceDialog,
		ManualComponent,
		MasterdataChassisCreateDialog,
		MasterdataConstructionsCreateDialog,
		MasterdataLifterCreateDialog,
		MoveGarbageCanDialogComponent,
		MultiSelectDirective,
		OnlineCollectionsFilterDialogComponent,
		OnlineKarteComponent,
		OnlineLeerungComponent,
		OrganizationComponent,
		ReportsTripsFilterDialogComponent,
		SettingsComponent,
		SetUpGarbageCanDialogComponent,
		TblAbfallartCreateComponent,
		TblAbfallartDetailComponent,
		TblAbfallartEditComponent,
		TblAbfallartListComponent,
		TblAbfallartTypCreateComponent,
		TblAbfallartTypDetailComponent,
		TblAbfallartTypEditComponent,
		TblAbfallartTypListComponent,
		TblAbfuhrrhythmusCreateComponent,
		TblAbfuhrrhythmusDetailComponent,
		TblAbfuhrrhythmusEditComponent,
		TblAbfuhrrhythmusListComponent,
		TblAnredeCreateComponent,
		TblAnredeDetailComponent,
		TblAnredeEditComponent,
		TblAnredeListComponent,
		TblAufbauCreateComponent,
		TblAufbauDetailComponent,
		TblAufbauEditComponent,
		TblAufbauListComponent,
		TblAvvCreateComponent,
		TblAvvDetailComponent,
		TblAvvEditComponent,
		TblAvvListComponent,
		TblFahrgestellCreateComponent,
		TblFahrgestellDetailComponent,
		TblFahrgestellEditComponent,
		TblFahrgestellListComponent,
		TblFahrtberichtDetailComponent,
		TblFahrtberichtEditComponent,
		TblFahrtberichtListComponent,
		TblFahrzeugCreateComponent,
		TblFahrzeugDetailComponent,
		TblFahrzeugEditComponent,
		TblFahrzeugListComponent,
		TblKomponenteTypListComponent,
		TblKomponenteTypCreateComponent,
		TblKomponenteTypEditComponent,
		TblKomponenteTypDetailComponent,
		TblKomponenteListComponent,
		TblKomponenteCreateComponent,
		TblKomponenteEditComponent,
		TblKomponenteDetailComponent,
		TblFahrzeugAkteKomponenteLinkDetailComponent,
		TblFahrzeugAkteKomponenteLinkEditComponent,
		TblFirmaCreateComponent,
		TblFirmaDetailComponent,
		TblFirmaEditComponent,
		TblFirmaListComponent,
		TblGebietCreateComponent,
		TblGebietDetailComponent,
		TblGebietEditComponent,
		TblGebietListComponent,
		TblGefaessCreateComponent,
		TblGefaessDetailComponent,
		TblGefaessEditComponent,
		TblGefaessFilterDialogComponent,
		TblGefaessListComponent,
		TblGefaessStatusCreateComponent,
		TblGefaessStatusDetailComponent,
		TblGefaessStatusEditComponent,
		TblGefaessStatusListComponent,
		TblGefaessTypCreateComponent,
		TblGefaessTypDetailComponent,
		TblGefaessTypEditComponent,
		TblGefaessTypListComponent,
		TblGefaessSperreCreateComponent,
		TblGefaessSperreDetailComponent,
		TblGefaessSperreEditComponent,
		TblGefaessSperreListComponent,
		TblGefaessZustandCreateComponent,
		TblGefaessZustandDetailComponent,
		TblGefaessZustandEditComponent,
		TblGefaessZustandListComponent,
		TblGefaessMoveToHaldeDialogComponent,
		TblGemeindeCreateComponent,
		TblGemeindeDetailComponent,
		TblGemeindeEditComponent,
		TblGemeindeListComponent,
		TblKundeCreateComponent,
		TblKundeDetailComponent,
		TblKundeEditComponent,
		TblKundeFilterDialogComponent,
		TblKundeListComponent,
		TblAuftragCreateComponent,
		TblAuftragDetailComponent,
		TblAuftragEditComponent,
		TblAuftragFilterDialogComponent,
		TblAuftragListComponent,
		TblLandCreateComponent,
		TblLandDetailComponent,
		TblLandEditComponent,
		TblLandListComponent,
		TblLeerungBarcodeStatusCreateComponent,
		TblLeerungBarcodeStatusDetailComponent,
		TblLeerungBarcodeStatusEditComponent,
		TblLeerungBarcodeStatusListComponent,
		TblLeerungFilterDialogComponent,
		TblLeerungListComponent,
		TblLifterCreateComponent,
		TblLifterDetailComponent,
		TblLifterEditComponent,
		TblLifterListComponent,
		TblMitarbeiterCreateComponent,
		TblMitarbeiterDetailComponent,
		TblMitarbeiterEditComponent,
		TblMitarbeiterListComponent,
		TblObjektCreateComponent,
		TblObjektDetailComponent,
		TblObjektEditComponent,
		TblObjektFilterDialogComponent,
		TblObjektSetEigentuemerDialogComponent,
		TblObjektGruppeCreateComponent,
		TblObjektGruppeDetailComponent,
		TblObjektGruppeEditComponent,
		TblObjektGruppeListComponent,
		TblObjektListComponent,
		TblObjektStatusCreateComponent,
		TblObjektStatusDetailComponent,
		TblObjektStatusEditComponent,
		TblObjektStatusListComponent,
		TblObjektTypCreateComponent,
		TblObjektTypDetailComponent,
		TblObjektTypEditComponent,
		TblObjektTypListComponent,
		TblOrtCreateComponent,
		TblOrtDetailComponent,
		TblOrtEditComponent,
		TblOrtListComponent,
		TblRueckfahrkatasterGefahrCreateComponent,
		TblRueckfahrkatasterGefahrDetailComponent,
		TblRueckfahrkatasterGefahrEditComponent,
		TblRueckfahrkatasterGefahrListComponent,
		TblRueckfahrkatasterGrundCreateComponent,
		TblRueckfahrkatasterGrundDetailComponent,
		TblRueckfahrkatasterGrundEditComponent,
		TblRueckfahrkatasterGrundListComponent,
		TblRueckfahrkatasterMassnahmeCreateComponent,
		TblRueckfahrkatasterMassnahmeDetailComponent,
		TblRueckfahrkatasterMassnahmeEditComponent,
		TblRueckfahrkatasterMassnahmeListComponent,
		TblRueckfahrkatasterStatusCreateComponent,
		TblRueckfahrkatasterStatusDetailComponent,
		TblRueckfahrkatasterStatusEditComponent,
		TblRueckfahrkatasterStatusListComponent,
		TblRueckfahrkatasterAufzeichnungCreateComponent,
		TblRueckfahrkatasterAufzeichnungDetailComponent,
		TblRueckfahrkatasterAufzeichnungEditComponent,
		TblRueckfahrkatasterAufzeichnungListComponent,
		TblRueckfahrkatasterAufzeichnungFilterDialogComponent,
		TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent,
		TblRueckfahrkatasterAufzeichnungGebietszuweisungListComponent,
		TblRueckfahrkatasterAkteFilterDialogComponent,
		TblRueckfahrkatasterAkteListComponent,
		TblRueckfahrkatasterAkteCreateComponent,
		TblRueckfahrkatasterAkteDetailComponent,
		TblRueckfahrkatasterAkteEditComponent,
		TblStrasseCreateComponent,
		TblStrasseDetailComponent,
		TblStrasseEditComponent,
		TblStrasseListComponent,
		TblTourCreateComponent,
		TblTourDetailComponent,
		TblTourEditComponent,
		TblTourListComponent,
		TblTourGruppeCreateComponent,
		TblTourGruppeDetailComponent,
		TblTourGruppeEditComponent,
		TblTourGruppeListComponent,
		TblTourTypCreateComponent,
		TblTourTypDetailComponent,
		TblTourTypEditComponent,
		TblTourTypListComponent,
		TblVolumenCreateComponent,
		TblVolumenDetailComponent,
		TblVolumenEditComponent,
		TblVolumenListComponent,
		TblAuftragGfAbmeldungDialogComponent,
		TblAuftragStatusDialogComponent,
		ComponentToVehicleDialogComponent,
		VehicleAddComponentDialogComponent,
		VehicleAddMultipleComponentsDialogComponent,
		TermsComponent,
		VehicleSelection,
		VToolsCreateComponent,
		ObjektGefaessStapelverarbeitungComponent,
		UploadIdentcodesperreFromTextfileComponent,
		CommonListViewElementsComponent,
		SafeHtmlPipe,
		PopupFromToReasonComponent,
		PopupCloseOnReasonAdditionComponent,
		TblFahrzeugAbwicklungAbschnittTypListComponent,
		TblFahrzeugAbwicklungAbschnittTypCreateComponent,
		TblFahrzeugAbwicklungAbschnittTypEditComponent,
		TblFahrzeugAbwicklungAbschnittTypDetailComponent,
		TblFahrzeugAbwicklungAbschnittListComponent,
		TblFahrzeugAbwicklungAbschnittCreateComponent,
		TblFahrzeugAbwicklungAbschnittEditComponent,
		TblFahrzeugAbwicklungAbschnittDetailComponent,
		VehicleAddProcesstaskDialogComponent,
		VehicleCheckProcessTaskDialogComponent,
		VehicleAddProcessScheduleDialogComponent,
		VehicleProcessScheduleHistoryListComponent,
		VehicleProcessScheduleDetailComponent,
		VehicleProcessScheduleEditComponent
	],

	// Hier müssen alle Komponenten eingetragen werden, die als Popup geöffnet werden sollen
	entryComponents: [
		MaintenanceDialog,
		MasterdataChassisCreateDialog,
		MasterdataConstructionsCreateDialog,
		MasterdataLifterCreateDialog,
		MoveGarbageCanDialogComponent,
		OnlineCollectionsFilterDialogComponent,
		ReportsTripsFilterDialogComponent,
		SetUpGarbageCanDialogComponent,
		TblAnredeCreateComponent,
		TblGefaessFilterDialogComponent,
		TblKundeFilterDialogComponent,
		TblAuftragFilterDialogComponent,
		TblLeerungFilterDialogComponent,
		TblObjektFilterDialogComponent,
		VehicleSelection,
		VToolsCreateComponent,
		PopupFromToReasonComponent,
		ObjektGefaessStapelverarbeitungComponent,
		UploadIdentcodesperreFromTextfileComponent
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: ExceptionHandler
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: configProviderFactory,
			deps: [ConfigProvider],
			multi: true
		},
		AccountService,
		AdminService,
		BreadcrumbService,
		ConfigProvider,
		CookieService,
		CRUDService,
		DatePipe,
		ExportService,
		Globals,
		LogService,
		LogService,
		MapService,
		MenuService,
		NetService,
		SettingsService,
		DateService,
		ZPLPrintService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

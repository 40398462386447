<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading===true" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<div class="layout-config-content">
				<div class="p-grid p-formgrid">
					<div class="p-col-12 p-md-12 p-lg-4 p-mb-6">
						<h4 class="p-mb-4" translate>SETTINGS.GENERAL_SETTINGS</h4>

						<h6 style="margin-top: 0" translate>SETTINGS.LANGUAGE</h6>
						<p-dropdown [options]="languages" [(ngModel)]="selectedLanguage"
							(ngModelChange)="switchLanguage()" placeholder="Sprache wählen" [showClear]="true"
							dropdownDirective>
						</p-dropdown>

						<h6 translate>SETTINGS.LANDING_PAGE.SELECT_LANDING_PAGE</h6>
						<p-dropdown [options]="pages" [(ngModel)]="selectedLandingPage"
							(ngModelChange)="switchLandingPage()" placeholder="Startseite wählen" [showClear]="true"
							dropdownDirective>
						</p-dropdown>

						<h6 translate>SETTINGS.RESTORE_LAST_PAGE_AS_LANDING</h6>
						<p-checkbox [(ngModel)]="app.restoreLastPageOnOpen" [binary]="true"
							(onChange)="changeRestoreLastPageOnOpen()"></p-checkbox>

						<h6 translate>SETTINGS.MAX_ENTRIES</h6>
						<p-dropdown [options]="entryCounts" [(ngModel)]="maxEntries"
							(ngModelChange)="switchMaxEntries()" placeholder="Maximale Tabelleneinträge"
							dropdownDirective>
						</p-dropdown>

						<h6 translate>SETTINGS.FONT_SIZE</h6>
						<p-inputNumber (onInput)="setFontSize(app.fontSize)" [(ngModel)]="app.fontSize" [min]="8"
							[max]="24" [showButtons]="true" suffix="px">
						</p-inputNumber>

						<h6 translate>SETTINGS.INPUT_STYLE</h6>
						<div class="p-formgroup-inline">
							<div class="p-field-radiobutton">
								<p-radioButton inputId="input_outlined" name="inputstyle" [(ngModel)]="app.inputStyle"
									(onClick)="setInputStyle($event, 'outlined')" value="outlined"></p-radioButton>
								<label for="input_outlined" translate>SETTINGS.OUTLINED</label>
							</div>
							<div class="p-field-radiobutton">
								<p-radioButton inputId="input_filled" name="inputstyle" [(ngModel)]="app.inputStyle"
									(onClick)="setInputStyle($event, 'filled')" value="filled"></p-radioButton>
								<label for="input_filled" translate>SETTINGS.FILLED</label>
							</div>
						</div>

						<h6 translate>SETTINGS.MENU</h6>
						<div class="p-formgroup-inline">
							<div class="p-field-radiobutton">
								<p-radioButton inputId="overlay" name="layoutMode" [value]="false"
									[(ngModel)]="app.horizontalMenu" (onClick)="setMenuHorizontal($event, false)">
								</p-radioButton>
								<label for="static" translate>SETTINGS.VERTICAL</label>
							</div>
							<div class="p-field-radiobutton">
								<p-radioButton inputId="horizontal" name="layoutMode" [value]="true"
									[(ngModel)]="app.horizontalMenu" (onClick)="setMenuHorizontal($event, true)">
								</p-radioButton>
								<label for="horizontal" translate>SETTINGS.HORIZONTAL</label>
							</div>
						</div>

						<h6 translate>SETTINGS.COLOR_SCHEME</h6>
						<div class="p-formgroup-inline">
							<div class="p-field-radiobutton">
								<p-radioButton inputId="light" name="colorScheme" [value]="false"
									[(ngModel)]="app.darkMode" (onClick)="changeLayout($event, false)"></p-radioButton>
								<label for="light" translate>SETTINGS.LIGHT</label>
							</div>
							<div class="p-field-radiobutton">
								<p-radioButton inputId="dark" name="colorScheme" [value]="true"
									[(ngModel)]="app.darkMode" (onClick)="changeLayout($event, true)"></p-radioButton>
								<label for="dark" translate>SETTINGS.DARK</label>
							</div>
						</div>

						<h6 translate>SETTINGS.COLOR_THEME</h6>
						<div class="layout-themes">
							<div *ngFor="let t of colors">
								<a style="cursor: pointer" (click)="changeComponentTheme($event, t.file)"
									[ngStyle]="{'background-color': t.color}">
									<i class="pi pi-check" *ngIf="app.themeColor === t.file"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="p-col-12 p-md-12 p-lg-4 p-mb-6">
						<h4 class="p-mb-4" translate>SETTINGS.ONLINEVIEWER_SETTINGS</h4>
						<h6 translate>SETTINGS.LIVE_ROUTE_COLOR</h6>
						<p-colorPicker [(ngModel)]="app.defaultLiveRouteColor" (onHide)="changeDefaultLiveRouteColor()"
							appendTo="body" styleClass="colorpicker" pTooltip="{{'BUTTONS.PICK_COLOR'|translate}}"
							tooltipPosition="bottom" showDelay="500"></p-colorPicker>
						<p-inputMask #colorInputText mask="#******" [(ngModel)]="app.defaultLiveRouteColor"
							(onComplete)="changeDefaultLiveRouteColor()"
							(onBlur)="app.defaultLiveRouteColor = getFromStorage('defaultLiveRouteColor')"
							characterPattern="[A-Fa-f]+$"></p-inputMask>
						<h6 translate>SETTINGS.OLD_ROUTE_COLOR</h6>
						<p-colorPicker [(ngModel)]="app.defaultOldRouteColor" (onHide)="changeDefaultOldRouteColor()"
							appendTo="body" styleClass="colorpicker" pTooltip="{{'BUTTONS.PICK_COLOR'|translate}}"
							tooltipPosition="bottom" showDelay="500"></p-colorPicker>
						<p-inputMask #colorInputText mask="#******" [(ngModel)]="app.defaultOldRouteColor"
							(onComplete)="changeDefaultOldRouteColor()"
							(onBlur)="app.defaultOldRouteColor = getFromStorage('defaultOldRouteColor')"
							characterPattern="[A-Fa-f]+$"></p-inputMask>
						<h6 translate>SETTINGS.DEFAULT_OPEN_VEHICLE_DIALOG</h6>
						<p-checkbox [(ngModel)]="app.defaultOpenVehicleDialog" [binary]="true"
							(onChange)="changeDefaultOpenVehicleDialog()"></p-checkbox>
						<h6 translate>SETTINGS.DEFAULT_ROUTE_VISIBILITY</h6>
						<p-checkbox [(ngModel)]="app.defaultShowRoutes" [binary]="true"
							(onChange)="changeDefaultRouteVisibility()"></p-checkbox>
						<h6 translate>SETTINGS.SHOW_CLIENTS</h6>
						<p-checkbox [(ngModel)]="app.showClients" [binary]="true" (onChange)="changeShowClients()">
						</p-checkbox>
						<h6 translate>SETTINGS.SHOW_AREAS</h6>
						<p-checkbox [(ngModel)]="app.defaultShowAreas" [binary]="true" (onChange)="changeShowAreas()">
						</p-checkbox>
						<h6 translate>SETTINGS.SHOW_TIMELINE</h6>
						<p-checkbox [(ngModel)]="app.defaultShowTimeline" [binary]="true"
							(onChange)="changeShowTimeline()">
						</p-checkbox>
						<h6 translate>SETTINGS.TIMELINE_ACCURACY</h6>
						<p-inputNumber (onInput)="setTimelineAccuracy(app.timelineAccuracy)"
							[(ngModel)]="app.timelineAccuracy" [min]="1" [max]="100" [showButtons]="true" suffix="%">
						</p-inputNumber>
						<h6 translate>SETTINGS.SHOW_COLLECTIONS</h6>
						<p-checkbox [(ngModel)]="app.defaultShowCollections" [binary]="true"
							(onChange)="changeShowCollections()">
						</p-checkbox>
					</div>
					<div class="p-col-12 p-md-12 p-lg-4 p-mb-6">
						<h4 class="p-mb-4" translate>SETTINGS.REVERSINGCADASTRAL_SETTINGS</h4>
						<h5 class="p-mb-4" translate>SETTINGS.RAS_AUFZEICHNUNG.HEADER_AUFZEICHNUNG</h5>
						<h6 translate>SETTINGS.FORWARDING_COLOR</h6>
						<p-colorPicker [(ngModel)]="app.forwardingColor" (onHide)="changeForwardingColor()"
							appendTo="body" styleClass="colorpicker" pTooltip="{{'BUTTONS.PICK_COLOR'|translate}}"
							tooltipPosition="bottom" showDelay="500"></p-colorPicker>
						<p-inputMask #colorInputText mask="#******" [(ngModel)]="app.forwardingColor"
							(onComplete)="changeForwardingColor()"
							(onBlur)="app.forwardingColor = getFromStorage('forwardingColor')"
							characterPattern="[A-Fa-f]+$"></p-inputMask>
						<h6 translate>SETTINGS.REVERSING_COLOR</h6>
						<p-colorPicker [(ngModel)]="app.reversingColor" (onHide)="changeReversingColor()"
							appendTo="body" styleClass="colorpicker" pTooltip="{{'BUTTONS.PICK_COLOR'|translate}}"
							tooltipPosition="bottom" showDelay="500"></p-colorPicker>
						<p-inputMask #colorInputText mask="#******" [(ngModel)]="app.reversingColor"
							(onComplete)="changeReversingColor()"
							(onBlur)="app.reversingColor = getFromStorage('reversingColor')"
							characterPattern="[A-Fa-f]+$"></p-inputMask>
						<h6 translate>SETTINGS.RAS_AUFZEICHNUNG.DEFAULT_FILTER</h6>
						<p-dropdown [options]="rasAufzeichnungDefaultFilter" [(ngModel)]="selectedRasAufzeichnungDefaultFilter"
							(ngModelChange)="switchRasAufzeichnungDefaultFilter()" placeholder="Aufzeichnungen Defaultansicht wählen" [showClear]="true"
							dropdownDirective>
						</p-dropdown>
						<h6 translate>SETTINGS.RAS_AUFZEICHNUNG.DEFAULT_FILTER_GEO_OPTIONS</h6>
						<p-dropdown [options]="rasAufzeichnungDefaultFilterGeoOptions" [(ngModel)]="selectedRasAufzeichnungDefaultFilterGeoOptions"
							(ngModelChange)="switchRasAufzeichnungDefaultFilterGeoOptions()" placeholder="Aufzeichnungen Default Geoadressoption wählen" [showClear]="true"
							dropdownDirective>
						</p-dropdown>
						<h5 class="p-mb-4" translate>SETTINGS.RAS_AKTE.HEADER_AKTE</h5>
						<h6 translate>SETTINGS.RAS_AKTE.DEFAULT_FILTER</h6>
						<p-dropdown [options]="rasAkteDefaultFilter" [(ngModel)]="selectedRasAkteDefaultFilter"
							(ngModelChange)="switchRasAkteDefaultFilter()" placeholder="Akten Defaultansicht wählen" [showClear]="true"
							dropdownDirective>
						</p-dropdown>
						<h6 translate>SETTINGS.RAS_AKTE.FILTER_BEWERTUNG_VON</h6>
						<div class="p-formgroup-inline">
							<div class="p-m-1">
								<p-dropdown [options]="ratings" [(ngModel)]="selectedRasAkteDefaultFilterBewertungVon"
									(ngModelChange)="switchRasAkteRatingFrom()"
									[showClear]="true" dropdownDirective>
									<ng-template let-option pTemplate="selectedItem">
										<!--<i [ngClass]="option.value == null || option.value == undefined ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>-->
										<i [ngClass]="option.value === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>
									</ng-template>
									<ng-template let-option pTemplate="item">
										<!--<i [ngClass]="option.value == null || option.value == undefined ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>-->
										<i [ngClass]="option.value === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>
									</ng-template>
								</p-dropdown>
							</div>
						</div>
						<h6 translate>SETTINGS.RAS_AKTE.FILTER_BEWERTUNG_BIS</h6>
						<div class="p-formgroup-inline">
							<div class="p-m-1">
								<p-dropdown [options]="ratings" [(ngModel)]="selectedRasAkteDefaultFilterBewertungBis"
									(ngModelChange)="switchRasAkteRatingTo()"
									[showClear]="true" dropdownDirective>
									<ng-template let-option pTemplate="selectedItem">
										<!--<i [ngClass]="option.value == null || option.value == undefined ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>-->
										<i [ngClass]="option.value === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>
									</ng-template>
									<ng-template let-option pTemplate="item">
										<!--<i [ngClass]="option.value == null || option.value == undefined ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>-->
										<i [ngClass]="option.value === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
										   [style.color]="option.icon"></i>
									</ng-template>
								</p-dropdown>
							</div>
						</div>

						<h4 class="p-my-4" translate>SETTINGS.BHV</h4>
						<h6 translate>SETTINGS.SHOW_POSTAL_IN_OBJECT_DROPDOWN</h6>
						<p-checkbox [(ngModel)]="showPostalInObjectDropdown" [binary]="true"
							(onChange)="changeShowPostalInObjectDropdown()">
						</p-checkbox>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading !== 0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.SAVE'|translate}}" class="p-button-success p-mr-2"
						(click)="save()"></button>
				</ng-template>
				<ng-template pTemplate="right">
				</ng-template>
			</p-toolbar>

			<!-- Content -->
			<div *ngIf="track">
				<div *ngFor="let fields of fieldgroups" class="p-my-5">
					<div class="p-grid p-formgrid">
						<div *ngFor="let field of fields" class="p-col-12 p-md-6 p-lg-3 p-my-2">
							<h6 class="p-my-2">{{field.label}}</h6>
							<div *ngIf="field.type === 'string'">
								<input type="text" pInputText [(ngModel)]="track[field.key]">
							</div>
							<div *ngIf="field.type === 'number'">
								<p-inputNumber [(ngModel)]="track[field.key]" mode="decimal" locale="de-DE"
											   [style]="{'width':'100%'}" [showButtons]="true" [min]="0">
								</p-inputNumber>
							</div>
							<div *ngIf="field.type === 'date'">
								<p-calendar [showIcon]="true" [showTime]="true" [showSeconds]="true" hourFormat="24"
											dateFormat="dd.mm.yy" keepInvalid="true" appendTo="body"
											(onInput)="inputDate(field.key, $event.target.value)" [ngModel]="track[field.key]"
											(onSelect)="track[field.key]=$event"></p-calendar>
							</div>
							<div *ngIf="field.type === 'boolean'">
								<p-radioButton [value]="true" [(ngModel)]="track[field.key]"></p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.YES'|translate}}
								</span>
								<p-radioButton [value]="false" [(ngModel)]="track[field.key]"></p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.NO'|translate}}
								</span>
							</div>
							<div *ngIf="field.type === 'dropdown'" [style.width]="'90%'" [style.max-width]="'400px'">
								<p-dropdown [options]="options[field.key]" [(ngModel)]="track[field.key]"
											[style]="{'width':'100%'}" placeholder="-" [showClear]="true" [virtualScroll]="true"
											itemSize="30" appendTo="body" [filter]="true" dropdownDirective></p-dropdown>
							</div>
							<div *ngIf="field.type === 'multiselect'" [style.width]="'90%'" [style.max-width]="'400px'">
								<p-multiSelect [options]="options[field.key]" [(ngModel)]="track[field.key]"
											   placeholder="-" [showClear]="true" [virtualScroll]="true" itemSize="30"
											   appendTo="body" [filter]="true" [style]="{width: '100%'}" multiSelectDirective>
								</p-multiSelect>
							</div>
							<!--<div *ngIf="field.type === 'rating'" class="p-formgroup-inline">
								<div class="p-m-1">
									<p-dropdown [options]="options[field.key]" [(ngModel)]="track[field.key]"
												[showClear]="true" dropdownDirective>
										<ng-template let-option pTemplate="selectedItem">
											<i [ngClass]="option.value === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
											   [style.color]="option.icon"></i>
										</ng-template>
										<ng-template let-option pTemplate="item">
											<i [ngClass]="option.value === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
											   [style.color]="option.icon"></i>
										</ng-template>
									</p-dropdown>
								</div>
								<div class="p-my-1 p-mx-2">
									Errechnete<br>
									Bewertung
								</div>
								<div class="p-my-3">
									<i [ngClass]="rating === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
									   [style.color]="ratings[rating]"></i>
								</div>
							</div>-->
							<div *ngIf="field.type === 'plz'">
								<p-inputMask [(ngModel)]="track[field.key]" mask="99999" slotChar=""
											 (onComplete)="getPlacesFromPostalCode(field.key)">
								</p-inputMask>
							</div>
							<div *ngIf="field.type === 'village'" class="p-inputgroup" [style.width]="'90%'"
								 [style.max-width]="'400px'">
								<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
											[options]="placeNames[field.plz_field]" placeholder="-" [showClear]="true"
											[virtualScroll]="true" itemSize="30" [filter]="true" editable="true"
											[(ngModel)]="track[field.key]" appendTo="body" dropdownDirective>
								</p-dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-grid p-formgrid">
				<div *ngIf="track && track.video_url" class="p-m-2 p-p-0">
					<video id="edit_track_video" (timeupdate)="setVehiclePosition($event.target.currentTime)"
						(loadedmetadata)="initGPX($event)" controls [src]="videoSafeUrl">
					</video>
				</div>
				<div id="mapRasEdit" class="p-m-2 p-p-0" style="width: 720px; height: 575px;"></div>
			</div>
		</div>
	</div>
</div>
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Base } from '@app/models/base';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './list_gebietszuweisung.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblRueckfahrkatasterAufzeichnungGebietszuweisungListComponent extends ListComponent {
	resultData: any = {};
	commitButtonLabel: string = "Ausgewähltes Gebiet zuweisen und speichern";
	commitButtonVisible: boolean = true;
	isValidAreaSelected: boolean = false;
	selectedAreaData: Base = null;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public dialogService: DialogService,
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblRueckfahrkatasterAufzeichnung';
		this.name = 'MENU.AUFZEICHNUNG';
		this.url = '/' + PATH.RK_AUFZEICHNUNG;
		this.module = Module.ReversingCadastral;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'Id', required: true, width: 400 },
			{ type: 'text', key: 'Bezeichnung', required: true, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'Bezeichnung', required: true, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);

		this.hasFilterDialog = false; // natürlich nicht; aber get_entries überschreiben, bekommt die Daten ja vorher übergeben

		//let fieldKey: string = 'manualData' + (i+1).toString();
		//if( this.config.data[fieldKey] ) {
		//	this.manualData[i] = this.config.data[fieldKey];
		//}

		this.stateName = 'state' + this.apiUrl + 'ListGebietszuweisung';
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.unselectAll();
	}

	unselectAll(): void {
		this.isValidAreaSelected = false;
		this.selectedAreaData = null;
		this.commitButtonLabel = 'Gebiet für die Zuweisung auswählen...';
	}

	handleRowSelection(event) {
		// und das hier ist die gerade neu selektierte zeile aus dem multiselect
		this.unselectAll();
		if( event && event.data )
			this.selectedAreaData = event.data;
		if( this.selectedAreaData && this.selectedAreaData.ds_this_id && this.selectedAreaData.ds_this_id > 0 ) {
			this.isValidAreaSelected = true;
			if(this.selectedAreaData['Bezeichnung'] && this.selectedAreaData['Bezeichnung'].length > 0) {
				if( this.selectedAreaData['Id'] && this.selectedAreaData['Id'].length > 0 )
					this.commitButtonLabel = 'An Gebiet \"' + this.selectedAreaData['Bezeichnung'] + ' (' + this.selectedAreaData['Id'] + ')\" zuweisen';
				else this.commitButtonLabel = 'An Gebiet \"' + this.selectedAreaData['Bezeichnung'] + '\" zuweisen';
			} else {
				this.commitButtonLabel = 'An ausgewählte Akte zuweisen';
			}
		}
		else this.unselectAll();
	}

	handleRowUnselect(event) {
		this.unselectAll();
	}

	getAllEntries(): void {
		this.unselectAll();
		this.entries = [];
		if( this.config.data['candidateAreas'] != null && this.config.data['candidateAreas'] != undefined ) {
			this.processEntries(this.config.data['candidateAreas']);
		}
		this.unselectAll();
	}

	processEntries(entries: any): void {
		this.loading += 1;
		// gibt kein 'date' hier...
		//this.possibleCols.forEach(c => {
		//	if (c.type == 'date') {
		//		entries.forEach(e => {
		//			if (e[c.key] != null) {
		//				e[c.key] = new Date(e[c.key]);
		//			}
		//		});
		//	}
		//});
		this.entries = entries;
		this.count = this.entries.length;
		this.unselectAll();
		this.loading -= 1;

		// test
		//this.ref.close(this.resultData);
	}

	commitGebietszuweisung() {
		if( this.isValidAreaSelected ) {
			this.resultData['selectedEntry'] = this.selectedAreaData;
			this.ref.close(this.resultData);
		}
	}

	/**
	 * OVERRIDE - Passt die Tabellenhöhe und -breite an den verfügbaren PLatz an, um den Bildschrim auszufüllen
	 */
	initTable(): void {
		setTimeout(() => {
			this.contentHeight = 100;
		}, 0);
		setTimeout(() => {
			this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 200 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
			//console.log('special table contentHeight:');
			//console.log(this.contentHeight);
			if (this.contentHeight < 100) {
				this.contentHeight = 100;
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		this.changeDetectorRef.detectChanges();
	}
}

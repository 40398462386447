<div class="p-grid">
	<div class="p-col-12 p-p-0">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading !== 0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card p-mb-0">
			<!-- Content -->
			<div class="p-grid p-formgrid">
				<div class="p-col-12 p-md-12 p-lg-4">
					<p-table #dt styleClass="p-datatable-sm" [value]="area" [columns]="cols" selectionMode="single"
						[(selection)]="selectedRow" (onRowSelect)="OnRowSelect($event)" [scrollable]="true"
						scrollHeight="65vh" reorderableColumns="true" resizableColumns="true"
						(sortFunction)="onSort($event)" [customSort]="true" [globalFilterFields]="filters"
						filterDelay="0" [rowHover]="true" dataKey="PunktId">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<button pButton pRipple label="{{'BUTTONS.SAVE'|translate}}"
										class="p-button-success p-m-2" (click)="save()"></button>
								</div>
							</div>
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<h6 class="p-my-2">
										{{ 'HEADERS.bezeichnung' | translate }}
									</h6>
									<input type="text" pInputText placeholder="Name" (input)="newEntryBezeichnung=$event.target.value">
									<span class="p-input-icon-left">
									</span>
								</div>
							</div>
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<label for="flagCheck">
										{{ 'HEADERS.GEBIET.FLAG_GebietIsAusschlussgebiet' | translate }} <input id="flagCheck" type="checkbox" [(ngModel)]="newEntryFlagIsAusschlussgebiet">
									</label>
									<span class="p-input-icon-left">
									</span>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th *ngFor="let col of columns" pResizableColumn pReorderableColumn
									[pSortableColumn]="col.field" class="p-pl-3">
									{{ 'HEADERS.' + col.header | translate}}
								</th>
								<th></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-point let-columns="columns">
							<tr [pSelectableRow]="point">
								<td *ngFor="let col of columns" class="p-pl-3">
									<div *ngIf="!point[col.field]">
										<div *ngIf="point[col.field]!==false">
											<i class="pi pi-minus"></i>
										</div>
										<div *ngIf="point[col.field]===false">
											<i class="pi pi-times" style="color: red"></i>
										</div>
									</div>
									<div *ngIf="point[col.field]&&!col.subfield">
										<div *ngIf="point[col.field]!==true">
											{{point[col.field]}}
										</div>
										<div *ngIf="point[col.field]===true">
											<i class="pi pi-check" style="color: green;font-weight: bold;"></i>
										</div>
									</div>
									<div *ngIf="point[col.field]&&col.subfield&&!point[col.field][col.subfield]">
										<div *ngIf="point[col.field]!==false">
											<i class="pi pi-minus"></i>
										</div>
										<div *ngIf="point[col.field]===false">
											<i class="pi pi-times" style="color: red"></i>
										</div>
									</div>
									<div *ngIf="point[col.field]&&col.subfield&&point[col.field][col.subfield]">
										<div *ngIf="point[col.field][col.subfield]!==true">
											{{point[col.field][col.subfield]}}
										</div>
										<div *ngIf="point[col.field][col.subfield]===true">
											<i class="pi pi-check" style="color: green;font-weight: bold;"></i>
										</div>
									</div>
								</td>
								<td>
									<button pButton pRipple icon="pi pi-trash"
										class="p-button-rounded p-button-danger p-mr-2" (click)="deletePoint(point)"
										pTooltip="{{'BUTTONS.DELETE'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
									<button pButton pRipple icon="pi pi-map-marker"
										class="p-button-rounded p-button p-mr-2" (click)="centerOn([point.X, point.Y])"
										pTooltip="{{'BUTTONS.CENTER'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="summary">
							<div class="p-d-flex p-ai-center p-jc-between p-pl-2">
								{{ 'AREAS.POINT_COUNT' | translate : { count : (area ? pointCount : 0) } }}
							</div>
						</ng-template>
					</p-table>
				</div>

				<div class="p-col-12 p-md-12 p-lg-8">
					<!-- Map -->
					<div id="mapGebietCreate" style="height: 80vh; min-width: 400px;"></div>
				</div>
			</div>
		</div>

		<!-- Confirm -->
		<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
	</div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import PATH from '@assets/routes/routes.json';
import { environment } from '@environments/environment';
import { Settings } from '@models/settings';
import { AccountService } from '@services/account.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// ras aufzeichnung filter settings zeugs
export enum RasAufzeichnungDefaultFilter {
	KeineDaten = 0,
	AlleDaten = 1,
	OhneAkte = 2,
	MitAkte = 3,
	FilterAnzeigen = 4
}

export enum RasAkteDefaultFilter {
	KeineDaten = 0,
	AlleDaten = 1,
	AlleInKataster = 2,
	AlleNichtInKataster = 3,
	FilterAnzeigen = 4
}

export enum RasAufzeichnungDefaultFilterGeoOptions {
	Alle = 0,
	MitGeo = 1,
	OhneGeo = 2
}

@Injectable()
export class SettingsService {
	isFooterVisible: boolean;
	footerVisibilityChange: Subject<boolean> = new Subject<boolean>();

	// landing page indizes und object data
	private readonly indexLandingPage_Label: number = 0;
	private readonly indexLandingPage_Alias: number = 1;
	private readonly indexLandingPage_Route: number = 2;
	private LandingPage_Home: string[];// = ['Home', 'lpHome', PATH.HOME]; // private readonly LandingPage_Home: string[] = ['Home', 'lpHome', 'home'];
	private LandingPage_OnlineFahrzeuge: string[];// = ['Online - Fahrzeuge', 'lpOnlineFahrzeuge', PATH.ONLINE_FAHRZEUGE]; // private readonly LandingPage_OnlineFahrzeuge: string[] = ['Online - Fahrzeuge', 'lpOnlineFahrzeuge', 'online/karte'];
	private LandingPage_OnlineLeerungen: string[];// = ['Online - Leerungen', 'lpOnlineLeerungen', PATH.ONLINE_LEERUNGEN]; // private readonly LandingPage_OnlineLeerungen: string[] = ['Online - Leerungen', 'lpOnlineLeerungen', 'online/leerung'];
	private LandingPage_StammdatenFahrzeuge: string[];// = ['Stammdaten - Fahrzeuge', 'lpStammdatenFahrzeuge', PATH.FAHRZEUG + '/' + PATH.LIST]; // private readonly LandingPage_StammdatenFahrzeuge: string[] = ['Stammdaten - Fahrzeuge', 'lpStammdatenFahrzeuge', 'fahrzeug/list'];
	private LandingPage_RueckfahrkatasterListe: string[];// = ['Rückfahrkataster', 'lpRasListe', PATH.RK_STRECKE + '/' + PATH.LIST]; // private readonly LandingPage_RueckfahrkatasterListe: string[] = ['Rückfahrkataster', 'lpRasListe', 'rueckfahrkataster_aufzeichnung/list'];
	private LandingPage_BerichteLeerungen: string[];// = ['Berichte - Leerungen', 'lpBerichteLeerungen', PATH.LEERUNG + '/' + PATH.LIST]; // private readonly LandingPage_BerichteLeerungen: string[] = ['Berichte - Leerungen', 'lpBerichteLeerungen', 'leerung/list'];
	private LandingPage_BerichteFahrtbericht: string[];// = ['Berichte - Fahrtberichte', 'lpBerichteFahrtberichte', PATH.FAHRTEBRICHT + '/' + PATH.LIST]; // private readonly LandingPage_BerichteFahrtbericht: string[] = ['Berichte - Fahrtberichte', 'lpBerichteFahrtberichte', 'fahrtbericht/list'];

	private RasAufzeichnungDefaultFilter_KeineDaten: any;// = {label: 'alle Daten', enumValue: this.enumRasAufzeichnungDefaultFilter_KeineDaten};
	private RasAufzeichnungDefaultFilter_AlleDaten: any;// = {label: 'alle Daten', enumValue: this.enumRasAufzeichnungDefaultFilter_AlleDaten};
	private RasAufzeichnungDefaultFilter_OhneAkte: any;// = {label: 'alle ohne Akte', enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte};
	private RasAufzeichnungDefaultFilter_MitAkte: any;// = {label: 'alle mit Akte', enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenMitAkte};
	private RasAufzeichnungDefaultFilter_FilterAnzeigen: any;

	private RasAkteDefaultFilter_KeineDaten: any;// = {label: 'alle Daten', enumValue: this.enumRasAufzeichnungDefaultFilter_KeineDaten};
	private RasAkteDefaultFilter_AlleDaten: any;// = {label: 'alle Daten', enumValue: this.enumRasAufzeichnungDefaultFilter_AlleDaten};
	private RasAkteDefaultFilter_AlleInKataster: any;// = {label: 'alle ohne Akte', enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte};
	private RasAkteDefaultFilter_AlleNichtInKataster: any;// = {label: 'alle mit Akte', enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenMitAkte};
	private RasAkteDefaultFilter_FilterAnzeigen: any;

	private RasAufzeichnungDefaultFilterGeoOptions_Alle: any;
	private RasAufzeichnungDefaultFilterGeoOptions_MitGeo: any;
	private RasAufzeichnungDefaultFilterGeoOptions_OhneGeo: any;


	constructor(
		private accountService: AccountService,
		private http: HttpClient,
		public translate: TranslateService
	) {
		this.footerVisibilityChange.subscribe(value => {
			this.isFooterVisible = value;
		});
	}

	/**
	 * Einstellung bearbeitung
	 * 
	 * @param setting Einstellung die geändert werden soll
	 * @returns Antwort der API
	 */
	editSetting(setting): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/settings/edit`, setting, options).toPromise());
		});
	}

	/**
	 * Einstellungen abfragen
	 * 
	 * @returns Antwort der API
	 */
	getSettings(): Promise<Settings> {
		return new Promise<Settings>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/settings`, options).toPromise());
		});
	}

	/**
	 * Footer ein-/ausblenden
	 */
	toggleFooterVisibility(): void {
		this.footerVisibilityChange.next(!this.isFooterVisible);
	}

	getPageSelection(): any[] {
		this.LandingPage_Home = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_HOME'), 'lpHome', PATH.HOME];
		this.LandingPage_OnlineFahrzeuge = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_ONLINE_FAHRZEUGE'), 'lpOnlineFahrzeuge', PATH.ONLINE_FAHRZEUGE];
		this.LandingPage_OnlineLeerungen = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_ONLINE_LEERUNGEN'), 'lpOnlineLeerungen', PATH.ONLINE_LEERUNGEN];
		this.LandingPage_StammdatenFahrzeuge = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_STAMMDATEN_FAHRZEUGE'), 'lpStammdatenFahrzeuge', PATH.FAHRZEUG + '/' + PATH.LIST];
		this.LandingPage_RueckfahrkatasterListe = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_RAS'), 'lpRasListe', PATH.RK_STRECKE + '/' + PATH.LIST];
		this.LandingPage_BerichteLeerungen = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_BERICHTE_LEERUNGEN'), 'lpBerichteLeerungen', PATH.LEERUNG + '/' + PATH.LIST];
		this.LandingPage_BerichteFahrtbericht = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_BERICHTE_FAHRTBERICHTE'), 'lpBerichteFahrtberichte', PATH.FAHRTEBRICHT + '/' + PATH.LIST];
		return [
			{ label: this.LandingPage_Home[this.indexLandingPage_Label], value: this.LandingPage_Home[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Label], value: this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_OnlineLeerungen[this.indexLandingPage_Label], value: this.LandingPage_OnlineLeerungen[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Label], value: this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Label], value: this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_BerichteLeerungen[this.indexLandingPage_Label], value: this.LandingPage_BerichteLeerungen[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Label], value: this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Alias] }
		];
	}

	getRouteForLandingPageAlias(value: string): string {
		let resultRoute: string = this.LandingPage_Home[this.indexLandingPage_Route]; // failback/default

		if (value !== null && value !== undefined && value.trim().length > 0) {
			value = value.trim();

			switch (value) {
				case this.LandingPage_Home[this.indexLandingPage_Alias]:
					resultRoute = this.LandingPage_Home[this.indexLandingPage_Alias];
					break;
				case this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Route]:
					resultRoute = this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Route];
					break;
				case this.LandingPage_OnlineLeerungen[this.indexLandingPage_Alias]:
					resultRoute = this.LandingPage_OnlineLeerungen[this.indexLandingPage_Alias];
					break;
				case this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Alias]:
					resultRoute = this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Alias];
					break;
				case this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Alias]:
					resultRoute = this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Alias];
					break;
				case this.LandingPage_BerichteLeerungen[this.indexLandingPage_Alias]:
					resultRoute = this.LandingPage_BerichteLeerungen[this.indexLandingPage_Alias];
					break;
				case this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Alias]:
					resultRoute = this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Alias];
					break;
			} // ... to be continued
		}

		return resultRoute;
	}

	getRasAufzeichnungDefaultFilterSelection(): any[] {
		this.RasAufzeichnungDefaultFilter_KeineDaten = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_KEINE_DATEN'), enumValue: RasAufzeichnungDefaultFilter.KeineDaten};
		this.RasAufzeichnungDefaultFilter_AlleDaten = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_ALLE_DATEN'), enumValue: RasAufzeichnungDefaultFilter.AlleDaten};
		this.RasAufzeichnungDefaultFilter_OhneAkte = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_OHNE_AKTE'), enumValue: RasAufzeichnungDefaultFilter.OhneAkte};
		this.RasAufzeichnungDefaultFilter_MitAkte = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_MIT_AKTE'), enumValue: RasAufzeichnungDefaultFilter.MitAkte};
		this.RasAufzeichnungDefaultFilter_FilterAnzeigen = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_ANZEIGEN'), enumValue: RasAufzeichnungDefaultFilter.FilterAnzeigen};
		return [
			{ label: this.RasAufzeichnungDefaultFilter_KeineDaten['label'], value: this.RasAufzeichnungDefaultFilter_KeineDaten['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_AlleDaten['label'], value: this.RasAufzeichnungDefaultFilter_AlleDaten['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_OhneAkte['label'], value: this.RasAufzeichnungDefaultFilter_OhneAkte['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_MitAkte['label'], value: this.RasAufzeichnungDefaultFilter_MitAkte['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_FilterAnzeigen['label'], value: this.RasAufzeichnungDefaultFilter_FilterAnzeigen['enumValue'] }
		];
	}

	getRasAkteDefaultFilterSelection(): any[] {
		this.RasAkteDefaultFilter_KeineDaten = {label: this.translate.instant('SETTINGS.RAS_AKTE.FILTER_KEINE_DATEN'), enumValue: RasAkteDefaultFilter.KeineDaten};
		this.RasAkteDefaultFilter_AlleDaten = {label: this.translate.instant('SETTINGS.RAS_AKTE.FILTER_ALLE_DATEN'), enumValue: RasAkteDefaultFilter.AlleDaten};
		this.RasAkteDefaultFilter_AlleInKataster = {label: this.translate.instant('SETTINGS.RAS_AKTE.FILTER_ALLE_IN_KATASTER'), enumValue: RasAkteDefaultFilter.AlleInKataster};
		this.RasAkteDefaultFilter_AlleNichtInKataster = {label: this.translate.instant('SETTINGS.RAS_AKTE.FILTER_ALLE_NICHT_IN_KATASTER'), enumValue: RasAkteDefaultFilter.AlleNichtInKataster};
		this.RasAkteDefaultFilter_FilterAnzeigen = {label: this.translate.instant('SETTINGS.RAS_AKTE.FILTER_ANZEIGEN'), enumValue: RasAkteDefaultFilter.FilterAnzeigen};
		return [
			{ label: this.RasAkteDefaultFilter_KeineDaten['label'], value: this.RasAkteDefaultFilter_KeineDaten['enumValue'] },
			{ label: this.RasAkteDefaultFilter_AlleDaten['label'], value: this.RasAkteDefaultFilter_AlleDaten['enumValue'] },
			{ label: this.RasAkteDefaultFilter_AlleInKataster['label'], value: this.RasAkteDefaultFilter_AlleInKataster['enumValue'] },
			{ label: this.RasAkteDefaultFilter_AlleNichtInKataster['label'], value: this.RasAkteDefaultFilter_AlleNichtInKataster['enumValue'] },
			{ label: this.RasAkteDefaultFilter_FilterAnzeigen['label'], value: this.RasAkteDefaultFilter_FilterAnzeigen['enumValue'] }
		];
	}

	getRasAufzeichnungDefaultFilterSelectionGeoOptions(): any[] {
		this.RasAufzeichnungDefaultFilterGeoOptions_Alle = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_GEOADRESSE_ALLE'), enumValue: RasAufzeichnungDefaultFilterGeoOptions.Alle};
		this.RasAufzeichnungDefaultFilterGeoOptions_MitGeo = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_GEOADRESSE_NUR_MIT'), enumValue: RasAufzeichnungDefaultFilterGeoOptions.MitGeo};
		this.RasAufzeichnungDefaultFilterGeoOptions_OhneGeo = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_GEOADRESSE_NUR_OHNE'), enumValue: RasAufzeichnungDefaultFilterGeoOptions.OhneGeo};
		
		return [
			{ label: this.RasAufzeichnungDefaultFilterGeoOptions_Alle['label'], value: this.RasAufzeichnungDefaultFilterGeoOptions_Alle['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilterGeoOptions_MitGeo['label'], value: this.RasAufzeichnungDefaultFilterGeoOptions_MitGeo['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilterGeoOptions_OhneGeo['label'], value: this.RasAufzeichnungDefaultFilterGeoOptions_OhneGeo['enumValue'] },
		];
	}

	safeToIntEx(val: any, defaultval: number): number {
		let result: number = defaultval;
		if(val) {
			try {
				result = this.safeToInt(val.toString(), defaultval);
			} catch {
				result = defaultval;
			}
		}
		return result;
	}

	safeToInt(val: string, defaultval: number): number {
		let result: number = defaultval;
		try {
			result = parseInt(val);
		} catch {
			result = defaultval;
		}
		return result;
	}

	safeToIntOrNull(val: string): number {
		let result: number = null;
		try {
			result = parseInt(val);
		} catch {
			result = null;
		}
		return result;
	}

	getCurrentRasRecordsDefaultView(): any {
		let nDefaultView: number = this.safeToInt(localStorage.getItem('rasAufzeichnungDefaultFilter'), RasAufzeichnungDefaultFilter.KeineDaten);
		let nDefaultFileView: number = this.safeToInt(localStorage.getItem('rasAkteDefaultFilter'), RasAkteDefaultFilter.KeineDaten);
		let nDefaultGeoOption: number = this.safeToInt(localStorage.getItem('rasAufzeichnungDefaultFilterGeoOptions'), RasAufzeichnungDefaultFilterGeoOptions.Alle);
		return {
			optionDefaultView: nDefaultView,
			optionDefaultFileView: nDefaultFileView,
			optionDefaultGeoOption: nDefaultGeoOption
		};
	}

	getCurrentFileRatingFrom(): number {
		return this.getCurrentFileRatingFromToEx('rasAkteDefaultBewertungVon');
	}

	getCurrentFileRatingTo(): number {
		return this.getCurrentFileRatingFromToEx('rasAkteDefaultBewertungBis');
	}

	getCurrentFileRatingFromToEx(identifier: string): number {
		let nValue: number = null;
		let val = localStorage.getItem(identifier);
		if(val != null && val != undefined)
			nValue = this.safeToIntOrNull(val);
		return nValue;
	}
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { AreaPoint } from '@models/area';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { LineString, Point, Polygon } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblGebietDetailComponent implements OnInit, AfterViewInit {
	@ViewChild('dt', { read: '', static: true }) dt: Table;
	public area: AreaPoint[];
	private borderLayer: VectorLayer;
	private borderStyle: Style;
	public cols: any[];
	private coord = [8.0787, 50.8233];
	public filters = [];
	id: number;
	public loading = 0;
	private mapGebietDetail: Map;
	private mapLayer: TileLayer;
	private markerLayer: VectorLayer;
	private markerStyle: Style;
	public pointCount: number;
	private polygonLayer: VectorLayer;
	private polygonStyle: Style;
	private selectedMarkerStyle: Style;
	public selectedRow: AreaPoint;
	url: string = '';
	public detailFlagIsAusschlussgebiet: boolean = false;

	constructor(
		private breadcrumbService: BreadcrumbService,
		private confirmationService: ConfirmationService,
		private crudService: CRUDService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];
		this.url = '/' + PATH.GEBIET;

		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: 'MENU.GEBIET', routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}

	ngAfterViewInit() {
		this.initMap();
		(document.querySelector('.p-datatable-scrollable-body') as HTMLElement).style.maxHeight = 'calc(100vh - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '32rem' : '28rem') + ')';
		(document.querySelector('#mapGebietDetail') as HTMLElement).style.maxHeight = 'calc(100vh - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '21rem' : '17rem') + ')';
	}

	ngOnInit() {
		this.loadArea();

		this.cols = [
			{ field: 'X', header: 'Longitude' },
			{ field: 'Y', header: 'Latitude' },
		];

		this.cols.forEach(col => {
			if ('subfield' in col) {
				this.filters.push(col.field + '.' + col.subfield);
			} else {
				this.filters.push(col.field);
			}
		});
	}

	centerOfPoints(points: AreaPoint[]) {
		let centerX = 0;
		let centerY = 0;

		points.forEach(p => {
			centerX += p.X;
			centerY += p.Y;
		});

		centerX /= points.length;
		centerY /= points.length;

		return [centerX, centerY];
	}

	centerOn(point: number[]) {
		this.mapGebietDetail.getView().centerOn(point, this.mapGebietDetail.getSize(), [this.mapGebietDetail.getSize()[0] / 2, this.mapGebietDetail.getSize()[1] / 2]);
	}

	delete() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteArea(this.id).then(res => {
					this.router.navigate(['/gebiet']);
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	edit() {
		this.router.navigate(['/gebiet/edit/' + this.id]);
	}

	initMap() {
		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			})
		});

		this.selectedMarkerStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({
					color: '#55ff55'
				}),
			})
		});
		this.markerStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({
					color: '#ff5555'
				}),
			})
		});
		this.markerLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: this.markerStyle,
			name: 'Marker'
		});

		this.borderStyle = new Style({
			stroke: new Stroke({
				color: '#3333ff',
				width: 4
			})
		});
		this.borderLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.borderStyle,
			name: 'Border'
		});

		this.polygonStyle = new Style({
			fill: new Fill({
				color: '#ccccff'
			})
		});
		this.polygonLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.polygonStyle,
			name: 'Polygon'
		});

		useGeographic();
		this.mapGebietDetail = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'mapGebietDetail',
			view: new View({
				center: this.coord,
				zoom: 14,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.polygonLayer,
				this.borderLayer,
				this.markerLayer
			]
		});
		[this.mapLayer, this.markerLayer, this.borderLayer, this.polygonLayer].forEach(layer => {
			layer.getSource().on('tileloadstart', () => {
				this.loading += 1;
			});
			layer.getSource().on('tileloadend', () => {
				this.loading -= 1;
			});
			layer.getSource().on('tileloaderror', () => {
				this.loading -= 1;
			});
		});

		this.mapGebietDetail.on('click', (event) => {
			this.mapGebietDetail.forEachFeatureAtPixel(
				event.pixel,
				(feature) => {
					this.area.forEach(point => {
						if (this.area.indexOf(point) === feature.get('name')) {
							this.selectPoint(point);
						}
					});
				},
				{
					hitTolerance: 5,
					layerFilter: (layer) => {
						return layer === this.markerLayer;
					}
				}
			);
		});
	}

	loadArea() {
		this.loading += 1;
		this.crudService.getArea(this.id).then(res => {
			this.area = res;
			this.pointCount = this.area.length;
			let bCenterOn = true;
			if( 1 == this.area.length ) {
				if( this.area[0].PunktId == null || this.area[0].PunktId == undefined ) {
					this.pointCount = 0;
					bCenterOn = false;
				}
			}
			if(this.area != null && this.area != undefined && this.area.length > 0) {
				if(this.area[0].GebietFlags != null && this.area[0].GebietFlags != undefined && this.area[0].GebietFlags > 0) {
					if( (this.area[0].GebietFlags & (1<<0)) !== 0) {
						this.detailFlagIsAusschlussgebiet = true;
					}
				}
			}
			this.showBorders();
			this.showPolygon();
			this.showMarkers();
			if( bCenterOn ) {
				this.centerOn(this.centerOfPoints(this.area));
			}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	onFilter(event) {
		this.loading += 1;
		this.dt.filterGlobal(event.target.value, 'contains');
		setTimeout(() => {
			if (this.dt.filteredValue) {
				this.pointCount = this.dt.filteredValue.length;
			} else {
				this.pointCount = this.area.length;
			}
			this.loading -= 1;
		}, 500);
	}

	OnRowSelect(event) {
		this.selectPoint(event.data);
	}

	onSort(event: SortEvent) {
		let subfield: string;
		this.cols.forEach(col => {
			if (col.field === event.field) {
				if ('subfield' in col) {
					subfield = col.subfield;
				}
			}
		});

		event.data.sort((data1, data2) => {
			let value1 = data1[event.field];
			let value2 = data2[event.field];
			let result = null;

			if (data1[event.field] && subfield) {
				value1 = data1[event.field][subfield];
			}
			if (data2[event.field] && subfield) {
				value2 = data2[event.field][subfield];
			}

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1 === 'string' && typeof value2 === 'string')
				result = value1.localeCompare(value2);
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
	}

	selectPoint(point) {
		this.selectedRow = point;

		this.markerLayer.getSource().getFeatures().forEach(feature => {
			if (feature.get('name') === this.area.indexOf(point)) {
				feature.setStyle(this.selectedMarkerStyle);
			} else {
				feature.setStyle(this.markerStyle);
			}
		});
	}

	hasValue(val): boolean {
		if( val !== null && val !== undefined ) {
			return true;
		}
		return false;
	}

	showBorders() {
		let oldMarker = this.area[this.area.length - 1];
		this.area.forEach(newMarker => {
			if( this.hasValue(oldMarker.PunktId) && this.hasValue(oldMarker.X) && this.hasValue(oldMarker.Y) ) {
				if( this.hasValue(newMarker.PunktId) && this.hasValue(newMarker.X) && this.hasValue(newMarker.Y) ) {
					const feature = new Feature({
						geometry: new LineString(
							[
								[oldMarker.X, oldMarker.Y],
								[newMarker.X, newMarker.Y]
							]
						),
						name: this.area.indexOf(newMarker)
					});
					this.borderLayer.getSource().addFeature(feature);
				}
			}
			oldMarker = newMarker;
		});
	}

	showMarkers() {
		this.area.forEach(point => {
			if( this.hasValue(point.PunktId) && this.hasValue(point.X) && this.hasValue(point.Y)) {
				const feature = new Feature({
					geometry: new Point([point.X, point.Y]),
					name: this.area.indexOf(point)
				});
				this.markerLayer.getSource().addFeature(feature);
			}
		});
	}

	showPolygon() {
		const points = [];
		if( this.hasValue(this.area[this.area.length - 1].PunktId) && this.hasValue(this.area[this.area.length - 1].X) && this.hasValue(this.area[this.area.length - 1].Y)) {
			points.push([this.area[this.area.length - 1].X, this.area[this.area.length - 1].Y]);
		}
		this.area.forEach(nextPoint => {
			if( this.hasValue(nextPoint.PunktId) && this.hasValue(nextPoint.X) && this.hasValue(nextPoint.Y)) {
				points.push([nextPoint.X, nextPoint.Y]);
			}
		});
		if( points.length > 0 ) {
			const feature = new Feature(new Polygon([points]));
			feature.setId(this.id);
			this.polygonLayer.getSource().addFeature(feature);
		}
	}
}

import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { Area } from '@models/area';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { LineString, Polygon } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblGebietListComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	area: Area;
	areaCount: number;
	areaDialog: boolean;
	private borderLayer: VectorLayer;
	private borderStyle: Style;
	private coord = [8.0787, 50.8233];
	private mapGebietList: Map;
	private mapLayer: TileLayer;
	private markerLayer: VectorLayer;
	private markerStyle: Style;
	private polygonLayer: VectorLayer;
	private polygonStyle: Style;
	tooltip = '';
	apiUrl: string = '';
	buttonColWidth: number = 200;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	count: number = 0;
	createPermission: boolean = false;
	deletePermission: boolean = false;
	entries: Area[];
	filters: string[];
	globalFilter: string = '';
	isMobile: boolean = false;
	isTableInit: boolean = false;
	loading: number = 0;
	loadTimestamp: Date;
	module: Module;
	name: string = '';
	oldCols: any[] = [];
	persistenceCheckInterval: any;
	possibleCols: any[] = [];
	readPermission: boolean = false;
	selectedEntry: Area;
	state: any;
	stateName: string = '';
	updatePermission: boolean = false;
	url: string = '';
	url_detail: string = PATH.DETAIL;
	url_edit: string = PATH.EDIT;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
	) {
		this.apiUrl = 'TblGebiet';
		this.name = 'MENU.GEBIET';
		this.url = '/' + PATH.GEBIET;
		this.module = Module.Masterdata;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'Id', required: true, width: 400 },
			{ type: 'text', key: 'Ankey', required: true, width: 400 },
			{ type: 'text', key: 'Bezeichnung', required: true, width: 400 },
			{ type: 'numeric', key: 'Flags', required: false, width: 100 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'Bezeichnung', required: true, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}

	// #region Angular

	ngOnInit() {
		this.loading += 1;

		this.createPermission = this.accountService.checkPermissions(this.module, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(this.module, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(this.module, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(this.module, Operation.DELETE);

		if (this.readPermission) {
			this.getAllEntries();
		}

		this.stateName = 'state' + this.apiUrl + 'List';
		this.retrieveTableState(this.state);
		if (this.state) {
			this.cols = [];
			this.state['columnOrder'].forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
			if (this.state.filters.global) {
				this.globalFilter = this.state.filters.global.value;
			}
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.CENTER'), icon: 'pi pi-fw pi-map-marker', command: () => this.centerOn(this.centerOfPoints(this.selectedEntry.Eckpunkte)) },
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.loading -= 1;
	}

	ngAfterViewInit() {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initMap();
		this.initTable();
	}

	ngAfterViewChecked() {
		if (!this.isTableInit && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidth();
		}
	}

	ngOnDestroy() {
		clearInterval(this.persistenceCheckInterval);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.initTable();
	}

	// #endregion Angular

	// #region CRUD

	create() {
		this.router.navigate([this.url + '/' + PATH.CREATE]);
	}

	detail(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.Id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.Id);
		} else {
			this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.Id]);
		}
	}

	edit(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.Id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.Id);
		} else {
			this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.Id]);
		}
	}

	delete(ds_this_id?: number) {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, ds_this_id ? ds_this_id : this.selectedEntry.Id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.getAllEntries();
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	// #endregion CRUD

	// #region Table

	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	exportCSV() {
		this.exportService.exportCSV(this.translate.instant(this.name), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportPDF() {
		this.exportService.exportPDF(this.translate.instant(this.name), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportXLSX() {
		this.exportService.exportXLSX(this.translate.instant(this.name), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	getAllEntries() {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.crudService.getAllEntries(this.apiUrl).then(res => {
			this.loadTimestamp = new Date();
			if (!this.persistenceCheckInterval) {
				this.persistenceCheckInterval = setInterval(() => {
					this.crudService.checkPersistence(this.apiUrl, this.loadTimestamp).then(isPersistent => {
						if (!isPersistent) {
							this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
						}
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					});
				}, 1000 * 60 * 10);
			}
			this.entries = res as Area[];
			this.count = this.entries.length;
			this.possibleCols.forEach(c => {
				if (c.type == 'date') {
					this.entries.forEach(e => {
						if (e[c.key] != null) {
							e[c.key] = new Date(e[c.key]);
						}
					});
				}
			});
			this.resizeTableWidth();
			if (this.entries.length > 0) {
				this.centerOn(this.centerOfPoints(this.entries[0].Eckpunkte));
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
			this.redraw();
		});
	}

	initTable() {
		this.contentHeight = 400;
		setTimeout(() => {
			this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 200 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
			if (this.contentHeight < 400) {
				this.contentHeight = 400;
			}
		}, 0);
		this.resizeTableWidth();
		setTimeout(() => {
			this.mapGebietList.updateSize();
		}, 0);

	}

	isColFiltered(col) {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.state && this.state.filters[col.key]) {
			Object.keys(this.state.filters[col.key]).forEach(filter => {
				if (this.state.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event) {
		this.count = this.table.filteredValue ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event) {
		this.retrieveTableState(this.state);
		const columnWidths = this.state.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.state.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	onColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.retrieveTableState(this.state);
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));

		this.resizeTableWidth(this.state);
	}

	resetTable() {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(state?) {
		this.loading += 1;

		this.retrieveTableState(this.state);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.state ? this.state.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.state) {
					localStorage.setItem(this.stateName, JSON.stringify(this.state));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?) {
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.table && (this.state == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.state = JSON.parse(localStorage.getItem(this.stateName));
		}
	}

	toggleColumn(event) {
		this.retrieveTableState(this.state);
		this.state.columnOrder = event.value.map(c => c.key);
		this.state.columnWidths = event.value.map(c => c.width);
		this.state.columnWidths = this.state.columnWidths.join(',');
		this.state.columnWidths = this.state.columnWidths + ',' + this.buttonColWidth;
		this.state.tableWidth = (this.state.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.resizeTableWidth(this.state);
	}

	// #endregion Table

	// #region Map

	centerOfPoints(points) {
		let centerX = 0;
		let centerY = 0;

		points.forEach(p => {
			centerX += p[0];
			centerY += p[1];
		});

		centerX /= points.length;
		centerY /= points.length;

		return [centerX, centerY];
	}

	centerOn(point: number[]) {
		this.mapGebietList.getView().centerOn(point, this.mapGebietList.getSize(), [this.mapGebietList.getSize()[0] / 2, this.mapGebietList.getSize()[1] / 2]);
	}

	displayTooltip(event) {
		const pixel = event.pixel;
		this.mapGebietList.forEachFeatureAtPixel(pixel, feature => {
			document.getElementById('nameTooltip').style.display = 'inherit';
			document.getElementById('nameTooltip').style.top = (document.getElementById('mapGebietList').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 25 + event.pixel[1]) + 'px';
			document.getElementById('nameTooltip').style.left = (document.getElementById('mapGebietList').getBoundingClientRect().left - document.body.getBoundingClientRect().left + 5 + event.pixel[0]) + 'px';
		});
	}

	initMap() {
		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			})
		});

		this.markerStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({
					color: '#ff5555'
				}),
			})
		});
		this.markerLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: this.markerStyle,
			name: 'Marker'
		});

		this.borderStyle = new Style({
			stroke: new Stroke({
				color: '#3333ff',
				width: 4
			})
		});
		this.borderLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.borderStyle,
			name: 'Border'
		});

		this.polygonStyle = new Style({
			fill: new Fill({
				color: '#ccccff'
			})
		});
		this.polygonLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.polygonStyle,
			name: 'Polygon'
		});

		useGeographic();
		this.mapGebietList = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'mapGebietList',
			view: new View({
				center: this.coord,
				zoom: 14,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.polygonLayer,
				this.borderLayer,
				this.markerLayer
			]
		});
		[this.mapLayer, this.markerLayer, this.borderLayer, this.polygonLayer].forEach(layer => {
			layer.getSource().on('tileloadstart', () => {
				this.loading += 1;
			});
			layer.getSource().on('tileloadend', () => {
				this.loading -= 1;
			});
			layer.getSource().on('tileloaderror', () => {
				this.loading -= 1;
			});
		});

		this.mapGebietList.on('click', (event) => {
			this.mapGebietList.forEachFeatureAtPixel(
				event.pixel,
				(feature) => {
					this.entries.forEach(area => {
						if (area.Id === feature.getId()) {
							this.selectedEntry = area;
						}
					});
				},
				{
					hitTolerance: 10,
					layerFilter: (layer) => {
						return layer === this.polygonLayer;
					}
				}
			);
		});
		this.mapGebietList.on('pointermove', (event) => {
			document.getElementById('nameTooltip').style.display = 'none';
			this.mapGebietList.forEachFeatureAtPixel(
				event.pixel,
				(feature) => {
					this.entries.forEach(area => {
						if (area.Id === feature.getId()) {
							this.tooltip = area.Bezeichnung;
							this.displayTooltip(event);
						}
					});
				},
				{
					hitTolerance: 10,
					layerFilter: (layer) => {
						return layer === this.polygonLayer;
					}
				}
			);
		});
	}

	loadAreasToMap() {
		this.entries.forEach(area => {
			this.showBorders(area.Eckpunkte);
			this.showPolygon(area.Id, area.Eckpunkte);
		});
	}

	OnRowSelect(event) {
		this.entries.forEach(area => {
			if (area.Id === event.data.Id) {
				this.centerOn(this.centerOfPoints(area.Eckpunkte));
			}
		});
	}

	redraw() {
		this.borderLayer.getSource().clear();
		this.markerLayer.getSource().clear();
		this.polygonLayer.getSource().clear();
		if (this.entries) {
			this.entries.forEach(area => {
				this.showBorders(area.Eckpunkte);
				this.showPolygon(area.Id, area.Eckpunkte);
			});
		}
		this.areaCount = this.entries ? this.entries.length : 0;
	}

	showBorders(marker) {
		let oldMarker = marker[marker.length - 1];
		marker.forEach(m => {
			const feature = new Feature({
				geometry: new LineString(
					[
						oldMarker,
						m
					]
				),
				name: marker.indexOf(m)
			});
			this.borderLayer.getSource().addFeature(feature);
			oldMarker = m;
		});
	}

	showPolygon(id, marker) {
		marker.push(marker[0]);
		const feature = new Feature(new Polygon([marker]));
		feature.setId(id);
		this.polygonLayer.getSource().addFeature(feature);
		marker.pop();
	}

	// #endregion Map
}

import { Component, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { AccountService } from '@app/services/account.service';
import { AdminService } from '@app/services/admin.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
//import { TblRueckfahrkatasterGefahrCreateComponent } from '../../tbl_rueckfahrkataster_gefahr/create/create.component';
//import { TblRueckfahrkatasterGrundCreateComponent } from '../../tbl_rueckfahrkataster_grund/create/create.component';
//import { TblRueckfahrkatasterMassnahmeCreateComponent } from '../../tbl_rueckfahrkataster_massnahme/create/create.component';
//import { TblRueckfahrkatasterStatusCreateComponent } from '../../tbl_rueckfahrkataster_status/create/create.component';
import { TblGebietCreateComponent } from '@app/views/stammdaten/tbl_gebiet/create/create.component';
import { TblTourCreateComponent } from '@app/views/stammdaten/tbl_tour/create/create.component';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/create/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAkteCreateComponent extends CreateComponent {
	rk_rating_boundaries: any;
	rating: number = 0;
	ratings: any;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
		private adminService: AdminService,
	) {
		super(accountService, breadcrumbService, config, confirmationService, crudService, dialogService, globals, messageService, ref, router, translate);

		this.apiUrl = 'TblRueckfahrkatasterStrecke';
		this.name = 'MENU.RUECKFAHRKATASTER';
		this.url = '/' + PATH.RK_STRECKE;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'name', required: true, width: 400 },
				{ type: 'text', key: 'freitext', required: false, width: 400 },
				{ type: 'boolean', key: 'VIRTUAL_flag_is_always_candidate', required: false, width: 400 },
				//{ type: 'dropdown', key: 'sachbearbeiter_id', label: 'Sachbearbeiter', required: false },
				//{ type: 'dropdown', key: 'verantwortlicher_id', label: 'Verantwortlicher', required: false },
				//{ type: 'text', key: 'weitere_personen', required: false, width: 400 },
			],
			[
				{ type: 'plz', key: 'plz_von', required: false, width: 400 },
				{ type: 'village', key: 'ort_von', required: false, width: 400 },
				{ type: 'text', key: 'strasse_von', required: false, width: 400 },
				{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
				{ type: 'plz', key: 'plz_bis', required: false, width: 400 },
				{ type: 'village', key: 'ort_bis', required: false, width: 400 },
				{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
				{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
				{ type: 'object', key: 'gebiet_id', required: false, width: 400, apiUrl: 'TblGebiet', createComponent: TblGebietCreateComponent },
				{ type: 'object', key: 'tour_id', required: false, width: 400, apiUrl: 'TblTour', createComponent: TblTourCreateComponent },
			],
			[
				{ type: 'numeric', key: 'laenge', required: false, width: 400 },
				{ type: 'boolean', key: 'kuerzer_150', required: false, width: 400 },
				{ type: 'boolean', key: 'breiter_350', required: false, width: 400 },
				//{ type: 'list', key: 'rk_gruende', required: false, width: 400, apiUrl: "TblRueckfahrkatasterGrund", createComponent: TblRueckfahrkatasterGrundCreateComponent },
				//{ type: 'list', key: 'rk_gefahren', required: false, width: 400, apiUrl: "TblRueckfahrkatasterGefahr", createComponent: TblRueckfahrkatasterGefahrCreateComponent },
				//{ type: 'list', key: 'rk_status', required: false, width: 400, apiUrl: "TblRueckfahrkatasterStatus", createComponent: TblRueckfahrkatasterStatusCreateComponent },
				//{ type: 'rating', key: 'bewertung', required: false, width: 400 },
			],
			//[
			//	{ type: 'list', key: 'rk_massnahmen', required: false, width: 400, apiUrl: "TblRueckfahrkatasterMassnahme", createComponent: TblRueckfahrkatasterMassnahmeCreateComponent },
			//	{ type: 'date', key: 'massnahme_umgesetzt_am', label: 'Umgesetzt am' },
			//	{ type: 'dropdown', key: 'massnahme_umgesetzt_von_id', label: 'Umgesetzt von', required: false },
			//]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);

		this.options.bewertung = [
			{ label: '0 von 5', value: 0, icon: '' },
			{ label: '1 von 5', value: 1, icon: 'green' },
			{ label: '2 von 5', value: 2, icon: 'lightgreen' },
			{ label: '3 von 5', value: 3, icon: 'yellow' },
			{ label: '4 von 5', value: 4, icon: 'lightcoral' },
			{ label: '5 von 5', value: 5, icon: 'red' },
		];

		this.ratings =
		{
			0: '',
			1: 'green',
			2: 'lightgreen',
			3: 'yellow',
			4: 'lightcoral',
			5: 'red'
		};

		this.options = {
			massnahme_umgesetzt_von_id: [],
			sachbearbeiter_id: [],
			verantwortlicher_id: []

		};
	}

	ngOnInit() {
		super.ngOnInit();
		//this.loading += 1;
		//this.crudService.getStaff().then(res => {
		//	const o = [];
		//	res.forEach(l => {
		//		o.push({ label: l.bezeichnung, value: l.ds_this_id });
		//	});
		//	this.options.massnahme_umgesetzt_von_id = o;
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});

		//this.loading += 1;
		//this.crudService.getStaff().then(res => {
		//	const o = [];
		//	res.forEach(l => {
		//		o.push({ label: l.bezeichnung, value: l.ds_this_id });
		//	});
		//	this.options.sachbearbeiter_id = o;
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});

		//this.loading += 1;
		//this.crudService.getStaff().then(res => {
		//	const o = [];
		//	res.forEach(l => {
		//		o.push({ label: l.bezeichnung, value: l.ds_this_id });
		//	});
		//	this.options.verantwortlicher_id = o;
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
	}

	updateRating(fieldkey?: string): void {
		if (this.entry && (!fieldkey || fieldkey == 'rk_gefahren')) {
			this.entry['fkey'] = 0;
			this.entry['rk_gefahren'].forEach(x => {
				this.entry['fkey'] += x['value'];
			});

			if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[5] : 50))
				this.rating = 5;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[4] : 40))
				this.rating = 4;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[3] : 30))
				this.rating = 3;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[2] : 20))
				this.rating = 2;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[1] : 10))
				this.rating = 1;
			else
				this.rating = 0;
		}
	}

	/**
	 * Löscht den Eintrag aus der Datenbank
	 */
	createEntry(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'boolean') {
						if (!this.entry[field.key]) {
							this.entry[field.key] = false;
						}
					}
				});
			});
			this.crudService.createEntry(this.apiUrl, this.entry).then(res => {
				if (this.ref) {
					this.ref.close(true);
				} else {
					if (res) {
						this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res]);
					} else {
						this.router.navigate([this.url + '/' + PATH.LIST]);
					}
				}
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	getOptions(): void {
		this.loading += 1;
		this.adminService.getCustomerSettings().then(res => {
			if (res) {
				this.rk_rating_boundaries = res.rk_rating_boundaries;
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
		super.getOptions();
	}
}

import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { RasAkteDefaultFilter, RasAufzeichnungDefaultFilter, RasAufzeichnungDefaultFilterGeoOptions, SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TblRueckfahrkatasterAufzeichnungFilterDialogComponent } from './filter_dialog/filter_dialog.component';
import { Base } from '@app/models/base';
import { TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent } from '../popup_list_aktenzuweisung/list_aktenzuweisung.component';

@Component({
	templateUrl: '../../../../common/templates/list/list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblRueckfahrkatasterAufzeichnungListComponent extends ListComponent {
	url_rk_akte: string = '';

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public dialogService: DialogService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblRueckfahrkatasterAufzeichnung';
		this.name = 'MENU.AUFZEICHNUNG';
		this.url = '/' + PATH.RK_AUFZEICHNUNG;
		this.url_rk_akte = '/' + PATH.RK_STRECKE;
		this.module = Module.ReversingCadastral;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gebiet_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			//{ type: 'numeric', key: 'fkey', required: true, width: 400 },
			//{ type: 'rating', key: 'bewertung', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'boolean', key: 'ras_deaktiviert', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 },
			{ type: 'text', key: 'gpx', required: false, width: 400 },
			{ type: 'text', key: 'video_url', required: false, width: 400 },
			//{ type: 'text', key: 'rk_gruende', required: false, width: 400 },
			//{ type: 'text', key: 'rk_gefahren', required: false, width: 400 },
			//{ type: 'text', key: 'rk_massnahmen', required: false, width: 400 },
			//{ type: 'text', key: 'rk_status', required: false, width: 400 }
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			//{ type: 'text', key: 'rk_status', required: false, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 }
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);

		this.hasFilterDialog = true;
	}

	openFilterDialog() {
		const ref = this.dialogService.open(TblRueckfahrkatasterAufzeichnungFilterDialogComponent, {
			header: this.translate.instant('Filter'),
			width: '70%'
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				//console.log(filters);
				this.getFilteredEntries(filters);
			}
		});
	}

	getFilteredEntries(filters) {
		//console.log(filters);
		if( filters['breiter_350_ex'] != null && filters['breiter_350_ex'] != undefined ) {
			if(filters['breiter_350_ex'] === 0) {
				filters['breiter_350'] = false;
			} else filters['breiter_350'] = true;
		} else filters['breiter_350'] = null;
		
		if( filters['kuerzer_150_ex'] != null && filters['kuerzer_150_ex'] != undefined ) {
			if(filters['kuerzer_150_ex'] === 0) {
				filters['kuerzer_150'] = false;
			} else filters['kuerzer_150'] = true;
		} else filters['kuerzer_150'] = null;
		
		if( filters['hat_akte_ex'] != null && filters['hat_akte_ex'] != undefined ) {
			if(filters['hat_akte_ex'] === 0) {
				filters['hat_akte'] = false;
			} else filters['hat_akte'] = true;
		} else filters['hat_akte'] = null;

		//hat_geo_adresse_ex
		if( filters['hat_geo_adresse_ex'] != null && filters['hat_geo_adresse_ex'] != undefined ) {
			if(filters['hat_geo_adresse_ex'] === 0) {
				filters['hat_geo_adresse'] = false;
			} else filters['hat_geo_adresse'] = true;
		} else filters['hat_geo_adresse'] = null;
		//console.log(filters);

		this.loading += 1;
		this.entries = [];
		this.loadFilters = filters;
		this.crudService.getFilteredEntries(this.apiUrl, filters).then(res => {
			this.processEntries(res);
			this.resizeTableWidth();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// override..?
	ngOnInit(): void {
		super.ngOnInit();
		this.entries = [];
		
		let nModeRasRecordView: number = RasAufzeichnungDefaultFilter.FilterAnzeigen;
		let nModeRasRecordViewGeoOptions: number = RasAufzeichnungDefaultFilterGeoOptions.Alle;

		let nDefaultViewOptionsRas: any = this.settingsService.getCurrentRasRecordsDefaultView();
		if( nDefaultViewOptionsRas != null && nDefaultViewOptionsRas != undefined ) {
			// getCurrentRasRecordsDefaultView liefert:
			//return {
			//	optionDefaultView: nDefaultView,
			//	optionDefaultGeoOption: nDefaultGeoOption
			//};
			if(nDefaultViewOptionsRas['optionDefaultView'] != null && nDefaultViewOptionsRas['optionDefaultView'] != undefined && nDefaultViewOptionsRas['optionDefaultView'] >= 0) {
				nModeRasRecordView = nDefaultViewOptionsRas['optionDefaultView'];
			}
			if(nDefaultViewOptionsRas['optionDefaultGeoOption'] != null && nDefaultViewOptionsRas['optionDefaultGeoOption'] != undefined && nDefaultViewOptionsRas['optionDefaultGeoOption'] >= 0) {
				nModeRasRecordViewGeoOptions = nDefaultViewOptionsRas['optionDefaultGeoOption'];
			}
		}
		
		switch(nModeRasRecordView) {
			case RasAufzeichnungDefaultFilter.AlleDaten:
				this.getAllEntries();
				break;
			
			case RasAufzeichnungDefaultFilter.MitAkte:
				if( nModeRasRecordViewGeoOptions != RasAufzeichnungDefaultFilterGeoOptions.Alle ) {
					// mit zusätzlichen geo-Optionen
					if(nModeRasRecordViewGeoOptions == RasAufzeichnungDefaultFilterGeoOptions.MitGeo) {
						this.getFilteredEntries({hat_akte_ex: 1, hat_geo_adresse_ex: 1});
					} else if(nModeRasRecordViewGeoOptions == RasAufzeichnungDefaultFilterGeoOptions.OhneGeo) {
						this.getFilteredEntries({hat_akte_ex: 1, hat_geo_adresse_ex: 0});
					} else {
						// hmm, failback, dann doch ohne zusatz geo option
						this.getFilteredEntries({hat_akte_ex: 1});
					}
				} else {
					this.getFilteredEntries({hat_akte_ex: 1});
				}
				break;

			case RasAufzeichnungDefaultFilter.OhneAkte:
				if( nModeRasRecordViewGeoOptions != RasAufzeichnungDefaultFilterGeoOptions.Alle ) {
					// mit zusätzlichen geo-Optionen
					if(nModeRasRecordViewGeoOptions == RasAufzeichnungDefaultFilterGeoOptions.MitGeo) {
						this.getFilteredEntries({hat_akte_ex: 0, hat_geo_adresse_ex: 1});
					} else if(nModeRasRecordViewGeoOptions == RasAufzeichnungDefaultFilterGeoOptions.OhneGeo) {
						this.getFilteredEntries({hat_akte_ex: 0, hat_geo_adresse_ex: 0});
					} else {
						// hmm, failback, dann doch ohne zusatz geo option
						this.getFilteredEntries({hat_akte_ex: 0});
					}
				} else {
					this.getFilteredEntries({hat_akte_ex: 0});
				}
				break;

			case RasAufzeichnungDefaultFilter.FilterAnzeigen:
				this.openFilterDialog();
				break;
				
			default:
			case RasAufzeichnungDefaultFilter.KeineDaten:
				// nothing TODO ;)
				break;
		}
	}

	// override
	initContextMenu(): void {
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				// default
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) },
				// custom
				{ separator: true },
				//{ label: this.translate.instant('CONTEXT_MENU.RUECKFAHRKATASTER.AKTE_ZUWEISEN'), icon: 'pi pi-file-o', command: () => this.aktenzuweisung(), disabled: !this.isContextAktenzuweisungPossible() }, // geht nicht, wird nicht beim öffnen des popups ausgewertet
				{ label: this.translate.instant('CONTEXT_MENU.RUECKFAHRKATASTER.AKTE_ZUWEISEN'), icon: 'pi pi-file-o', command: () => this.aktenzuweisung() },
				//
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	hasRasFile(pEntry: Base): boolean {
		if(pEntry && pEntry['akte_id'] != null && pEntry['akte_id'] != undefined && pEntry['akte_id'] >= 1) {
			return true;
		}
		return false;
	}

	hasNoRasFile(pEntry: Base): boolean {
		return !this.hasRasFile(pEntry);
	}

	_aktenzuweisung_neue_anlegen_und_zeigen(): void {
		// neue anlegen und zeigen
		this.loading += 1;
		this.crudService.createFileForRecord(this.selectedEntry).then(resultingId => {
			//console.log('createFileForRecord done!');
			//console.log(res);
			this.loading -= 1;
			if( resultingId != null && resultingId != undefined && resultingId > 0 ) {
				this.confirmationService.confirm({
					message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.NEUE_AKTE_ANZEIGEN'),
					header: this.translate.instant('CONFIRMATION.CONFIRM'),
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: this.translate.instant('CONFIRMATION.YES'),
					rejectLabel: this.translate.instant('CONFIRMATION.NO'),
					accept: () => {
						this.loading += 1;
						this.crudService.getTrack(this.selectedEntry.ds_this_id).then(res => {
							this.loading -= 1;
							this.selectedEntry = res;
							if(this.selectedEntry['FREMD_strecke_ds_this_id']) {
								//this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + this.selectedEntry['FREMD_strecke_ds_this_id']]);
								let strTarget: string = 'tab'; // über Variable implementiert, falls das doch noch parametrierbar gestaltet werden soll später
								this.editAkteEx(strTarget);
							}
						}).catch(err => {
							this.loading -= 1;
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						});
					}
				});
			} else {
				this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.RUECKFAHRKATASTER.AKTENERZEUGUNG_FEHLER'), life: 8000 });
			}
		}).catch(err => {
			this.loading -= 1;
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		});
	}

	_aktenzuweisung_link2existing(akte_id_existing: number): void {
		this.loading += 1;
		this.crudService.linkRecordToFile(this.selectedEntry.ds_this_id, akte_id_existing).then(res => {
			this.loading -= 1;
			if(res) {
				this.confirmationService.confirm({
					message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.NEU_VERKNUEPFTE_AKTE_ANZEIGEN'),
					header: this.translate.instant('CONFIRMATION.CONFIRM'),
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: this.translate.instant('CONFIRMATION.YES'),
					rejectLabel: this.translate.instant('CONFIRMATION.NO'),
					accept: () => {
						//this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + akte_id_existing]);
						this.loading += 1;
						this.crudService.getTrack(this.selectedEntry.ds_this_id).then(res => {
							this.loading -= 1;
							this.selectedEntry = res;
							if(this.selectedEntry['FREMD_strecke_ds_this_id']) {
								//this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + this.selectedEntry['FREMD_strecke_ds_this_id']]);
								let strTarget: string = 'tab'; // über Variable implementiert, falls das doch noch parametrierbar gestaltet werden soll später
								this.editAkteEx(strTarget);
							}
						}).catch(err => {
							this.loading -= 1;
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						});
					}
				});
			} else {
				this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.RUECKFAHRKATASTER.AKTENVERKNUEPFUNG_FEHLER'), life: 8000 });
			}
		}).catch(err => {
			this.loading -= 1;
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			});
		});
	}

	editAkteEx(target: string) {
		if (target == 'window') {
			window.open('/#/' + this.url_rk_akte + '/' + PATH.EDIT + '/' + this.selectedEntry['FREMD_strecke_ds_this_id'], '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url_rk_akte + '/' + PATH.EDIT + '/' + this.selectedEntry['FREMD_strecke_ds_this_id']);
		} else {
			this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + this.selectedEntry['FREMD_strecke_ds_this_id']]);
		}
		/*
			if (target == 'window') {
				window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
			} else if (target == 'tab') {
				window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id);
			} else {
				this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id]);
			}
		*/
	}

	isContextAktenzuweisungPossible(): boolean {
		let bHasData = false;
		let bHasFile = false;
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			bHasData = true;
			bHasFile = this.hasRasFile(this.selectedEntry);
		}
		if( !bHasData || bHasFile )
			return false;
		return true;
	}

	aktenzuweisung(): void {
		let bHasData = false;
		let bHasFile = false;
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			bHasData = true;
			bHasFile = this.hasRasFile(this.selectedEntry);
		}
	
		if( !bHasData ) {
			this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.LISTE_KEINE_AUSWAHL'), life: 3000 });
		} else if(bHasFile) {
			this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.RUECKFAHRKATASTER.HAT_BEREITS_AKTE'), life: 6000 });
		} else {
			let bDatenAusreichend: boolean = false;
			let hasPLZ = this.selectedEntry['plz_von'] != null && this.selectedEntry['plz_von'] != undefined && this.selectedEntry['plz_von'].toString().trim().length > 0;
			let hasOrt = this.selectedEntry['ort_von'] != null && this.selectedEntry['ort_von'] != undefined && this.selectedEntry['ort_von'].toString().trim().length > 0;
			let hasStrasse = this.selectedEntry['strasse_von'] != null && this.selectedEntry['strasse_von'] != undefined && this.selectedEntry['strasse_von'].toString().trim().length > 0;
			bDatenAusreichend = hasPLZ && hasOrt && hasStrasse;
			if( !bDatenAusreichend ) {
				this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.RUECKFAHRKATASTER.DATEN_UNGENUEGEND'), life: 8000 });
			} else {
				this.loading += 1;
				this.crudService.getTrackfileCandidatesIncludingAlwaysCandidates(this.selectedEntry).then(res => {
					this.loading -= 1;
					if( res && res.length > 0 ) {
						// picker anzeigen
						const ref = this.dialogService.open(TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent, {
							header: this.translate.instant('Aktenzuweisung'),
							width: '70%',
							data: { candidateFiles: res }
						});

						ref.onClose.subscribe((data) => {
							// onClose:
							//this.resultData['selectedEntry'] = this.selectedEntry;
						
							if( data != null && data != undefined ) {
								if( data['selectedEntry'] != null && data['selectedEntry'] != undefined ) {
									let pExistingFile: Base = data['selectedEntry'];
									if( pExistingFile != null && pExistingFile != undefined
										&& pExistingFile.ds_this_id != null && pExistingFile.ds_this_id != undefined
										&& pExistingFile.ds_this_id !== 0 )
									{
										this._aktenzuweisung_link2existing(pExistingFile.ds_this_id);
									}
								} else if(data['createNew']) {
									this.confirmationService.confirm({
										message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.KEINE_AKTE_ZUM_LINKEN_AUSGEWAEHLT'),
										header: this.translate.instant('CONFIRMATION.CONFIRM'),
										icon: 'pi pi-exclamation-triangle',
										acceptLabel: this.translate.instant('CONFIRMATION.YES'),
										rejectLabel: this.translate.instant('CONFIRMATION.NO'),
										accept: () => {
											this._aktenzuweisung_neue_anlegen_und_zeigen();
										}
									});
								}
							}
						});
					} else {
						// keine kandidaten, neue anlegen mit rückfrage
						this.confirmationService.confirm({
							message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.KEINE_AKTE_VORHANDEN_NEUE_ANLEGEN'),
							header: this.translate.instant('CONFIRMATION.CONFIRM'),
							icon: 'pi pi-exclamation-triangle',
							acceptLabel: this.translate.instant('CONFIRMATION.YES'),
							rejectLabel: this.translate.instant('CONFIRMATION.NO'),
							accept: () => {
								this._aktenzuweisung_neue_anlegen_und_zeigen();
							}
						});
					}
				}).catch(err => {
					this.loading -= 1;
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 3000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 3000 });
						}
					});
					this.confirmationService.confirm({
						message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.FEHLER_AKTEN_LADEN_NEUE_ANLEGEN'),
						header: this.translate.instant('CONFIRMATION.CONFIRM'),
						icon: 'pi pi-exclamation-triangle',
						acceptLabel: this.translate.instant('CONFIRMATION.YES'),
						rejectLabel: this.translate.instant('CONFIRMATION.NO'),
						accept: () => {
							this._aktenzuweisung_neue_anlegen_und_zeigen();
						}
					});
				});
			} // if( !bDatenAusreichend )
		}
	}
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { Area, AreaPoint } from '@models/area';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { LineString, Point, Polygon } from 'ol/geom';
import { Collection, Feature, Map, View } from 'ol/index';
import { Translate } from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
	templateUrl: './edit.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblGebietEditComponent implements OnInit, AfterViewInit {
	@ViewChild('dt', { read: '', static: true }) dt: Table;
	public area: AreaPoint[];
	private borderLayer: VectorLayer;
	private borderStyle: Style;
	public cols: any[];
	private coord = [8.0787, 50.8233];
	public filters = [];
	id: number;
	public loading = 0;
	private mapGebietEdit: Map;
	private mapLayer: TileLayer;
	private markerLayer: VectorLayer;
	private markerStyle: Style;
	public pointCount: number;
	private polygonLayer: VectorLayer;
	private polygonStyle: Style;
	private selectedMarkerStyle: Style;
	public selectedRow: AreaPoint;
	//public cacheIsAusschlussgebiet: boolean;

	name: string;
	apiUrl: string = '';
	url: string = '';

	constructor(
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];

		this.apiUrl = 'TblGebiet';
		this.name = 'MENU.GEBIET';
		this.url = '/' + PATH.GEBIET;
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: [this.url + '/' + PATH.EDIT + '/' + this.id] }
		]);
	}

	ngAfterViewInit() {
		this.initMap();
		let maxHeightDatatable = 'calc(100vh - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '32rem' : '28rem') + ')';
		let maxHeightMap = 'calc(100vh - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '21rem' : '17rem') + ')';

		//let elemDataTable: HTMLElement = document.querySelector('.p-datatable-scrollable-body') as HTMLElement;
		let elemDataTable: HTMLElement = document.querySelector('#dt') as HTMLElement;
		let elemMap: HTMLElement = document.querySelector('#mapGebietEdit') as HTMLElement;

		if( elemDataTable ) {
			elemDataTable.style.maxHeight = maxHeightDatatable;
		}
		if( elemMap ) {
			elemMap.style.maxHeight = maxHeightMap;
		}

		this.initialDrawLoadedArea();
	}

	ngOnInit() {
		this.loadArea();

		this.cols = [
			{ field: 'X', header: 'Longitude' },
			{ field: 'Y', header: 'Latitude' },
		];

		this.cols.forEach(col => {
			if ('subfield' in col) {
				this.filters.push(col.field + '.' + col.subfield);
			} else {
				this.filters.push(col.field);
			}
		});
	}

	centerOfPoints(points: AreaPoint[]) {
		let centerX = 0;
		let centerY = 0;

		points.forEach(p => {
			centerX += p.X;
			centerY += p.Y;
		});

		centerX /= points.length;
		centerY /= points.length;

		return [centerX, centerY];
	}

	centerOn(point: number[]) {
		this.mapGebietEdit.getView().centerOn(point, this.mapGebietEdit.getSize(), [this.mapGebietEdit.getSize()[0] / 2, this.mapGebietEdit.getSize()[1] / 2]);
	}

	deletePoint(point) {
		if( this.hasValue(this.area) ) {
			if( this.area.length > 1 ) {
				this.area.splice(this.area.indexOf(point), 1);
				this.redraw();
			} else {
				this.startFromScratch();
			}
		}
	}

	startFromScratch() {
		// TODO: hiermit komplett neu beginnen, wie beim Create
		const newInitialPoint = {
			GebietId: this.area[0].GebietId,
			Bezeichnung: this.area[0].Bezeichnung,
			GebietAnkey: this.area[0].GebietAnkey,
			GebietFlags: this.area[0].GebietFlags,
			X: null,
			Y: null
		};
		this.name = this.area[0].Bezeichnung;
		this.area = [];
		this.area.push(newInitialPoint);
		this.redraw();
	}

	initMap() {
		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			})
		});

		this.selectedMarkerStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({
					color: '#55ff55'
				}),
			})
		});
		this.markerStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({
					color: '#ff5555'
				}),
			})
		});
		this.markerLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: this.markerStyle,
			name: 'Marker'
		});

		this.borderStyle = new Style({
			stroke: new Stroke({
				color: '#3333ff',
				width: 4
			})
		});
		this.borderLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.borderStyle,
			name: 'Border'
		});

		this.polygonStyle = new Style({
			fill: new Fill({
				color: '#ccccff'
			})
		});
		this.polygonLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.polygonStyle,
			name: 'Polygon'
		});

		useGeographic();
		this.mapGebietEdit = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'mapGebietEdit',
			view: new View({
				center: this.coord,
				zoom: 14,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.polygonLayer,
				this.borderLayer,
				this.markerLayer
			]
		});
		[this.mapLayer, this.markerLayer, this.borderLayer, this.polygonLayer].forEach(layer => {
			layer.getSource().on('tileloadstart', () => {
				this.loading += 1;
			});
			layer.getSource().on('tileloadend', () => {
				this.loading -= 1;
			});
			layer.getSource().on('tileloaderror', () => {
				this.loading -= 1;
			});
		});

		this.mapGebietEdit.addInteraction(new Translate({
			features: new Collection([])
		}));

		this.mapGebietEdit.on('click', (event) => {
			let clickedMarker = false;
			let clickedBorder = false;

			this.mapGebietEdit.forEachFeatureAtPixel(
				event.pixel,
				(feature, layer) => {
					if (layer.get('name') === 'Marker') {
						this.area.forEach(point => {
							if (this.area.indexOf(point) === feature.get('name')) {
								this.selectPoint(point);
								clickedMarker = true;
							}
						});
					} else if (layer.get('name') === 'Border') {
						if (!clickedMarker) {
							this.area.forEach(point => {
								if (this.area.indexOf(point) === feature.get('name')) {
									const newPoint = {
										GebietId: point.GebietId,
										Bezeichnung: point.Bezeichnung,
										GebietAnkey: point.GebietAnkey,
										GebietFlags: point.GebietFlags,
										X: event.coordinate[0],
										Y: event.coordinate[1]
									};
									this.area.splice(this.area.indexOf(point), 0, newPoint);
									this.redraw();
									clickedBorder = true;
								}
							});
						}
					}
				},
				{
					hitTolerance: 5
				}
			);

			if (!clickedMarker && !clickedBorder) {
				const newPoint = {
					GebietId: (this.hasValue(this.area) && this.area.length && this.hasValue(this.area[0])) ?  this.area[0].GebietId : null,
					Bezeichnung: (this.hasValue(this.area) && this.area.length && this.hasValue(this.area[0])) ?  this.area[0].Bezeichnung : null,
					GebietAnkey: (this.hasValue(this.area) && this.area.length && this.hasValue(this.area[0])) ?  this.area[0].GebietAnkey : null,
					GebietFlags: (this.hasValue(this.area) && this.area.length && this.hasValue(this.area[0])) ?  this.area[0].GebietFlags : null,
					X: event.coordinate[0],
					Y: event.coordinate[1]
				};
				if (this.area) {
					if( this.area.length === 1 && (!this.hasValue(this.area[0].X) && !this.hasValue(this.area[0].Y))) {
						this.area = [];
						this.area.push(newPoint);
					} else {
						this.area.push(newPoint);
					}
				} else {
					this.area = [newPoint];
				}
				this.redraw();
			}
		});
	}

	loadArea() {
		this.loading += 1;
		this.crudService.getArea(this.id).then(res => {
			this.area = res;
			this.pointCount = this.area.length;
			//this.cacheIsAusschlussgebiet = false;
			//if( this.area && this.area.length > 0 && this.area[0] ) {
			//	this.cacheIsAusschlussgebiet = this.area[0].FLAG_GebietIsAusschlussgebiet;
			//}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	initialDrawLoadedArea() {
		let bCenterOn = true;
		if( 1 == this.area.length ) {
			if( this.area[0].PunktId == null || this.area[0].PunktId == undefined ) {
				this.pointCount = 0;
				bCenterOn = false;
			}
		}
		this.showBorders();
		this.showPolygon();
		this.showMarkers();
		if( bCenterOn ) {
			this.centerOn(this.centerOfPoints(this.area));
		}
	}

	onFilter(event) {
		this.loading += 1;
		this.dt.filterGlobal(event.target.value, 'contains');
		setTimeout(() => {
			if (this.dt.filteredValue) {
				this.pointCount = this.dt.filteredValue.length;
			} else {
				this.pointCount = this.area.length;
			}
			this.loading -= 1;
		}, 500);
	}

	OnRowSelect(event) {
		this.selectPoint(event.data);
	}

	onSort(event: SortEvent) {
		let subfield: string;
		this.cols.forEach(col => {
			if (col.field === event.field) {
				if ('subfield' in col) {
					subfield = col.subfield;
				}
			}
		});

		event.data.sort((data1, data2) => {
			let value1 = data1[event.field];
			let value2 = data2[event.field];
			let result = null;

			if (data1[event.field] && subfield) {
				value1 = data1[event.field][subfield];
			}
			if (data2[event.field] && subfield) {
				value2 = data2[event.field][subfield];
			}

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1 === 'string' && typeof value2 === 'string')
				result = value1.localeCompare(value2);
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
	}

	redraw() {
		this.borderLayer.getSource().clear();
		this.markerLayer.getSource().clear();
		this.polygonLayer.getSource().clear();
		if( this.hasValue(this.area) && this.area.length > 0 ) {
			this.showMarkers();
			this.showPolygon();
			this.showBorders();
			this.pointCount = this.area.length;
		} else this.pointCount = 0;
	}

	save() {
		this.loading += 1;

		//this.area[0].FLAG_GebietIsAusschlussgebiet = this.cacheIsAusschlussgebiet;
		if(this.area[0].GebietFlags == null || this.area[0].GebietFlags == undefined) {
			this.area[0].GebietFlags = 0;
		}
		if(this.area[0].FLAG_GebietIsAusschlussgebiet) {
			this.area[0].GebietFlags |= 1<<0;
		} else {
			this.area[0].GebietFlags &= ~(1<<0);
		}
		const newArea: Area = {
			Id: this.area[0].GebietId,
			Bezeichnung: this.area[0].Bezeichnung,
			Ankey: this.area[0].GebietAnkey,
			Flags: this.area[0].GebietFlags,
			Eckpunkte: []
		};
		this.area.forEach(point => {
			if( this.hasValue(point.X) && this.hasValue(point.Y) ) {
				newArea.Eckpunkte.push([point.X, point.Y]);
			}
		});

		this.crudService.editArea(newArea).then(res => {
			//this.router.navigate(['/masterdata/areas/detail/' + this.id]);
			if (res) {
				this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res]);
			} else {
				this.router.navigate([this.url + '/' + PATH.LIST]);
			}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	selectPoint(point) {
		this.selectedRow = point;

		this.markerLayer.getSource().getFeatures().forEach(feature => {
			if (feature.get('name') === this.area.indexOf(point)) {
				feature.setStyle(this.selectedMarkerStyle);
				const t = new Translate({
					features: new Collection([feature])
				});
				t.on('translateend', (e) => {
					e.features.forEach(f => {
						point.X = f.getGeometry().getCoordinates()[0];
						point.Y = f.getGeometry().getCoordinates()[1];
					});
					this.redraw();
				});
				this.mapGebietEdit.addInteraction(t);
			} else {
				feature.setStyle(this.markerStyle);
			}
		});
	}

	hasValue(val): boolean {
		if( val !== null && val !== undefined ) {
			return true;
		}
		return false;
	}

	showBorders() {
		let oldMarker = this.area[this.area.length - 1];
		this.area.forEach(newMarker => {
			if( this.hasValue(oldMarker.X) && this.hasValue(oldMarker.Y) ) {
				if( this.hasValue(newMarker.X) && this.hasValue(newMarker.Y) ) {
					const feature = new Feature({
						geometry: new LineString(
							[
								[oldMarker.X, oldMarker.Y],
								[newMarker.X, newMarker.Y]
							]
						),
						name: this.area.indexOf(newMarker)
					});
					this.borderLayer.getSource().addFeature(feature);
				}
			}
			oldMarker = newMarker;
		});
	}

	showMarkers() {
		this.area.forEach(point => {
			if( this.hasValue(point.X) && this.hasValue(point.Y)) {
				const feature = new Feature({
					geometry: new Point([point.X, point.Y]),
					name: this.area.indexOf(point)
				});
				this.markerLayer.getSource().addFeature(feature);
			}
		});
	}

	showPolygon() {
		const points = [];
		if( this.hasValue(this.area[this.area.length - 1].X) && this.hasValue(this.area[this.area.length - 1].Y)) {
			points.push([this.area[this.area.length - 1].X, this.area[this.area.length - 1].Y]);
		}
		this.area.forEach(nextPoint => {
			if( this.hasValue(nextPoint.X) && this.hasValue(nextPoint.Y)) {
				points.push([nextPoint.X, nextPoint.Y]);
			}
		});
		if( points.length > 0 ) {
			const feature = new Feature(new Polygon([points]));
			feature.setId(this.id);
			this.polygonLayer.getSource().addFeature(feature);
		}
	}
}

import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import { EditComponent } from '@app/common/templates/edit/edit.component';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { AdminService } from '@app/services/admin.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { AppMainComponent } from '@components/app.main.component';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { Occasion, State } from '@models/reversingcadastral';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { asArray } from 'ol/color';
import GPX from 'ol/format/GPX';
import { Point } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { TblRueckfahrkatasterGefahrCreateComponent } from '../../tbl_rueckfahrkataster_gefahr/create/create.component';
import { TblRueckfahrkatasterGrundCreateComponent } from '../../tbl_rueckfahrkataster_grund/create/create.component';
import { TblRueckfahrkatasterMassnahmeCreateComponent } from '../../tbl_rueckfahrkataster_massnahme/create/create.component';
import { TblRueckfahrkatasterStatusCreateComponent } from '../../tbl_rueckfahrkataster_status/create/create.component';
import { TblGebietCreateComponent } from '@app/views/stammdaten/tbl_gebiet/create/create.component';
import { TblTourCreateComponent } from '@app/views/stammdaten/tbl_tour/create/create.component';

@Component({
	templateUrl: './edit.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAkteEditComponent extends EditComponent {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	apiUrl: string = '';
	buttonColWidth: number = 150;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	count: number = 0;
	createPermission: boolean = false;
	entries: Base[];
	filters: string[];
	globalFilter: string = '';
	historyUrl: string = '';
	isMobile: boolean = false;
	isTableInit: boolean = false;
	loading: number = 0;
	loadTimestamp: Date;
	minTableHeight: number = 80;
	name: string = '';
	oldCols: any[] = [];
	persistenceCheckInterval: any;
	possibleCols: any[] = [];
	selectedEntry: Base;
	tableState: any;
	tableStateName: string = '';
	url: string = '';
	url_detail: string = PATH.DETAIL;
	url_aufzeichnung: string = '';
	apiUrlAufzeichnung: string = '';
	options: any = {};

	apiUrlRkGefahren: string = '';
	apiUrlRkGruende: string = '';
	apiUrlRkMassnahmen: string = '';
	apiUrlRkStatus: string = '';

	video_api_url: string = '';
	videoSafeUrl: any;
	gpx_api_url: string = '';
	gpxUnsafeUrl: string = '';
	gpxSafeUrl: any;

	private _rkOptions = {
		"rk_gefahren": [],
		"rk_gruende": [],
		"rk_massnahmen": [],
		"rk_status": []
	};

	rk_rating_boundaries: any;

	public mapAkteEditRefAufzeichnung: Map;
	public occasions: Occasion[];
	positionLayer: VectorLayer;
	public rating: number = 0;
	public ratings: any;

	routeLayer: VectorLayer;
	startTimeStamp: number;
	public states: State[];
	trackPoints: any[];
	videoplayer: any;

	constructor(
		public app: AppMainComponent,
		private adminService: AdminService,
		public dialogService: DialogService,
		private exportService: ExportService,
		public globals: Globals,
		public elRef: ElementRef,
		private settingsService: SettingsService,
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
		public changeDetectorRef: ChangeDetectorRef,
		private http: HttpClient,
		private sanitizer: DomSanitizer
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, dialogService, globals, messageService, router, translate);

		this.apiUrl = 'TblRueckfahrkatasterStrecke';
		this.name = 'MENU.RUECKFAHRKATASTER';
		this.url = '/' + PATH.RK_STRECKE;
		this.url_aufzeichnung = '/' + PATH.RK_AUFZEICHNUNG;
		this.apiUrlAufzeichnung = 'TblRueckfahrkatasterAufzeichnung';

		this.apiUrlRkGefahren = 'TblRueckfahrkatasterGefahr';
		this.apiUrlRkGruende = 'TblRueckfahrkatasterGrund';
		this.apiUrlRkMassnahmen = 'TblRueckfahrkatasterMassnahme';
		this.apiUrlRkStatus = 'TblRueckfahrkatasterStatus';

		this.ratings =
		{
			0: '',
			1: 'green',
			2: 'lightgreen',
			3: 'yellow',
			4: 'lightcoral',
			5: 'red'
		};

		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'name', required: true, width: 400 },
				{ type: 'text', key: 'freitext', required: false, width: 400 },
				{ type: 'boolean', key: 'in_kartaster', required: false, width: 400 },
				{ type: 'boolean', key: 'VIRTUAL_flag_is_always_candidate', required: false, width: 400 },
				{ type: 'dropdown', key: 'sachbearbeiter_id', label: 'Sachbearbeiter', required: false },
				{ type: 'dropdown', key: 'verantwortlicher_id', label: 'Verantwortlicher', required: false },
				{ type: 'text', key: 'weitere_personen', required: false, width: 400 },
			],
			[
				{ type: 'plz', key: 'plz_von', required: false, width: 400 },
				{ type: 'village', key: 'ort_von', required: false, width: 400 },
				{ type: 'text', key: 'strasse_von', required: false, width: 400 },
				{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
				{ type: 'plz', key: 'plz_bis', required: false, width: 400 },
				{ type: 'village', key: 'ort_bis', required: false, width: 400 },
				{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
				{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
				//{ type: 'object', key: 'FREMD_gebiet_bezeichnung', required: false, width: 400, url: '/' + PATH.GEBIET, id: 'gebiet_id' },
				{ type: 'object', key: 'gebiet_id', required: false, width: 400, apiUrl: 'TblGebiet', createComponent: TblGebietCreateComponent },
				{ type: 'object', key: 'tour_id', required: false, width: 400, apiUrl: 'TblTour', createComponent: TblTourCreateComponent },
			],
			[
				{ type: 'object', key: 'referenz_aufzeichnung_id', required: false, width: 400 },
				{ type: 'numeric', key: 'laenge', required: false, width: 400 },
				{ type: 'boolean', key: 'breiter_350', required: false, width: 400 },
				{ type: 'boolean', key: 'kuerzer_150', required: false, width: 400 },
				{ type: 'list', key: 'rk_gruende', required: false, width: 400, apiUrl: "TblRueckfahrkatasterGrund", createComponent: TblRueckfahrkatasterGrundCreateComponent },
				{ type: 'list', key: 'rk_gefahren', required: false, width: 400, apiUrl: "TblRueckfahrkatasterGefahr", createComponent: TblRueckfahrkatasterGefahrCreateComponent },
				{ type: 'list', key: 'rk_status', required: false, width: 400, apiUrl: "TblRueckfahrkatasterStatus", createComponent: TblRueckfahrkatasterStatusCreateComponent },
				{ type: 'numeric', key: 'fkey', label: 'Risikopunkte' },
				{ type: 'rating', key: 'bewertung', label: 'Risikobewertung' },
			],
			[
				{ type: 'list', key: 'rk_massnahmen', required: false, width: 400, apiUrl: "TblRueckfahrkatasterMassnahme", createComponent: TblRueckfahrkatasterMassnahmeCreateComponent },
				{ type: 'date', key: 'massnahme_umgesetzt_am', label: 'Umgesetzt am' },
				{ type: 'dropdown', key: 'massnahme_umgesetzt_von_id', label: 'Umgesetzt von', required: false },
			]

			/*
			  public DateTime ? massnahme_umgesetzt_am { get; set; } = null;
        public UInt64? massnahme_umgesetzt_von_id { get; set; } = null;
        public UInt64 ? sachbearbeiter_id { get; set; } = null;
        public UInt64 ? verantwortlicher_id { get; set; } = null;
        public string weitere_personen { get; set; } = null;
        public string weitere_bemerkungen { get; set; } = null;

			   public string FREMD_massnahme_umgesetzt_von_bezeichnung { get; set; } = null;
		[NotMapped]
        public string FREMD_sachbearbeiter_bezeichnung { get; set; } = null;
[NotMapped]
        public string FREMD_verantwortlicher_bezeichnung { get; set; } = null;
		*/
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: ['/' + PATH.RK_STRECKE] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);

		this.historyUrl = '/rueckfahrkataster_aufzeichnung';
		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'ras_deaktiviert', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 },
			{ type: 'text', key: 'gpx', required: false, width: 400 },
			{ type: 'text', key: 'video_url', required: false, width: 400 }
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'ras_deaktiviert', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 }
		];

		this.options = {
			massnahme_umgesetzt_von_id: [],
			sachbearbeiter_id: [],
			verantwortlicher_id: []

		};
	}

	// #region Angular

	ngOnInit() {
		this.createPermission = this.accountService.checkPermissions(Module.ReversingCadastral, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.ReversingCadastral, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.ReversingCadastral, Operation.UPDATE);

		this.stateName = 'state' + this.apiUrl + 'Edit';
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		this.tableStateName = 'state' + this.apiUrl + 'EditHistoryTracks' + 'List';
		this.retrieveTableState(this.tableState);
		if (this.tableState) {
			this.cols = [];
			this.tableState['columnOrder'].forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
			if (this.tableState.filters.global) {
				this.globalFilter = this.tableState.filters.global.value;
			}
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detailTracks() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detailTracks('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detailTracks('window') },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw', command: () => this.resizeTableWidthFromContent(true) }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	ngAfterViewInit() {
		if (this.readPermission) {
			this.getEntry();
		}

		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initTable();
	}

	ngAfterViewChecked() {
		if (!this.isTableInit && this.table && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidthFromContent(false);
			this.changeDetectorRef.detectChanges();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.initTable();
	}

	// #endregion Angular

	// #region CRUD

	// override
	editEntry(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.crudService.editEntry(this.apiUrl, this.entry).then(res => {
				this.router.navigate([this.url + '/' + this.url_detail + '/' + this.entry.ds_this_id]);
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	updateRating(fieldkey?: string): void {
		if (!fieldkey || fieldkey == 'rk_gefahren') {
			this.entry['fkey'] = this.entry['CALC_summe_rk_gefahren_werte'];
			if( this.entry['fkey'] == null || this.entry['fkey'] == undefined ) {
				this.entry['fkey'] = 0;
				this.entry['rk_gefahren'].forEach(x => {
					this.entry['fkey'] += x['value'];
				});
			}
			
			if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[5] : 50))
				this.rating = 5;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[4] : 40))
				this.rating = 4;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[3] : 30))
				this.rating = 3;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[2] : 20))
				this.rating = 2;
			else if (this.entry["fkey"] >= (this.rk_rating_boundaries ? this.rk_rating_boundaries[1] : 10))
				this.rating = 1;
			else
				this.rating = 0;
		}
	}

	// override, weil vor Rückkehr zur gepufferten liste muss jetzt der gelöschte Eintrag aus dem Puffer gelöscht werden
	deleteEntry() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, this.entry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });

					// hier eintrag aus gepufferter Liste löschen
					let storage = VIONSessionStorage.getInstance().get(this.apiUrl);
					if (null != storage && undefined != storage) {
						const storedTimestamp = new Date(storage.timestamp);
						if (null != storage.entries && undefined != storage.entries) {
							let index = 0;
							let foundElement = false;
							for (let i = 0; i < storage.entries.length; ++i) {
								if (storage.entries[i]['ds_this_id'] === this.entry.ds_this_id) {
									foundElement = true;
									break;
								}
								index += 1;
							}
							if (foundElement && index < storage.entries.length) {
								storage.entries.splice(index, 1);
								VIONSessionStorage.getInstance().set(this.apiUrl, storedTimestamp, storage.filters, storage.entries);
							}
						}
					}

					// und neu öffnen
					this.router.navigate([this.url + '/list/']);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	getStaff() {
		this.loading += 1;
		this.crudService.getStaff().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.massnahme_umgesetzt_von_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getStaff().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.sachbearbeiter_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getStaff().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.verantwortlicher_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	getAufzeichnungen() {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.options.referenz_aufzeichnung_id = [];
		const filter = {
			plz: "",
			ort: "",
			strasse: "",
			laenger_als: null,
			datum_von: null,
			datum_bis: null,
			fahrzeuge: [],
			gefahren: [],
			massnahmen: [],
			gruende: [],
			status: [],
			akte_id: this.entry['akte_id']
		}
		this.crudService.getFilteredEntries('TblRueckfahrkatasterAufzeichnung', filter).then(res => {
			this.entries = res;
			this.count = this.entries.length;
			this.possibleCols.forEach(c => {
				if (c.type == 'date') {
					this.entries.forEach(e => {
						if (e[c.key] != null) {
							e[c.key] = new Date(e[c.key]);
						}
					});
				}
			});
			this.getOptions();
			this.initTable();
			//this.entries = [...this.entries];
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	getEntry() {
		this.loading += 1;
		this.crudService.getEntry(this.apiUrl, this.id).then(res => {
			this.entry = res;
			this.getAufzeichnungen();
			this.rating = this.entry['CALC_rk_gefahren_werte_einstufung'];
			this.updateRating();
			this.getStaff();
			this.video_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/video/` + this.entry['referenz_aufzeichnung_id'];
			this.gpx_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/gpx/` + this.entry['referenz_aufzeichnung_id'];
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (!field) {
						let a;
						a = 5;
					}
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
					else if (field.type == 'plz' && !this.placeNames[field.key] && this.entry[field.key]) {
						try {
							this.getPlacesFromPostalCode(field.key);
						} catch { }
					}
					else if (field.type == 'list') {
						if(this.entry[field.key] != null && this.entry[field.key] != undefined && this.entry[field.key].length > 0) {
							this.entry[field.key] = this.entry[field.key].split(',').map(Number);
						}
					}
				});
			});
			this.accountService.getOptions().then(options => {
				this.http.get(this.gpx_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
					const unsafeUrl = URL.createObjectURL(blob);
					const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
					this.gpxUnsafeUrl = unsafeUrl;
					this.gpxSafeUrl = safeUrl;
					this.initMap();
				});
			this.http.get(this.video_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
					const unsafeUrl = URL.createObjectURL(blob);
					const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
					this.videoSafeUrl = safeUrl;
				});
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			});
			this.entries = [...this.entries];
			this.initTable();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	getOptions(): void {
		this.loading += 1;
		this.adminService.getCustomerSettings().then(res => {
			if (res) {
				this.rk_rating_boundaries = res.rk_rating_boundaries;
				this.updateRating();
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
		this.options.bewertung = [
			{ label: '0 von 5', value: 0, icon: '' },
			{ label: '1 von 5', value: 1, icon: 'green' },
			{ label: '2 von 5', value: 2, icon: 'lightgreen' },
			{ label: '3 von 5', value: 3, icon: 'yellow' },
			{ label: '4 von 5', value: 4, icon: 'lightcoral' },
			{ label: '5 von 5', value: 5, icon: 'red' },
		];

		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.type == 'object' || field.type == 'list') {
					if(field.key === 'gebiet_id' || field.key === 'tour_id') {
						// default-implementierung wie in "super.getOptions()"
						this.loading += 1;
						this.crudService.getAllEntries(field.apiUrl).then(res => {
							this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
							field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
							field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
							this.changeSorting(field, true);
						}).catch(err => {
							err.error.forEach(e => {
								this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
							})
						}).finally(() => {
							this.loading -= 1;
						});
					} else {
						// ähnlich, aber abweichend für die multiselects gefahren usw...
						this.loading += 1;
						this.crudService.getAllEntries(field.apiUrl).then(res => {
							this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option, object: option }));
							field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
							field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
							this.changeSorting(field, true);
						}).catch(err => {
							err.error.forEach(e => {
								this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
							})
						}).finally(() => {
							this.loading -= 1;
						});
					}
				}
			});
		});

		this.loading += 1;
		if (this.entries && this.entries.length > 0) {
			this.entries.forEach(entry => {
				entry.bezeichnung = entry["aufgezeichnet"].toLocaleDateString();
			});
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.key == 'referenz_aufzeichnung_id') {
						this.options.referenz_aufzeichnung_id = this.entries.map(option => ({ label: option["aufgezeichnet"].toLocaleDateString() + ' (' + option.ankey + ')', value: option.ds_this_id, object: option }));
						field.optionSortFieldOptions = Object.keys(this.entries[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
						field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
						this.changeSorting(field, true);
					}
				});
			});
		}
		this.loading -= 1;
		//super.getOptions();
	}

	// #endregion CRUD

	// #region History

	// #region CRUD

	detailTracks(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	// #endregion CRUD

	// #region Table

	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	exportPDF() {
		this.exportService.exportPDF(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	exportXLSX() {
		this.exportService.exportXLSX(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	initTable() {
		this.contentHeight = 0;
		setTimeout(() => {
			const detailDiv = document.getElementById('edit');
			if (detailDiv) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 320 - detailDiv.offsetHeight + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.entries && this.entries.length > 0) {
					let entryHeight;
					if (this.entries.length < 5) {
						entryHeight = this.minTableHeight + (this.entries.length * 45);
					} else {
						entryHeight = this.minTableHeight + (5 * 45);
					}
					if (entryHeight > this.contentHeight) {
						this.contentHeight = entryHeight;
					}
				} else {
					this.contentHeight = this.minTableHeight;
				}
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		//this.changeDetectorRef.detectChanges();
	}

	isColFiltered(col) {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.tableState && this.tableState.filters[col.key]) {
			Object.keys(this.tableState.filters[col.key]).forEach(filter => {
				if (this.tableState.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event) {
		this.count = this.table.filteredValue ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		const columnWidths = this.tableState.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.tableState.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
	}

	onColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));

		this.resizeTableWidth(this.tableState);
	}

	resetTable() {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(tableState?) {
		this.loading += 1;

		this.retrieveTableState(this.tableState);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.tableState ? this.tableState.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.tableState) {
					localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?) {
		this.tableState = state ? state : JSON.parse(localStorage.getItem(this.tableStateName));
		if (this.table && (this.tableState == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		}
	}

	toggleColumn(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.tableState.columnOrder = event.value.map(c => c.key);
		this.tableState.columnWidths = event.value.map(c => c.width);
		this.tableState.columnWidths = this.tableState.columnWidths.join(',');
		this.tableState.columnWidths = this.tableState.columnWidths + ',' + this.buttonColWidth;
		this.tableState.tableWidth = (this.tableState.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
		this.resizeTableWidth(this.tableState);
	}

	// liefert die länge der darstellung eines textes in pixeln zurück 
	getTextLength(text: string, styleFont: string) {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	// wenn eine spalte noch ein special symbol im header hat
	adaptColumnSize(maxStringLength, columnkey) {
		return maxStringLength;
	}

	// berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	resizeTableWidthFromContent(bForce, state?) {
		var bResize = bForce;

		this.loading += 1;

		// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
		this.tableState = state ? state : JSON.parse(localStorage.getItem(this.tableStateName));
		if (this.tableState == undefined || this.tableState == null) {
			// force storage of table state
			this.table.saveState();
			// reload state
			this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
			bResize = true;
		}

		if (this.table && bResize) {
			// autosize columns
			const lTable = document.getElementById(this.table.id);
			var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
			// für alle spalten, alle daten            
			this.cols.forEach(col => {
				let columnname = this.translate.instant('HEADERS.' + col.key);
				let maxStringLength = this.getTextLength(columnname, lTableFont);
				// filter symbol
				maxStringLength = maxStringLength + 80;
				maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
				if (this.entries) {
					this.entries.forEach(row => {
						let newLength = 0;
						if (col.type == 'date') {
							if (row[col.key] != undefined)
								newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
							else
								newLength = 0;
						} else {
							newLength = this.getTextLength(row[col.key], lTableFont);
						}
						// margins zur zelle
						newLength = newLength + 26;
						if (newLength > maxStringLength)
							maxStringLength = newLength;
					})
				}

				col.width = maxStringLength;
			});

			this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		}

		// standard funktion aufrufen
		this.resizeTableWidth(this.tableState);

		this.loading -= 1;
	}

	// #endregion Table

	// #region Map & Player
	initMap() {
		useGeographic();
		const coord = [this.entry["FREMD_aufzeichnung_center_longitude"], this.entry["FREMD_aufzeichnung_center_latitude"]];

		this.positionLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: new Style({
				image: new Circle({
					radius: 5,
					fill: new Fill({
						color: 'blue'
					})
				})
			})
		});

		this.routeLayer = new VectorLayer({
			source: new VectorSource({
				//url: '/assets' + this.entry["FREMD_aufzeichnung_gpx"],
				url: this.gpxUnsafeUrl,
				format: new GPX(),
				crossOrigin: 'anonymous'
			}),
			style: (feature) => {
				var colorName = feature.get('desc') == 'v' ? this.app.forwardingColor : this.app.reversingColor;
				var colorRGB = asArray(colorName); // Farbe von Name in RGB Array umwandeln
				colorRGB = colorRGB.slice();
				colorRGB[3] = 0.7; // Alphakanal hinzufügen
				return new Style({
					stroke: new Stroke({
						color: colorRGB,
						width: 4
					})
				})
			}
		});

		this.routeLayer.getSource().on('error', this.mapError());

		this.mapAkteEditRefAufzeichnung = new Map({
			target: 'mapAkteEditRefAufzeichnung',
			view: new View({
				center: coord,
				zoom: 17,
				maxZoom: 18,
			}),
			controls: [],
			renderer: 'webgl',
			layers: [
				new TileLayer({
					source: new OSM({
						url: environment.mapUrl,
						format: 'image/png',
						crossOrigin: 'anonymous'
					})
				}),
				this.routeLayer,
				this.positionLayer
			]
		});

		this.mapAkteEditRefAufzeichnung.on('singleclick', event => {
			const coord = this.mapAkteEditRefAufzeichnung.getCoordinateFromPixel(event.pixel);
			let closestPoint = [0, 0, 0];
			this.trackPoints.forEach(tp => {
				const distCurrentPoint = Math.abs(tp[0] - coord[0]) + Math.abs(tp[1] - coord[1]);
				const distClosestPoint = Math.abs(closestPoint[0] - coord[0]) + Math.abs(closestPoint[1] - coord[1]);
				if (distCurrentPoint < distClosestPoint) {
					closestPoint = tp;
				}
			});
			const timestamp = closestPoint[2] - this.startTimeStamp;
			this.videoplayer.currentTime = timestamp;
			this.setVehiclePosition(timestamp);
		});
	}

	initGPX(event) {
		this.videoplayer = event.target;
		if (!this.trackPoints) {
			this.trackPoints = [];
			this.routeLayer.getSource().getFeatures().forEach(feature => {
				const chunkSize = 3;
				let points = [];
				for (let i = 0; i < feature.getGeometry().flatCoordinates.length; i += chunkSize) {
					const chunk = feature.getGeometry().flatCoordinates.slice(i, i + chunkSize);
					points.push(chunk);
					if (!this.startTimeStamp || +chunk[2] < this.startTimeStamp) {
						this.startTimeStamp = +chunk[2];
					}
				}
				this.trackPoints = [...this.trackPoints, ...points];
			});
		}

		this.setVehiclePosition(0);
	}

	parseDate(date: Date): string {
		const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
		if (date) {
			return (date.toLocaleDateString('de-DE', options)) + ', ' + (date.toLocaleTimeString('de-DE'));
		} else {
			return null;
		}
	}

	setVehiclePosition(videoTimestamp) {
		let position = this.trackPoints[0];
		this.trackPoints.forEach(tp => {
			if (Math.abs(this.startTimeStamp + videoTimestamp - tp[2]) < Math.abs(this.startTimeStamp + videoTimestamp - position[2])) { // evtl +5 Sekunden wegen GPS Buffer?!
				position = tp;
			}
		})

		const feature = new Feature({
			geometry: new Point([position[0], position[1]]),
		});
		this.positionLayer.getSource().clear();
		this.positionLayer.getSource().addFeature(feature);
	}

	mapError() {
		if (this.gpxUnsafeUrl == null || this.gpxUnsafeUrl == undefined || this.gpxUnsafeUrl.length == 0) {
			this.messageService.add({ severity: 'error', summary: 'Map Error', detail: 'Can\'t load gpx file.', life: 10000 });
		}
	}

	videoError() {
		this.messageService.add({ severity: 'error', summary: 'Video Error', detail: 'Can\'t load video file.', life: 10000 });
		this.entry['FREMD_aufzeichnung_video_url'] = null;
	}
	// #endregion Map & Player

	// #region Spezial

	/**
	 * Schaltet die Sortierung eines Dropdowns oder Multiselects um
	 * 
	 * @param field Maskenfeld, für das die Sortierung geändert werden soll
	 */
	changeSorting(field: any, init?: boolean) {
		if (init) {
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			if (this.state && this.state.optionSortFields) {
				field.optionSortField = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].optionSortField : 'ankey';
				field.sortDescending = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].sortDescending : false;
			} else {
				this.state = this.state ? this.state : {};
				this.state.optionSortFields = {};
				field.optionSortField = 'ankey';
				field.sortDescending = false;
			}
		}

		if (!field.sortDescending) {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] < a.object[field.optionSortField]) ? 1 : -1);
		} else {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] > a.object[field.optionSortField]) ? 1 : -1);
		}
		this.state.optionSortFields[field.key] = { optionSortField: field.optionSortField, sortDescending: field.sortDescending };
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.options[field.key] = this.options[field.key].map(option => ({ label: (field.optionSortField == 'bezeichnung' ? option.object.bezeichnung : option.object.bezeichnung + ' (' + option.object[field.optionSortField] + ')'), value: option.value, object: option.object }));
		this.options[field.key] = [...this.options[field.key]];
	}

	/**
	 * Ordnet das Sortierfeld im Multiselect über dem Filterfeld an
	 */
	formatMultiselectLayout() {
		const msHeaderElements = document.getElementsByClassName('p-multiselect-header');
		Array.from(msHeaderElements).forEach(msHeaderElement => {
			(msHeaderElement as HTMLElement).style.flexWrap = 'wrap';
		});
	}

	// #endregion Spezial

	// #endregion History
}
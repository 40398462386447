import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Base } from '@app/models/base';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './list_aktenzuweisung.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent extends ListComponent {
	resultData: any = {};
	commitButtonLabel: string = "An gewählte Akte zuweisen und öffnen";
	commitButtonLabel2: string = "Neue Akte erzeugen und öffnen";
	commitButtonVisible: boolean = true;
	isValidFileSelected: boolean = false;
	selectedFileData: Base = null;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public dialogService: DialogService,
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblRueckfahrkatasterAufzeichnung';
		this.name = 'MENU.AUFZEICHNUNG';
		this.url = '/' + PATH.RK_AUFZEICHNUNG;
		this.module = Module.ReversingCadastral;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_strecke_ankey', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flag_is_always_candidate', required: false, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_strecke_ankey', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flag_is_always_candidate', required: false, width: 400 },
			//{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);

		this.hasFilterDialog = false; // natürlich nicht; aber get_entries überschreiben, bekommt die Daten ja vorher übergeben

		//let fieldKey: string = 'manualData' + (i+1).toString();
		//if( this.config.data[fieldKey] ) {
		//	this.manualData[i] = this.config.data[fieldKey];
		//}

		this.stateName = 'state' + this.apiUrl + 'ListAktenzuweisung';
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.unselectAll();
	}

	unselectAll(): void {
		this.isValidFileSelected = false;
		this.selectedFileData = null;
		this.commitButtonLabel = 'Akte für die Zuweisung auswählen...';
	}

	handleRowSelection(event) {
		// und das hier ist die gerade neu selektierte zeile aus dem multiselect
		// unbenutzt
		//console.log('on handleRowSelection');
		//console.log(event);
		//console.log('on handleRowSelection END');
		this.unselectAll();
		if( event && event.data )
			this.selectedFileData = event.data;
		if( this.selectedFileData && this.selectedFileData.ds_this_id && this.selectedFileData.ds_this_id > 0 ) {
			this.isValidFileSelected = true;
			if(this.selectedFileData['name'] && this.selectedFileData['name'].length > 0) {
				if( this.selectedFileData.ankey && this.selectedFileData.ankey.length > 0 )
					this.commitButtonLabel = 'An Akte \"' + this.selectedFileData['name'] + ' (' + this.selectedFileData.ankey + ')\" zuweisen';
				else this.commitButtonLabel = 'An Akte \"' + this.selectedFileData['name'] + '\" zuweisen';
			} else {
				this.commitButtonLabel = 'An ausgewählte Akte zuweisen';
			}
		}
		else this.unselectAll();
	}

	handleRowUnselect(event) {
		//console.log('on handleRowUnselect');
		//console.log(event);
		//console.log('on handleRowUnselect END');
		this.unselectAll();
	}

	//isSelected(): boolean {
	//	if( this.entries != null ) {
	//		if( this.entries != undefined ) {
	//			if( this.entries.length > 0 ) {
	//				if( this.selectedEntry != null ) {
	//					if( this.selectedEntry != undefined ) {
	//						if( this.selectedEntry.ds_this_id && this.selectedEntry.ds_this_id >= 0 ) {
	//							this.hasListSelection = true;
	//						}
	//					}
	//				}
	//			}
	//		}
	//	}
	//	return this.hasListSelection;
	//}

	getAllEntries(): void {
		this.unselectAll();
		this.entries = [];
		if( this.config.data['candidateFiles'] != null && this.config.data['candidateFiles'] != undefined ) {
			this.processEntries(this.config.data['candidateFiles']);
		}
		this.unselectAll();
	}

	processEntries(entries: any): void {
		this.loading += 1;
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});
		this.entries = entries;
		this.count = this.entries.length;
		this.unselectAll();
		this.loading -= 1;

		// test
		//this.ref.close(this.resultData);
	}

	commitAktenzuweisung() {
		if( this.isValidFileSelected ) {
			this.resultData['createNew'] = false;
			this.resultData['selectedEntry'] = this.selectedFileData;
			this.ref.close(this.resultData);
		}
	}

	commitAktenNeuanlage() {
		this.resultData['createNew'] = true;
		this.resultData['selectedEntry'] = null;
		this.ref.close(this.resultData);
	}

	/**
	 * OVERRIDE - Passt die Tabellenhöhe und -breite an den verfügbaren PLatz an, um den Bildschrim auszufüllen
	 */
	initTable(): void {
		setTimeout(() => {
			this.contentHeight = 100;
		}, 0);
		setTimeout(() => {
			this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 200 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
			//console.log('special table contentHeight:');
			//console.log(this.contentHeight);
			if (this.contentHeight < 100) {
				this.contentHeight = 100;
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		this.changeDetectorRef.detectChanges();
	}
}

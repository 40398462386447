import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	//templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	templateUrl: './filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAkteFilterDialogComponent extends FilterDialogComponent {
	public ratings: any = {};

	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		this.ratings = [
			{ label: '', value: null, icon: '' },
			{ label: '0 von 5', value: 0, icon: 'black' },
			{ label: '1 von 5', value: 1, icon: 'green' },
			{ label: '2 von 5', value: 2, icon: 'lightgreen' },
			{ label: '3 von 5', value: 3, icon: 'yellow' },
			{ label: '4 von 5', value: 4, icon: 'lightcoral' },
			{ label: '5 von 5', value: 5, icon: 'red' },
		];

		//this.selections = {
		//	rating_from: 0,
		//	rating_to: 5,
		//};

		const optionGroup1 = new Map();
		optionGroup1.set('ankey', { type: 'text' });
		optionGroup1.set('is_always_candidate', { sub: "RASAKTE", type: 'boolean' });
		optionGroup1.set('plz', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('ort', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('strasse', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('hsnr', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('laenge_von', { sub: "RASAKTE", type: 'numeric' });
		optionGroup1.set('laenge_bis', { sub: "RASAKTE", type: 'numeric' });
		optionGroup1.set('gebiet', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblGebiet', values: [] });
		optionGroup1.set('gebietNotIn', { sub: 'RASAKTE', type: 'boolean' });
		optionGroup1.set('gebietAusschlussEinschliessen', { sub: 'RASAKTE', type: 'boolean' });
		optionGroup1.set('tour', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblTour', values: [] });
		optionGroup1.set('status_ids', { sub: "RASAKTE", type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblRueckfahrkatasterStatus', values: [] });
		optionGroup1.set('gefahren_ids', { sub: "RASAKTE", type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblRueckfahrkatasterGefahr', values: [] });
		optionGroup1.set('gruende_ids', { sub: "RASAKTE", type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblRueckfahrkatasterGrund', values: [] });
		optionGroup1.set('massnahmen_ids', { sub: "RASAKTE", type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblRueckfahrkatasterMassnahme', values: [] });
		optionGroup1.set('in_kartaster', { type: 'boolean' });
		optionGroup1.set('breiter_350_ex', { sub: "RASAKTE", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });
		optionGroup1.set('kuerzer_150_ex', { sub: "RASAKTE", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });
		optionGroup1.set('rating_from', { sub: "RASAKTE", type: 'rating' });
		optionGroup1.set('rating_to', { sub: "RASAKTE", type: 'rating' });

		this.optionGroups = [optionGroup1];
	}
}

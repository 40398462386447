<!-- ui Canvas -->
<canvas hidden #emptyCanvas></canvas>

<!-- Context Menu -->
<p-contextMenu #cmComponentHistory [model]="contextMenuComponentHistory" [style]="{'width': 'auto'}"></p-contextMenu>

<!-- Context Menu -->
<p-contextMenu #cmProcessTasks [model]="contextMenuProcessTasks" [style]="{'width': 'auto'}"></p-contextMenu>

<div class="p-grid">
	<div class="p-col-12">
		<!-- Confirm -->
		<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading!==0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.EDIT'|translate}}" class="p-button-warning p-mr-2"
						(click)="edit()"></button>
					<button pButton pRipple label="{{'BUTTONS.DELETE'|translate}}" class="p-button-danger p-mr-2"
						(click)="delete()"></button>
				</ng-template>
				<ng-template pTemplate="right">
					<button *ngIf="vehicle && vehicle.DatensatzInaktiv" type="button" pButton icon="fa fa-box"
						(click)="setzeAktiv()" class="p-button-danger p-my-2 p-mx-3"
						pTooltip="Datensatz nur in Archiv, aktivieren?" tooltipPosition="bottom"></button>
					<button *ngIf="vehicle && !vehicle.DatensatzInaktiv" type="button" pButton icon="fa fa-box-open"
						(click)="setzeInaktiv()" class="p-button-success p-my-2 p-mx-3"
						pTooltip="Datensatz aktiv, in Archiv verschieben?" tooltipPosition="bottom"></button>
					<p-fileUpload #uploadButton *ngIf="isAdmin()" mode="basic" [maxFileSize]="20971520" fileLimit="1"
						chooseLabel="{{'BUTTONS.ATTACH_FILE'|translate}}"
						(onUpload)="loading=loading-1;refreshAttachments();" (onBeforeUpload)="loading=loading+1;"
						(onError)="loading=loading-1;uploadButton.clear();" withCredentials="true" [headers]="headers"
						url="{{attachUrl}}" class="p-mr-2 p-d-inline-block" [auto]="true">
					</p-fileUpload>
				</ng-template>
			</p-toolbar>

			<!-- Content -->
			<div *ngIf="vehicle" id="details" >
				<div class="p-grid p-formgrid">
					<div *ngIf="!isVisy">
						<div class="p-grid p-formgrid">
							<div *ngFor="let group of vehicleFieldGroups" class="p-col-12 p-md-4 p-lg-4 p-xl-4">
								<div *ngIf="group.title" class="p-my-2 p-p-0">
									<h4>
										{{ 'MASTERDATA.VEHICLES.' + group.title | translate}}
									</h4>
								</div>
								<div *ngFor="let field of group.fields" class="p-my-3">
									<h6 class="p-my-2">
										{{ 'HEADERS.' + field.key | translate}}
									</h6>
									<!-- TODO: was genau ist das hier? -->
									<div *ngIf="
										(!vehicle[field.key] && vehicle[field.key] !== false && vehicle[field.key] !== 0 && !field.subkey) ||
										(!vehicle[field.key] && vehicle[field.key] !== false && vehicle[field.key] !== 0 && field.subkey) ||
										(vehicle[field.key] && vehicle[field.key] !== false && vehicle[field.key] !== 0 && field.subkey &&
										!vehicle[field.key][field.subkey] && vehicle[field.key][field.subkey] !== false && vehicle[field.key][field.subkey] !== 0)
										">
										<i class="pi pi-minus"></i>
									</div>
									<div *ngIf="(field.type === 'string' || field.type === 'number') && !field.subkey">
										{{ vehicle[field.key] }}
									</div>
									<div
										*ngIf="(field.type === 'string' || field.type === 'number') && field.subkey && vehicle[field.key]">
										{{ vehicle[field.key][field.subkey] }}
									</div>
									<div *ngIf="field.type === 'date' && !field.subkey">
										{{ vehicle[field.key] }}
									</div>
									<div *ngIf="field.type === 'date' && field.subkey && vehicle[field.key]">
										{{ vehicle[field.key][field.subkey] }}
									</div>
									<div *ngIf="field.type === 'boolean' && !field.subkey">
										<p-radioButton [value]="true" [(ngModel)]="vehicle[field.key]" disabled>
										</p-radioButton>
										<span class="p-m-1">
											{{'CONFIRMATION.YES'|translate}}
										</span>
										<p-radioButton [value]="false" [(ngModel)]="vehicle[field.key]" disabled>
										</p-radioButton>
										<span class="p-m-1">
											{{'CONFIRMATION.NO'|translate}}
										</span>
									</div>
									<div *ngIf="field.type === 'boolean' && field.subkey && vehicle[field.key]">
										<p-radioButton [value]="true" [(ngModel)]="vehicle[field.key][field.subkey]"
											disabled>
										</p-radioButton>
										<span class="p-m-1">
											{{'CONFIRMATION.YES'|translate}}
										</span>
										<p-radioButton [value]="false" [(ngModel)]="vehicle[field.key][field.subkey]"
											disabled>
										</p-radioButton>
										<span class="p-m-1">
											{{'CONFIRMATION.NO'|translate}}
										</span>
									</div>
									<div
										*ngIf="field.type === 'link' && field.subkey && vehicle[field.key] && vehicle[field.key].Id">
										<a href="#/{{field.link}}/{{vehicle[field.key].Id}}">
											{{ vehicle[field.key][field.subkey] }}
										</a>
									</div>
									<div *ngIf="field.type == 'object' && vehicle[field.key]">
										<a [routerLink]="field.url + '/' + vehicle[field.id]">
											{{ vehicle[field.key] }}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div *ngIf="isVisy" class="p-col-12">
						<p-tabView #tabView (onChange)="tabChanged($event)">
							<p-tabPanel header="Fahrzeugdaten">
								<div class="p-grid p-formgrid">
									<div *ngFor="let group of vehicleFieldGroups" class="p-col-12 p-md-4 p-lg-4 p-xl-4">
										<div *ngIf="group.title" class="p-my-2 p-p-0">
											<h4>
												{{ 'MASTERDATA.VEHICLES.' + group.title | translate}}
											</h4>
										</div>
										<div *ngFor="let field of group.fields" class="p-my-3">
											<h6 class="p-my-2">
												{{ 'HEADERS.' + field.key | translate}}
											</h6>
											<!-- TODO: was genau ist das hier? -->
											<div *ngIf="
												(!vehicle[field.key] && vehicle[field.key] !== false && vehicle[field.key] !== 0 && !field.subkey) ||
												(!vehicle[field.key] && vehicle[field.key] !== false && vehicle[field.key] !== 0 && field.subkey) ||
												(vehicle[field.key] && vehicle[field.key] !== false && vehicle[field.key] !== 0 && field.subkey &&
												!vehicle[field.key][field.subkey] && vehicle[field.key][field.subkey] !== false && vehicle[field.key][field.subkey] !== 0)
												">
												<i class="pi pi-minus"></i>
											</div>
											<div
												*ngIf="(field.type === 'string' || field.type === 'number') && !field.subkey">
												{{ vehicle[field.key] }}
											</div>
											<div
												*ngIf="(field.type === 'string' || field.type === 'number') && field.subkey && vehicle[field.key]">
												{{ vehicle[field.key][field.subkey] }}
											</div>
											<div *ngIf="field.type === 'date' && !field.subkey">
												{{ vehicle[field.key] }}
											</div>
											<div *ngIf="field.type === 'date' && field.subkey && vehicle[field.key]">
												{{ vehicle[field.key][field.subkey] }}
											</div>
											<div *ngIf="field.type === 'boolean' && !field.subkey">
												<p-radioButton [value]="true" [(ngModel)]="vehicle[field.key]" disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.YES'|translate}}
												</span>
												<p-radioButton [value]="false" [(ngModel)]="vehicle[field.key]"
													disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.NO'|translate}}
												</span>
											</div>
											<div *ngIf="field.type === 'boolean' && field.subkey && vehicle[field.key]">
												<p-radioButton [value]="true"
													[(ngModel)]="vehicle[field.key][field.subkey]" disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.YES'|translate}}
												</span>
												<p-radioButton [value]="false"
													[(ngModel)]="vehicle[field.key][field.subkey]" disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.NO'|translate}}
												</span>
											</div>
											<div
												*ngIf="field.type === 'link' && field.subkey && vehicle[field.key] && vehicle[field.key].Id">
												<a href="#/{{field.link}}/{{vehicle[field.key].Id}}">
													{{ vehicle[field.key][field.subkey] }}
												</a>
											</div>
											<div *ngIf="field.type == 'object' && vehicle[field.key]">
												<a [routerLink]="field.url + '/' + vehicle[field.id]">
													{{ vehicle[field.key] }}
												</a>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="isVisy" class="p-col-12">
									<div class="card p-my-2" style="min-height: 100%">
										<p-table #tableComponentHistory *ngIf="visibleTableComponentHistory"
											[stateKey]="tableStateNameComponentHistory" stateStorage="local"
											(onColResize)="onColResizeComponentHistory($event)" (onColReorder)="onColReorderComponentHistory($event)"
											(onFilter)="onFilterComponentHistory($event)"
											(onRowSelect)="isMobile && cmComponentHistory.show($event.originalEvent); $event.originalEvent.stopPropagation();"
											[(contextMenuSelection)]="selectedEntryComponentHistory" [(selection)]="selectedEntryComponentHistory" [contextMenu]="cmComponentHistory"
											[globalFilterFields]="filtersComponentHistory" [reorderableColumns]="true" [resizableColumns]="true"
											[scrollHeight]="contentHeightComponentHistory + 'px'" [virtualRowHeight]="45" [virtualScroll]="true"
											columnResizeMode="expand" dataKey="lfd_nummer" scrollDirection="both" selectionMode="single"
											sortField="lfd_nummer" styleClass="p-datatable-gridlines"
											[columns]="colsComponentHistory" [rows]="100" [scrollable]="true" [value]="entriesComponentHistory">
											<ng-template pTemplate="caption">
												<div class="p-d-flex p-ai-center p-jc-between">
													<div>
														<button type="button" pButton icon="pi pi-file-o" (click)="exportCSVComponentHistory()"
															class="p-my-2 p-mx-1" pTooltip="CSV" tooltipPosition="bottom"></button>
														<button type="button" pButton icon="pi pi-file-excel" (click)="exportXLSXComponentHistory()"
															class="p-button-success p-my-2 p-mx-1" pTooltip="XLS" tooltipPosition="bottom"></button>
														<button type="button" pButton icon="pi pi-file-pdf" (click)="exportPDFComponentHistory()"
															class="p-button-warning p-my-2 p-mx-1" pTooltip="PDF" tooltipPosition="bottom"></button>
														<button type="button" pButton icon="fa fa-wrench" (click)="komponenteZuweisung()"
															class="p-my-2 p-mx-1" pTooltip="{{'BUTTONS.COMPONENT_SET_VEHICLE'|translate}}" tooltipPosition="bottom"></button>
														<button type="button" pButton icon="fa fa-screwdriver-wrench" (click)="komponentenMultiZuweisung()"
															class="p-my-2 p-mx-1" pTooltip="{{'BUTTONS.COMPONENT_SET_VEHICLE'|translate}}" tooltipPosition="bottom"></button>
													</div>
													<h5 class="p-m-0">{{ 'HEADERS.VehicleComponentHistorie' | translate }}</h5>
													<span class="p-input-icon-left">
														<i class="pi pi-search"></i>
														<input pInputText id="globalComponentHistory" type="text" [value]="globalFilterComponentHistory"
															(input)="tableComponentHistory.filterGlobal($event.target.value, 'contains');globalFilterComponentHistory = $event.target.value;"
															placeholder="{{'BUTTONS.SEARCH'|translate}}" />
													</span>
												</div>
											</ng-template>
											<ng-template pTemplate="header" let-columns>
												<tr>
													<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
														[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
														<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
															<div>
																<p-sortIcon [field]="col.key"></p-sortIcon>
																{{ 'HEADERS.' + col.key | translate }}
															</div>
															<p-columnFilter [type]="col.type" [field]="col.key" display="menu" appendTo="body"
																[style.color]="(isColFilteredComponentHistory(col) ? 'var(--primary-color)' : 'var(--text-color)')">
															</p-columnFilter>
														</div>
													</th>
													<th id="buttoncolhead" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
														pFrozenColumn>
														<p-multiSelect #colselectionComponentHistory [options]="possibleColsComponentHistory" [(ngModel)]="colsComponentHistory" dataKey="key"
															(onChange)="toggleColumnComponentHistory($event)" optionLabel="label" appendTo="body"
															[style.visibility]="'hidden'" [style.position]="'absolute'"
															[panelStyle]="{'min-width':'auto'}" class="colselect">
														</p-multiSelect>
														<button pButton pRipple icon="fas fa-table-columns"
															class="p-button-rounded p-button-secondary p-mr-2"
															(click)="colselectionComponentHistory.show($event.originalEvent);"
															pTooltip="{{'BUTTONS.COL_SELECT'|translate}}" tooltipPosition="bottom"
															showDelay="500"></button>
														<button pButton pRipple icon="pi pi-filter-slash"
															class="p-button-rounded p-button-secondary p-mr-2" (click)="tableComponentHistory.clear()"
															pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}" tooltipPosition="bottom"
															showDelay="500"></button>
														<button pButton pRipple icon="fas fa-table-cells"
															class="p-button-rounded p-button-secondary p-mr-2" (click)="resetTableComponentHistory()"
															pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
															showDelay="500"></button>
													</th>
												</tr>
											</ng-template>
											<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
												<tr style="height:45px" [pContextMenuRow]="entry" [pSelectableRow]="entry">
													<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
														<div *ngIf="entry[col.key] === null">
															<i class="pi pi-minus"></i>
														</div>
														<div *ngIf="entry[col.key] !== null">
															<div *ngIf="col.type == 'text'">
																{{ entry[col.key] }}
															</div>
															<div *ngIf="col.type == 'numeric'">
																{{ entry[col.key] }}
															</div>
															<div *ngIf="col.type == 'boolean'">
																<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
																	[style.color]="entry[col.key] ? 'green' : 'red'"></i>
															</div>
															<div *ngIf="col.type == 'date'">
																{{ entry[col.key].toLocaleDateString() }}
															</div>
														</div>
													</td>
													<td class="buttoncolbody" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
														pFrozenColumn>
														<a id="detailbutton" [routerLink]="historyUrl + '/detail/' + entry.ds_this_id"
															class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-search"></i>
														</a>
														<a *ngIf="updatePermission" id="editbutton" [routerLink]="historyUrl + '/edit/' + entry.ds_this_id"
															class="p-button-rounded p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-pencil"></i>
														</a>
														<a *ngIf="deletePermission" id="deletebutton" (click)="vehicleComponentDeleteWithLink(entry)"
															class="p-button-rounded p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-trash"></i>
														</a>
													</td>
												</tr>
											</ng-template>
											<ng-template pTemplate="summary">
												<div class="p-d-flex p-ai-center p-jc-between">
													{{ 'TABLE.COUNTComponentHistory' | translate : { countComponentHistory : countComponentHistory } }}
													{{ (tableComponentHistory && tableComponentHistory.filteredValue ? 'TABLE.TOTAL' : '') | translate : { countComponentHistory : (entriesComponentHistory ?
													entriesComponentHistory.length
													: 0) } }}
													<div>
														{{ 'TABLE.LAST_REFRESH' | translate }}: {{ loadTimestamp ?
														loadTimestamp.toLocaleString() : '' }}
													</div>
												</div>
											</ng-template>
										</p-table>
									</div>
								</div>
							</p-tabPanel>
							<p-tabPanel header="Abwicklung">
								<div *ngIf="isVisy && vehicleProcessingData" class="p-grid p-formgrid">
									<div *ngFor="let group of vehicleProcessingFieldGroups"
										class="p-col-12 p-md-4 p-lg-4 p-xl-4">
										<div *ngIf="group.title" class="p-my-2 p-p-0">
											<h4>
												{{ 'MASTERDATA.VEHICLES.' + group.title | translate}}
											</h4>
										</div>
										<div *ngFor="let field of group.fields" class="p-my-3">
											<h6 class="p-my-2">
												{{ 'HEADERS.' + field.key | translate}}
											</h6>
											<div *ngIf="
												(!vehicleProcessingData[field.key] && vehicleProcessingData[field.key] !== false && vehicleProcessingData[field.key] !== 0 && !field.subkey) ||
												(!vehicleProcessingData[field.key] && vehicleProcessingData[field.key] !== false && vehicleProcessingData[field.key] !== 0 && field.subkey) ||
												(vehicleProcessingData[field.key] && vehicleProcessingData[field.key] !== false && vehicleProcessingData[field.key] !== 0 && field.subkey &&
												!vehicleProcessingData[field.key][field.subkey] && vehicleProcessingData[field.key][field.subkey] !== false && vehicleProcessingData[field.key][field.subkey] !== 0)
												">
												<i class="pi pi-minus"></i>
											</div>
											<div
												*ngIf="(field.type === 'string' || field.type === 'number') && !field.subkey">
												{{ vehicleProcessingData[field.key] }}
											</div>
											<div
												*ngIf="(field.type === 'string' || field.type === 'number') && field.subkey && vehicleProcessingData[field.key]">
												{{ vehicleProcessingData[field.key][field.subkey] }}
											</div>
											<div *ngIf="field.type === 'date' && !field.subkey">
												{{ vehicleProcessingData[field.key] }}
											</div>
											<div
												*ngIf="field.type === 'date' && field.subkey && vehicleProcessingData[field.key]">
												{{ vehicleProcessingData[field.key][field.subkey] }}
											</div>
											<div *ngIf="field.type === 'boolean' && !field.subkey">
												<p-radioButton [value]="true" [(ngModel)]="vehicleProcessingData[field.key]"
													disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.YES'|translate}}
												</span>
												<p-radioButton [value]="false" [(ngModel)]="vehicleProcessingData[field.key]"
													disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.NO'|translate}}
												</span>
											</div>
											<div
												*ngIf="field.type === 'boolean' && field.subkey && vehicleProcessingData[field.key]">
												<p-radioButton [value]="true"
													[(ngModel)]="vehicleProcessingData[field.key][field.subkey]" disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.YES'|translate}}
												</span>
												<p-radioButton [value]="false"
													[(ngModel)]="vehicleProcessingData[field.key][field.subkey]" disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.NO'|translate}}
												</span>
											</div>
											<div
												*ngIf="field.type === 'link' && field.subkey && vehicleProcessingData[field.key] && vehicleProcessingData[field.key].Id">
												<a href="#/{{field.link}}/{{vehicleProcessingData[field.key].Id}}">
													{{ vehicleProcessingData[field.key][field.subkey] }}
												</a>
											</div>
											<div *ngIf="field.type == 'object' && vehicleProcessingData[field.key]">
												<a [routerLink]="field.url + '/' + vehicleProcessingData[field.id]">
													{{ vehicleProcessingData[field.key] }}
												</a>
											</div>
										</div>
									</div>
									<!--<div class="p-col-12 p-md-4 p-lg-4 p-xl-4">-->
									<div class="p-ai-center p-jc-between">
										<h4>
											{{ 'MASTERDATA.VEHICLES.PROGRESS' | translate}}
										</h4>
										<p-chart id="progressChart" type="doughnut" [data]="vehicleProcessingData.progressData"
											[options]="vehicleProcessingData.progressDataOptions"></p-chart>
										<!--<div class="p-mt-4">
											<h4>
												Schritt {{vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt <
													vehicleProcessingData.SYNTH_anzahl_abschnitte_insgesamt ?
													vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt + 1 :
													vehicleProcessingData.SYNTH_anzahl_abschnitte_erledigt}} von
													{{vehicleProcessingData.SYNTH_anzahl_abschnitte_insgesamt}} </h4>
													<h5>
														{{vehicleProcessingData.FREMD_aktueller_vorgang_typ_bezeichnung}}
													</h5>
													<span>
														{{vehicleProcessingData.FREMD_aktueller_vorgang_typ_beschreibung}}
													</span>
										</div>-->
										<div class="p-mt-4">
											<button pButton pRipple
												label="+"
												class="p-button p-mr-2" (click)="vehicleProcessAddTask()"></button>
											<button pButton pRipple
												label="{{'BUTTONS.REFRESH' | translate}}"
												class="p-button p-mr-2" (click)="loadVehicleProcessData(true)"></button>
											<button pButton pRipple
												label="{{'BUTTONS.ADD_DEFAULT_TASKS' | translate}}"
												class="p-button p-mr-2" (click)="vehicleProcessAddDefaultTasks()"></button>
										</div>
									</div>
								</div>
								<div *ngIf="isVisy && vehicleProcessingData && vehicleProcessingData.linkedTasks" class="p-col-12">
									<div class="card p-my-2" style="min-height: 100%">
										<p-table #tableProcessTasks  *ngIf="visibleTableProcessTasks"
											[stateKey]="tableStateNameProcessTasks" stateStorage="local"
											(onColResize)="onColResizeProcessTasks($event)" (onColReorder)="onColReorderProcessTasks($event)"
											(onFilter)="onFilterProcessTasks($event)"
											(onRowSelect)="isMobile && cmProcessTasks.show($event.originalEvent); $event.originalEvent.stopPropagation();"
											[(contextMenuSelection)]="selectedEntryProcessTasks" [(selection)]="selectedEntryProcessTasks" [contextMenu]="cmProcessTasks"
											[globalFilterFields]="filtersProcessTasks" [reorderableColumns]="true" [resizableColumns]="true"
											[scrollHeight]="contentHeightProcessTasks + 'px'" [virtualRowHeight]="45" [virtualScroll]="true"
											columnResizeMode="expand" dataKey="lfd_nummer" scrollDirection="both" selectionMode="single"
											sortField="lfd_nummer" styleClass="p-datatable-gridlines"
											[columns]="colsProcessTasks" [rows]="100" [scrollable]="true" [value]="vehicleProcessingData.linkedTasks">
											<ng-template pTemplate="caption">
												<div class="p-d-flex p-ai-center p-jc-between">
													<!--<div>
														<button type="button" pButton icon="pi pi-file-o" (click)="exportCSVProcessTasks()"
															class="p-my-2 p-mx-1" pTooltip="CSV" tooltipPosition="bottom"></button>
														<button type="button" pButton icon="pi pi-file-excel" (click)="exportXLSXProcessTasks()"
															class="p-button-success p-my-2 p-mx-1" pTooltip="XLS" tooltipPosition="bottom"></button>
														<button type="button" pButton icon="pi pi-file-pdf" (click)="exportPDFProcessTasks()"
															class="p-button-warning p-my-2 p-mx-1" pTooltip="PDF" tooltipPosition="bottom"></button>
													</div>-->
													<h5 class="p-m-0">{{ 'HEADERS.VehicleProcessingTasks' | translate }}</h5>
													<span class="p-input-icon-left">
														<i class="pi pi-search"></i>
														<input pInputText id="globalProcessTasks" type="text" [value]="globalFilterProcessTasks"
															(input)="tableProcessTasks.filterGlobal($event.target.value, 'contains');globalFilterProcessTasks = $event.target.value;"
															placeholder="{{'BUTTONS.SEARCH'|translate}}" />
													</span>
												</div>
											</ng-template>
											<ng-template pTemplate="header" let-columns>
												<tr>
													<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
														[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
														<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
															<div>
																<p-sortIcon [field]="col.key"></p-sortIcon>
																{{ 'HEADERS.' + col.key | translate }}
															</div>
															<p-columnFilter [type]="col.type" [field]="col.key" display="menu" appendTo="body"
																[style.color]="(isColFilteredProcessTasks(col) ? 'var(--primary-color)' : 'var(--text-color)')">
															</p-columnFilter>
														</div>
													</th>
													<th id="buttoncolhead" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
														pFrozenColumn>
														<p-multiSelect #colselectionProcessTasks [options]="possibleColsProcessTasks" [(ngModel)]="colsProcessTasks" dataKey="key"
															(onChange)="toggleColumnProcessTasks($event)" optionLabel="label" appendTo="body"
															[style.visibility]="'hidden'" [style.position]="'absolute'"
															[panelStyle]="{'min-width':'auto'}" class="colselect">
														</p-multiSelect>
														<button pButton pRipple icon="fas fa-table-columns"
															class="p-button-rounded p-button-secondary p-mr-2"
															(click)="colselectionProcessTasks.show($event.originalEvent);"
															pTooltip="{{'BUTTONS.COL_SELECT'|translate}}" tooltipPosition="bottom"
															showDelay="500"></button>
														<button pButton pRipple icon="pi pi-filter-slash"
															class="p-button-rounded p-button-secondary p-mr-2" (click)="tableProcessTasks.clear()"
															pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}" tooltipPosition="bottom"
															showDelay="500"></button>
														<button pButton pRipple icon="fas fa-table-cells"
															class="p-button-rounded p-button-secondary p-mr-2" (click)="resetTableProcessTasks()"
															pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
															showDelay="500"></button>
													</th>
												</tr>
											</ng-template>
											<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
												<tr style="height:45px" [pContextMenuRow]="entry" [pSelectableRow]="entry">
													<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
														<div *ngIf="entry[col.key] === null">
															<i class="pi pi-minus"></i>
														</div>
														<div *ngIf="entry[col.key] !== null">
															<div *ngIf="col.type == 'text'">
																{{ entry[col.key] }}
															</div>
															<div *ngIf="col.type == 'numeric'">
																{{ entry[col.key] }}
															</div>
															<div *ngIf="col.type == 'boolean'">
																<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
																	[style.color]="entry[col.key] ? 'green' : 'red'"></i>
															</div>
															<div *ngIf="col.type == 'date'">
																{{ entry[col.key].toLocaleDateString() }}
															</div>
														</div>
													</td>
													<td class="buttoncolbody" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
														pFrozenColumn>
														<!--<a id="detailbutton" [routerLink]="tasksUrl + '/detail/' + entry.ds_this_id"
															class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-search"></i>
														</a>-->
														<!--<a *ngIf="updatePermission" id="editbutton" [routerLink]="tasksUrl + '/edit/' + entry.ds_this_id"
															class="p-button-rounded p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-pencil"></i>
														</a>-->
														<!-- hier müssen detail- und editfunktionen in extra tabs geöffnet werden -->
														<!-- detail brauchen wir hier eigentlich nicht... <a id="detailbutton" (click)="vehicleProcessDetailTask(entry)"
															class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-search"></i>
														</a>-->

														<a *ngIf="updatePermission && !entry['geschlossen']" id="checkinoutbutton" (click)="vehicleProcessQuitTask(entry)"
															class="p-button-rounded p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-check"></i>
														</a>
														<a *ngIf="updatePermission && entry['geschlossen']" id="checkinoutbutton" (click)="vehicleProcessQuitTask(entry)"
															class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-check"></i>
														</a>
														<a *ngIf="updatePermission" id="editbutton" (click)="vehicleProcessEditTask(entry)"
															class="p-button-rounded p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-pencil"></i>
														</a>
														<a *ngIf="deletePermission" id="deletebutton" (click)="vehicleProcessDeleteTask(entry)"
															class="p-button-rounded p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
															<i class="pi pi-trash"></i>
														</a>
													</td>
												</tr>
											</ng-template>
											<ng-template pTemplate="summary">
												<div class="p-d-flex p-ai-center p-jc-between">
													{{ 'TABLE.COUNTProcessTasks' | translate : { countProcessTasks : countProcessTasks } }}
													{{ (tableProcessTasks && tableProcessTasks.filteredValue ? 'TABLE.TOTAL' : '') | translate : { countProcessTasks : (vehicleProcessingData && vehicleProcessingData.linkedTasks ?
													vehicleProcessingData.linkedTasks.length
													: 0) } }}
													<div>
														{{ 'TABLE.LAST_REFRESH' | translate }}: {{ loadTimestamp ?
														loadTimestamp.toLocaleString() : '' }}
													</div>
												</div>
											</ng-template>
										</p-table>
									</div>
								</div>
							</p-tabPanel>
							<p-tabPanel header="An-/Auslieferung Zeitplan">
								<div *ngIf="isVisy && vehicleProcessingData" class="p-grid p-formgrid">
									
										<div class="p-my-3 p-mx-5">
											<h3 class="p-my-2">
												{{ 'HEADERS.PROCESS_SCHEDULE_SUPPLY_DETAILS' | translate}}
											</h3>
											<div class="p-my-4">
												<button pButton pRipple
													label="{{ 'HEADERS.PROCESS_SCHEDULE_ADDNEW' | translate }}"
													class="p-button p-mr-2" (click)="addVehicleProcessScheduleSupply()">
												</button>
												<button pButton pRipple
													label="{{ 'HEADERS.PROCESS_SCHEDULE_SHOWHISTORY' | translate }}"
													class="p-button p-mr-2" (click)="showVehicleProcessScheduleHistorySupply()">
												</button>
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_SUPPLY_DATE' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleSupply">
												{{ vehicleProcessScheduleSupply.ereigniszeitpunkt_string_german }}
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_SUPPLY_TIMESTAMP' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleSupply">
												{{ vehicleProcessScheduleSupply.erfasst_string_german }}
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_SUPPLY_EMPLOYEE' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleSupply">
												{{ vehicleProcessScheduleSupply.FREMD_mitarbeiter_bezeichnung }}
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_SUPPLY_MEMO' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleSupply">
												{{ vehicleProcessScheduleSupply.bemerkungen }}
											</div>
										</div>
										<div class="p-my-3 p-mx-5">
											<h3 class="p-my-2">
												{{ 'HEADERS.PROCESS_SCHEDULE_DISPATCH_DETAILS' | translate}}
											</h3>
											<div class="p-my-4">
												<button pButton pRipple
													label="{{ 'HEADERS.PROCESS_SCHEDULE_ADDNEW' | translate }}"
													class="p-button p-mr-2" (click)="addVehicleProcessScheduleDispatch()">
												</button>
												<button pButton pRipple
													label="{{ 'HEADERS.PROCESS_SCHEDULE_SHOWHISTORY' | translate }}"
													class="p-button p-mr-2" (click)="showVehicleProcessScheduleHistoryDispatch()">
												</button>
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_DISPATCH_DATE' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleDispatch">
												{{ vehicleProcessScheduleDispatch.ereigniszeitpunkt_string_german }}
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_DISPATCH_TIMESTAMP' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleDispatch">
												{{ vehicleProcessScheduleDispatch.erfasst_string_german }}
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_DISPATCH_EMPLOYEE' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleDispatch">
												{{ vehicleProcessScheduleDispatch.FREMD_mitarbeiter_bezeichnung }}
											</div>
											<h5 class="p-mt-5">
												{{ 'HEADERS.PROCESS_SCHEDULE_DISPATCH_MEMO' | translate}}
											</h5>
											<div *ngIf="vehicleProcessScheduleDispatch">
												{{ vehicleProcessScheduleDispatch.bemerkungen }}
											</div>
										</div>
								</div>
							</p-tabPanel>
							<p-tabPanel header="Service">
								<div *ngIf="visibleServiceDataFields && serviceData" class="p-grid p-formgrid">
									<div *ngFor="let group of servicedataFieldGroups"
										class="p-col-12 p-md-4 p-lg-4 p-xl-4">
										<div *ngIf="group.title" class="p-my-2 p-p-0">
											<h4>
												{{ 'MASTERDATA.VEHICLES.' + group.title | translate}}
											</h4>
										</div>
										<div *ngFor="let field of group.fields" class="p-my-3">
											<h6 class="p-my-2">
												{{ 'HEADERS.' + field.key | translate}}
											</h6>
											<div *ngIf="
												(!serviceData[field.key] && serviceData[field.key] !== false && serviceData[field.key] !== 0 && !field.subkey) ||
												(!serviceData[field.key] && serviceData[field.key] !== false && serviceData[field.key] !== 0 && field.subkey) ||
												(serviceData[field.key] && serviceData[field.key] !== false && serviceData[field.key] !== 0 && field.subkey &&
												!serviceData[field.key][field.subkey] && serviceData[field.key][field.subkey] !== false && serviceData[field.key][field.subkey] !== 0)
												">
												<i class="pi pi-minus"></i>
											</div>
											<div
												*ngIf="(field.type === 'string' || field.type === 'number') && !field.subkey">
												{{ serviceData[field.key] }}
											</div>
											<div
												*ngIf="(field.type === 'string' || field.type === 'number') && field.subkey && serviceData[field.key]">
												{{ serviceData[field.key][field.subkey] }}
											</div>
											<div *ngIf="field.type === 'date' && !field.subkey">
												{{ serviceData[field.key] }}
											</div>
											<div
												*ngIf="field.type === 'date' && field.subkey && serviceData[field.key]">
												{{ serviceData[field.key][field.subkey] }}
											</div>
											<div *ngIf="field.type === 'boolean' && !field.subkey">
												<p-radioButton [value]="true" [(ngModel)]="serviceData[field.key]"
													disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.YES'|translate}}
												</span>
												<p-radioButton [value]="false" [(ngModel)]="serviceData[field.key]"
													disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.NO'|translate}}
												</span>
											</div>
											<div
												*ngIf="field.type === 'boolean' && field.subkey && serviceData[field.key]">
												<p-radioButton [value]="true"
													[(ngModel)]="serviceData[field.key][field.subkey]" disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.YES'|translate}}
												</span>
												<p-radioButton [value]="false"
													[(ngModel)]="serviceData[field.key][field.subkey]" disabled>
												</p-radioButton>
												<span class="p-m-1">
													{{'CONFIRMATION.NO'|translate}}
												</span>
											</div>
											<div
												*ngIf="field.type === 'link' && field.subkey && serviceData[field.key] && serviceData[field.key].Id">
												<a href="#/{{field.link}}/{{serviceData[field.key].Id}}">
													{{ serviceData[field.key][field.subkey] }}
												</a>
											</div>
											<div *ngIf="field.type == 'object' && serviceData[field.key]">
												<a [routerLink]="field.url + '/' + serviceData[field.id]">
													{{ serviceData[field.key] }}
												</a>
											</div>
										</div>
									</div>
								</div>
								<p-toolbar>
									<ng-template pTemplate="left">
										<div *ngIf="serviceData">
											<button pButton pRipple label="{{'BUTTONS.RELOAD_SERVICE_DATA'|translate}}"
												class="p-button p-mr-2" (click)="loadServiceData()"></button>
										</div>
										<div *ngIf="!serviceData">
											<button pButton pRipple label="{{'BUTTONS.LOAD_SERVICE_DATA'|translate}}"
												class="p-button p-mr-2" (click)="loadServiceData()"></button>
										</div>
										<div *ngIf="serviceData">
											<button pButton pRipple
												label="{{'BUTTONS.SET_RECEIVE_MESSAGES_FLAG'|translate}}"
												class="p-button p-mr-2" (click)="setReceiveMessagesFlag()"></button>
										</div>
										<div *ngIf="serviceData">
											<button pButton pRipple
												label="{{'BUTTONS.GET_REMOTE_MAINTENANCE_PORT'|translate}}"
												class="p-button p-mr-2" (click)="getRemoteMaintenancePort()"></button>
										</div>
									</ng-template>
									<ng-template pTemplate="right">
									</ng-template>
								</p-toolbar>
							</p-tabPanel>
						</p-tabView>
					</div>
				</div>

				<div *ngIf="attachments && attachments.length > 0" class="p-mt-4">
					<h3>
						{{ 'MASTERDATA.VEHICLES.' + 'Anhaenge' | translate}}
					</h3>
					<div class="p-mb-2">
						<button pButton pRipple label="Liste" class="p-button-text p-p-0"
							(click)="attachmentListStyle='list'"></button> | <button pButton pRipple label="Vorschau"
							class="p-button-text p-p-0" (click)="attachmentListStyle='preview'"></button>
					</div>
					<div *ngFor="let attachment of attachments">
						<img *ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'image'"
							[src]="attachment.fileUrl" style="max-height:50px;max-width:50px;">
						<iframe *ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'text'"
							[src]="attachment.fileUrl"
							style="max-height:50px;max-width:50px;background: #FFFFFF;"></iframe>
						<iframe *ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'pdf'"
							[src]="attachment.fileUrl" style="max-height:50px;max-width:50px;"></iframe>
						<button pButton pRipple label="{{ attachment.bezeichnung }}" class="p-button p-my-2 p-mx-1"
							(click)="filePreview.toggle($event);"></button>
						<button pButton pRipple icon="pi pi-arrow-down"
							class="p-button-rounded p-button-warning p-my-2 p-mx-1"
							(click)="downloadFile(attachment)"></button>
						<p-overlayPanel #filePreview>
							<ng-template pTemplate>
								<div *ngIf="getFileType(attachment.bezeichnung) === 'image'"
									style="max-height:75vh;max-width:75vw;">
									<img [src]="attachment.fileUrl" style="max-height:75vh;max-width:75vw;">
								</div>
								<div *ngIf="serviceData">
									<button pButton pRipple label="{{'BUTTONS.SET_RECEIVE_MESSAGES_FLAG'|translate}}"
										class="p-button p-mr-2" (click)="setReceiveMessagesFlag()"></button>
								</div>
								<div *ngIf="serviceData">
									<button pButton pRipple label="{{'BUTTONS.GET_REMOTE_MAINTENANCE_PORT'|translate}}"
										class="p-button p-mr-2" (click)="getRemoteMaintenancePort()"></button>
								</div>
							</ng-template>
							<ng-template pTemplate="right">
							</ng-template>
						</p-overlayPanel>
					</div>

					<div *ngIf="attachments && attachments.length > 0">
						<h3>
							{{ 'MASTERDATA.VEHICLES.' + 'Anhaenge' | translate}}
						</h3>
						<div class="p-mb-2">
							<button pButton pRipple label="Liste" class="p-button-text p-p-0"
								(click)="attachmentListStyle='list'"></button> | <button pButton pRipple label="Vorschau"
								class="p-button-text p-p-0" (click)="attachmentListStyle='preview'"></button>
						</div>
						<div *ngFor="let attachment of attachments">
							<img *ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'image'"
								[src]="attachment.fileUrl" style="max-height:50px;max-width:50px;">
							<iframe *ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'text'"
								[src]="attachment.fileUrl"
								style="max-height:50px;max-width:50px;background: #FFFFFF;"></iframe>
							<iframe *ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'pdf'"
								[src]="attachment.fileUrl" style="max-height:50px;max-width:50px;"></iframe>
							<button pButton pRipple label="{{ attachment.bezeichnung }}" class="p-button p-my-2 p-mx-1"
								(click)="filePreview.toggle($event);"></button>
							<button pButton pRipple icon="pi pi-arrow-down"
								class="p-button-rounded p-button-warning p-my-2 p-mx-1"
								(click)="downloadFile(attachment)"></button>
							<p-overlayPanel #filePreview>
								<ng-template pTemplate>
									<div *ngIf="getFileType(attachment.bezeichnung) === 'image'"
										style="max-height:75vh;max-width:75vw;">
										<img [src]="attachment.fileUrl" style="max-height:75vh;max-width:75vw;">
									</div>
									<div *ngIf="getFileType(attachment.bezeichnung) === 'text'"
										style="overflow:scroll;height:550px;width:550px;background: #FFFFFF;">
										<iframe [src]="attachment.fileUrl" width="100%" height="99%"
											allowfullscreen></iframe>
									</div>
									<div *ngIf="getFileType(attachment.bezeichnung) === 'pdf'"
										style="overflow:scroll;height:550px;width:550px;">
										<iframe [src]="attachment.fileUrl" width="100%" height="99%"
											allowfullscreen></iframe>
									</div>
								</ng-template>
							</p-overlayPanel>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
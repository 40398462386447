<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.EDIT'|translate}}" class="p-button-warning p-mr-2"
						(click)="edit()"></button>
					<button pButton pRipple label="{{'BUTTONS.DELETE'|translate}}" class="p-button-danger p-mr-2"
						(click)="delete()"></button>
				</ng-template>
				<ng-template pTemplate="right">
				</ng-template>
			</p-toolbar>

			<!-- Content -->
			<div *ngIf="connectionSetting">
				<div *ngFor="let fields of fieldgroups" class="p-my-5">
					<div class="p-grid p-formgrid">
						<div *ngFor="let field of fields" class="p-col-12 p-md-6 p-lg-3 p-my-2">
							<h6 class="p-my-2">{{field.label}}</h6>
							<div *ngIf="
								 (!connectionSetting[field.key] && connectionSetting[field.key] !== false && connectionSetting[field.key] !== 0 && !field.subkey) ||
								 (!connectionSetting[field.key] && connectionSetting[field.key] !== false && connectionSetting[field.key] !== 0 && field.subkey) ||
								 (connectionSetting[field.key] && connectionSetting[field.key] !== false && connectionSetting[field.key] !== 0 && field.subkey &&
								 !connectionSetting[field.key][field.subkey] && connectionSetting[field.key][field.subkey] !== false && connectionSetting[field.key][field.subkey] !== 0)
								 ">
								<i class="pi pi-minus"></i>
							</div>
							<div *ngIf="(field.type === 'string' || field.type === 'number') && !field.subkey"
								style="overflow: auto;">
								{{ connectionSetting[field.key] }}
							</div>
							<div
								*ngIf="(field.type === 'string' || field.type === 'number') && field.subkey && connectionSetting[field.key]">
								{{ connectionSetting[field.key][field.subkey] }}
							</div>
							<div *ngIf="field.type === 'boolean' && !field.subkey">
								<p-radioButton [value]="true" [(ngModel)]="connectionSetting[field.key]" disabled>
								</p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.YES'|translate}}
								</span>
								<p-radioButton [value]="false" [(ngModel)]="connectionSetting[field.key]" disabled>
								</p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.NO'|translate}}
								</span>
							</div>
							<div *ngIf="field.type === 'boolean' && field.subkey && connectionSetting[field.key]">
								<p-radioButton [value]="true" [(ngModel)]="connectionSetting[field.key][field.subkey]"
									disabled></p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.YES'|translate}}
								</span>
								<p-radioButton [value]="false" [(ngModel)]="connectionSetting[field.key][field.subkey]"
									disabled></p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.NO'|translate}}
								</span>
							</div>
						</div>
					</div>
				</div>
				<h4 class="p-mt-5 p-mb-2">Gebuchte Module</h4>
				<div class="p-grid p-formgrid">
					<div *ngFor="let field of permissions" class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<span>
							{{field.label}}
						</span>
						<p-toggleButton [ngModel]="isBookedModule(field.key)" onIcon="pi pi-check" offIcon="pi pi-times"
							class="p-m-2" disabled>
						</p-toggleButton>
					</div>
				</div>
				<h4 class="p-mt-5 p-mb-2">Connection Flags</h4>
				<!--<div class="p-grid p-formgrid">-->
				<div>
					<!--<div *ngFor="let field of connectionFlags" class="p-col-12 p-md-6 p-lg-3 p-my-2">-->
					<div *ngFor="let field of connectionFlags" class="p-col-1 p-md-4 p-lg-4 p-xl-4">
						<h6 class="p-my-2">{{field.label}}</h6>
						<!--<h6 class="p-my-2">---</h6>-->
						<div style="display:table;">
							<span style="display:table-cell;vertical-align:middle;text-align:right">
								{{field.unset}}
							</span>
							<p-inputSwitch [ngModel]="isFlagSet(field.bit)" class="p-mx-2" disabled>
							</p-inputSwitch>
							<span style="display:table-cell;vertical-align:middle;text-align:left">
								{{field.set}}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Confirm -->
		<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
	</div>
</div>